import { Component } from 'react';

import { Point, Polygon, LineString } from "ol/geom";
import { RLayerVector, RFeature, ROverlay, RPopup } from "rlayers";
import { RStyle, /*RIcon,*/ RFill, RStroke } from "rlayers/style";

import { Zapros, RandomColor } from "../Moduli/Funkcii"
import { Okno, OOkno } from "../Moduli/Okna"
import {
	ContentWide, ColTitleItemRender,
	WideList, WideItem
} from "../Moduli/Elementi"


export class Dorogi extends Component {
	constructor(props) {
		super(props);

		this.state = {
			Segmenti: []
		};
	}

	componentDidMount() {
		Zapros({ S: "Karta/Puti/Segmenti" }).then(O => {
			this.setState({ Segmenti: O.map(S => { S.jDannie = JSON.parse(S.jDannie); return S }) });
		});
	}

	render() {
		return <RLayerVector>
			{this.state.Segmenti.map(S => {
				if (S.sTip == "Segment" || S.sTip == "SegmentZakritii") return <RFeature geometry={new LineString(S.jDannie)}>
					<RStyle><RStroke color={"#6a6a6a"} width={2.5 * S.iRazmer * (2.0 / this.props.Centr.resolution) + 2} /></RStyle>
				</RFeature>

				return null;
			})}
			{this.state.Segmenti.map(S => {
				if (S.sTip == "Segment") return <RFeature geometry={new LineString(S.jDannie)}>
					<RStyle><RStroke color={"#ffffff"} width={2.5 * S.iRazmer * (2.0 / this.props.Centr.resolution)} /></RStyle>
				</RFeature>
				if (S.sTip == "SegmentZakritii") return <RFeature geometry={new LineString(S.jDannie)}>
					<RStyle><RStroke color={"#e14b00"} width={2.5 * S.iRazmer * (2.0 / this.props.Centr.resolution)} /></RStyle>
				</RFeature>
				if (S.sTip == "TochkaZakritii") return <RFeature geometry={new Point(S.jDannie)}>
					<ROverlay className="P11PX">
						<div style={{ color: "var(--GM4)" }} class={"P11PX Icon R_NoSel R_CC R_MI KR_C KR_CP"}>
							circle
						</div>
					</ROverlay>
				</RFeature>

				return null;
			})}
		</RLayerVector>;
	}
}



export class Podskazki extends Component {
	state = { Podskazki: [] };
	componentDidMount() {
		Zapros({ S: "Karta/Podskazki/Poluchit", D: {Gruppa: this.props.Gruppa} }).then(O => { this.setState({ Podskazki: O }) });
	}
	PodskazkiParser = (Item) => {
		return {
			Id: "KP" + Item.iId,
			Title: Item.sNazvanie, Desc: Item.sOpisanie,
			IconL: Item.sZnakL, IconLColor: Item.sZnakLCvet == "" ? RandomColor() : Item.sZnakLCvet,
			IconR: Item.sZnakP, IconRColor: Item.sZnakPCvet == "" ? RandomColor() : Item.sZnakPCvet
		};
	}
	render() {
		return <OOkno Aktivator={<ColTitleItemRender P Icon="tips_and_updates" Desc="Подсказки" Title="Подсказки" />}>
			<Okno Skrivaemoe Zakrivaemoe TitleIcon="view_list" TitleText="Подсказки" Rows={<></>}>
				<ContentWide AutoResize ColAutoWidth>
					{this.state.Podskazki.map(Item => <div class="WideItemBF">
						<WideItem Item={this.PodskazkiParser(Item)}/></div>
					)}
				</ContentWide>
			</Okno>
		</OOkno>;
	}
}
import { Component, createRef } from 'react';
import { Routes, Route } from 'react-router-dom';


import { fromLonLat, toLonLat } from "ol/proj";
import { boundingExtent/*, getCenter*/ } from "ol/extent";
import {
	altShiftKeysOnly,
	platformModifierKeyOnly,
	shiftKeyOnly,
//	altKeyOnly,
//	never,
	doubleClick,
} from "ol/events/condition";
import { DragBoxEvent } from "ol/interaction/DragBox";
import { Geometry, Point, Polygon, GeometryCollection, LineString } from "ol/geom";
import { Geolocation as OLGeoLoc } from "ol";
//import VectorSource from "ol/source/Vector";
import "ol/ol.css";
import "rlayers/control/layers.css";

import { RMap, RControl, ROSMWebGL, RLayerTileWebGL, RGeolocation } from "rlayers";
import { RLayerVector, RFeature, RInteraction, ROverlay, RPopup } from "rlayers";
import { RStyle, /*RIcon,*/ RFill, RStroke } from "rlayers/style";


import { Okno, OOkno } from "../Moduli/Okna"
import { Vhod, PolzovatelNull } from "../Moduli/Vhod"
import {
	ContentWide, ContentCol, ColTitleItemRender,
	WideList, WideItem, WideLink, WideInput, WideItemE, WideItemO
} from "../Moduli/Elementi"

import { Zapros, NabiratelSpiska, NabiratelFunkcii, RandomColor } from "../Moduli/Funkcii"

import { Dorogi, Podskazki } from "./Dopolnenia"
import { RedaktorObekta, OtobrajatelGruppi, OtobrajatelFiguri, Tochka, TochkaD, PutD } from "./RedaktorDopolnenia"
import "./Redaktor.scss";

const Satka = fromLonLat([59.030, 55.058]); //12.7
const Raion = boundingExtent([fromLonLat([58.864, 54.975]), fromLonLat([59.188, 55.147])]);



export default class Redaktor extends Component {
	constructor(props) {
		super(props);

		this.state = {
			Polzovatel: PolzovatelNull,
		};


	}

	componentDidMount() {
		
	}

	Test = () => {
		console.log(this.state.Polzovatel);
	}



	/*<WindowChildren TriggerItem={<ColTitleItemRender P Icon="add" Desc="Добавить пользователя" Title="Добавить" />} ItemClick={this.UserAdd}>
							<Users token={UIApi.Login.Token} apiPath={UIApi.Login.Url} docId={0} filterParam={0} />
						</WindowChildren>*/

	render() {
		return <>
			<Vhod.Consumer>{ Zapolnatel=> Zapolnatel(this, "Redaktor") }</Vhod.Consumer>
			<Okno Nazivaemoe Skrivaemoe TitleIcon="help" TitleText="СПП.Редактор"
				Rows={<>

					<WideLink To="" IconLColor="var(--GM1)" IconRColor="var(--GM2)" Path="/redaktor" Item={{
						IconL: "badge", IconR: "logout", Title: this.state.Polzovatel.ImaK, Desc: this.state.Polzovatel.Login + "(" + this.state.Polzovatel.Ima + ")"
					}} ItemRClick={(this.Vhod || {}).Viti} />
					<WideLink To="obektib" IconLColor="var(--GM2)" Path="/redaktor/obektib" Item={{
						IconL: "design_services", Title: "Большие объекты", Desc: "Площадки, Секторы, Здания"
					}}/>
					<WideLink To="tochkib" IconLColor="var(--GM2)" Path="/redaktor/tochkib" Item={{
						IconL: "grain", Title: "Точки больших объектов", Desc: "Площадки, Секторы, Здания"
					}}/>
					<WideLink To="organizaciib" IconLColor="var(--GM2)" Path="/redaktor/organizaciib" Item={{
						IconL: "design_services", Title: "Организации", Desc: ""
					}} />
					<WideLink To="tochkid" IconLColor="var(--GM2)" Path="/redaktor/tochkid" Item={{
						IconL: "polyline", Title: "Дороги", Desc: ""
					}} />
					<WideLink To="polzovateli" IconLColor="var(--GM2)" Path="/redaktor/polzovateli" Item={{
						IconL: "security", Title: "Пользователи", Desc: "Пользователи"
					}}/>

				</>}

			>
				<Routes>
					<Route index={true} element={<div>redaktor</div>} />
					<Route path="obektib" element={<ObektiB />} />
					<Route path="tochkib" element={<TochkiB />} />
					<Route path="organizaciib" element={<OrganizaciiB />} />
					<Route path="tochkid" element={<TochkiD />} />
					<Route path="polzovateli" element={<Polzovateli/>} />
					<Route path="*" element={<div>???</div>} />
					<Route path="test" element={<Test />} />
				</Routes>
				
			</Okno>
		</>
	}
}

class Test extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Centr: { center: Satka, zoom: 12.7 },
			Tochka: new Point(fromLonLat([59.030, 55.058])),
			Tochnost: null,
			Obnovleno: (new Date()).toLocaleString()
		};
		console.log(this.state);
	}

	componentDidMount() {
		console.log(this.state);
	}

	CentrPostavit = (Centr) => { this.setState({ Centr }); if (false) console.log(Centr); }
	TochkaPostavit = (ME) => {
		// Note the use of function instead of an arrow lambda
		// which does not have this
		let geoloc = ME.target;
		this.setState({
			Tochka: new Point(geoloc.getPosition()), Tochnost: geoloc.getAccuracyGeometry(), Obnovleno: (new Date()).toLocaleString()
		}, () => { if (false) console.log(this.state); })

		// Low-level access to the OpenLayers API
		//this.context.map.getView().fit(geoloc.getAccuracyGeometry(), {
		//	duration: 250,
		//	maxZoom: 15,
		//});
	}

	KartaTochka = (ME) => {
		//console.log(ME);
		//console.log(ME.map.getCoordinateFromPixel(ME.pixel));
		if (this.state.Shablon.Otkrit) {
			let { Shablon } = this.state; Shablon.Tochka = ME.map.getCoordinateFromPixel(ME.pixel);
			this.setState({ Shablon });
		}
	}

	render() {
		return <div class="RedaktorO">
			<ContentCol className="ColW500 MB0" TitleItems={<>
				<ColTitleItemRender Icon="design_services" Desc="Объекты" Title="Объекты" />

			</>}>
				
				<WideItem ItemClick={() => { }} ItemRClick={() => { }}
					Item={{
						Title: this.state.Obnovleno, Desc: JSON.stringify(this.state.Tochka.flatCoordinates),
						IconL: "crop", IconLColor: "var(--RM5)", IconR: "tune", IconRColor: "var(--GM7)"
					}}
				/>
						

			</ContentCol>
			<RMap width={"100%"} height={"100%"} extent={Raion} initial={this.state.Centr} view={[this.state.Centr, this.CentrPostavit]} onClick={this.KartaTochka} >
				<RControl.RLayers>
					<ROSMWebGL properties={{ label: "OpenStreetMap" }} />
					<RLayerTileWebGL
						properties={{ label: "OpenTopo" }}
						url="https://{a-c}.tile.opentopomap.org/{z}/{x}/{y}.png"
						attributions="Kartendaten: © OpenStreetMap-Mitwirkende, SRTM | Kartendarstellung: © OpenTopoMap (CC-BY-SA)"
					/>
					<RLayerTileWebGL
						properties={{ label: "Transport" }}
						url="http://tile.thunderforest.com/transport/{z}/{x}/{y}.png"
					/>
					<RLayerTileWebGL
						properties={{ label: "Watercolor" }}
						url="https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg"
					/>
				</RControl.RLayers>

				<RGeolocation
					tracking={true}
					trackingOptions={{ enableHighAccuracy: true }}
					onChange={this.TochkaPostavit}
				/>
				
				<RLayerVector zIndex={10}>
					<RFeature geometry={this.state.Tochka}>
						<ROverlay className="no-interaction P11PX"  >
							<div class="P11PX Icon R_NoSel R_CC R_TI" style={{ background: `radial-gradient(circle, var(--GM9), var(--GM9) 52%, transparent 55%)`, color: "var(--GM3)" }}>
								Я
							</div>
						</ROverlay>
					</RFeature>
					{(this.state.Tochnost != null) && <RFeature geometry={this.state.Tochnost}></RFeature>}
				</RLayerVector>
				


			</RMap>
		</div>
	}
}

class TochkiB extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Centr: { center: Satka, zoom: 12.7 },
			Tochka: new Point(fromLonLat([59.030, 55.058])),
			Tochnost: null, Otslejivanie: false,
			Obnovleno: (new Date()).toLocaleString(),
			Ploshadki: [], PloshadkiAktivnii: null,
			Sektori: [], SektoriAktivnii: null, 
			Zdanii: [], ZdaniiAktivnii: null,
			Aktivnii: null, AktivniiSpisok: "Ploshadki",
			TochkiAktivnii: null
		};

		NabiratelSpiska({
			C: this, Z: Zapros, T: "Ploshadki",
			Spisok: {
				S: "Obekti/B/Ploshadki/Spisok", K: "iId", N: "Загрузка списка площадок", P: () => {
					let Ploshadki = this.state.Ploshadki.map(P => { P.jDannie = JSON.parse(P.jDannie); return P; });
					this.setState({ Ploshadki });
				}
			},
			Click: {
				K: "iId", PU: () => {
					this.setState({ Aktivnii: this.state.PloshadkiAktivnii });
				}, PS: () => {
					this.setState({ Aktivnii: null });
				}
			},
			Deistvia: [
				{ DT: "Izmenit", S: "Obekti/B/Ploshadki/Izmenit", N: "Сохранение площадки", CSU: 1, M: (D => { return { ...D, jDannie: JSON.stringify(D.jDannie) }; }) }
			]
		});

		NabiratelSpiska({
			C: this, Z: Zapros, T: "Sektori",
			Spisok: {
				S: "Obekti/B/Sektori/Poluchit", K: "iId", N: "Загрузка списка секторов", M: (D) => {
					D = Object.assign({}, this.state.PloshadkiAktivnii, { jDannie: "{}" }); return D;
				}, P: () => {
					let Sektori = this.state.Sektori.map(P => { P.jDannie = JSON.parse(P.jDannie); return P; });
					this.setState({ Sektori });
				}
			},
			Click: {
				K: "iId", PU: () => {
					this.setState({ Aktivnii: this.state.SektoriAktivnii });
				}, PS: () => {
					this.setState({ Aktivnii: null });
				}
			},
			Deistvia: [
				{ DT: "Izmenit", S: "Obekti/B/Sektori/Izmenit", N: "Сохранение сектора", CSU: 1, M: (D => { return { ...D, jDannie: JSON.stringify(D.jDannie) }; }) }
			]
		});

		NabiratelSpiska({
			C: this, Z: Zapros, T: "Zdanii",
			Spisok: {
				S: "Obekti/B/Zdanii/Poluchit", K: "iId", N: "Загрузка списка зданий", M: (D) => {
					D = Object.assign({}, this.state.SektoriAktivnii, { jDannie: "{}" }); return D;
				}, P: () => {
					let Zdanii = this.state.Zdanii.map(P => { P.jDannie = JSON.parse(P.jDannie); return P; });
					this.setState({ Zdanii });
				}
			},
			Click: {
				K: "iId", PU: () => {
					this.setState({ Aktivnii: this.state.ZdaniiAktivnii });
				}, PS: () => {
					this.setState({ Aktivnii: null });
				}
			},
			Deistvia: [
				{ DT: "Izmenit", S: "Obekti/B/Zdanii/Izmenit", N: "Сохранение здания", CSU: 1, M: (D => { return { ...D, jDannie: JSON.stringify(D.jDannie) }; }) }
			]
		});
	}

	componentDidMount() {
		this.PloshadkiSpisok();
	}

	CentrPostavit = (Centr) => { this.setState({ Centr }); if (false) console.log(Centr); }
	TochkaPostavit = (ME) => {
		let geoloc = ME.target;
		this.setState({
			Tochka: new Point(geoloc.getPosition()), Tochnost: geoloc.getAccuracyGeometry(), Obnovleno: (new Date()).toLocaleString()
		})
	}

	KartaTochka = (ME) => {
		//console.log(ME);
		//console.log(ME.map.getCoordinateFromPixel(ME.pixel));
		if (this.state.Shablon.Otkrit) {
			let { Shablon } = this.state; Shablon.Tochka = ME.map.getCoordinateFromPixel(ME.pixel);
			this.setState({ Shablon });
		}
	}

	OtslejivanieIzmenit = () => {
		this.setState({ Otslejivanie: !this.state.Otslejivanie });
	}

	Otkrit = (Params) => {
		if (this.state.AktivniiSpisok == "Ploshadki") {
			this.setState({ AktivniiSpisok: "Sektori", Aktivnii: null, PloshadkiAktivnii: Params.Item }, () => { this.SektoriSpisok(); });
			
		} else if (this.state.AktivniiSpisok == "Sektori") {
			this.setState({ AktivniiSpisok: "Zdanii", Aktivnii: null, SektoriAktivnii: Params.Item }, () => { this.ZdaniiSpisok(); });
			
		}
	}
	Zakrit = (Params) => {
		if (this.state.AktivniiSpisok == "Zdanii") {
			this.setState({ AktivniiSpisok: "Sektori", SektoriAktivnii: null});
		} else if(this.state.AktivniiSpisok == "Sektori") {
			this.setState({ AktivniiSpisok: "Ploshadki", PloshadkiAktivnii: null });
		}
	}

	TochkiVibrat = (Params) => {
		let { Centr } = this.state;
		if (Centr.zoom <= 23) Centr.zoom = 23.5;
		Centr.center = this.state.Aktivnii.jDannie.Tochki[Params.Item];
		this.setState({ Centr, TochkiAktivnii: Params.Item });
	}
	TochkiIzmenit = (Params) => {
		let { Aktivnii } = this.state;
		if (Params.Deistvie) {
			if (Params.Deistvie == "P") {
				//console.log(this.state.Tochka);
				Aktivnii.jDannie.Tochki[Params.I] = this.state.Tochka.flatCoordinates;
				if (Params.I == 0) Aktivnii.jDannie.Tochki[Aktivnii.jDannie.Tochki.length - 1] = Aktivnii.jDannie.Tochki[0];
			}
			if (Params.Deistvie == "U") {
				Aktivnii.jDannie.Tochki.splice(Params.I, 1);
				if (Params.I == 0) Aktivnii.jDannie.Tochki[Aktivnii.jDannie.Tochki.length - 1] = Aktivnii.jDannie.Tochki[0];
				this.setState({ TochkiAktivnii: null });
			}
		} else {
			Aktivnii.jDannie.Tochki[Params.I] = Params.Tochka;
			if (Params.I == 0) Aktivnii.jDannie.Tochki[Aktivnii.jDannie.Tochki.length - 1] = Aktivnii.jDannie.Tochki[0];
		}
		this.setState({ Aktivnii });
	}
	TochkiSohranit = () => {
		this[this.state.AktivniiSpisok + "Izmenit"]();
	}

	PloshadkiParser = (Item) => {
		return {
			Id: "KOBP" + Item.iId,
			Title: Item.sNazvanie, Desc: Item.sOpisanie,
			IconLT: "TI", IconL: Item.sMetka, IconLColor: Item.sCvet, IconLText: Item.sCvetTekst,
			...(this.state.AktivniiSpisok == "Ploshadki" ? { IconR: "open_in_new", IconRColor: "var(--GM5)" } : { IconR: "reply", IconRColor: "var(--GM4)" })
		}
	}
	SektoriParser = (Item) => {
		return {
			Id: "KOBS" + Item.iId,
			Title: Item.sNazvanie, Desc: Item.sOpisanie,
			IconLT: "TI", IconL: Item.sMetka, IconLColor: Item.sCvet, IconLText: Item.sCvetTekst,
			...(this.state.AktivniiSpisok == "Sektori" ? { IconR: "open_in_new", IconRColor: "var(--GM5)" } : { IconR: "reply", IconRColor: "var(--GM4)" })
		}
	}
	ZdaniiParser = (Item) => {
		return {
			Id: "KOBZ" + Item.iId,
			Title: Item.sMetka + " (" + Item.sTextOT + Item.sTextOF + ")", Desc: Item.sNazvanie + " / " + Item.sOpisanie,
			IconLT: "TI", IconL: Item.iNomer, IconLColor: Item.sCvetK, IconLText: Item.sCvetTekstK
		}
	}

	TockiParser = (Item) => {
		return {
			Title: "X: " + Item[0] + "; Y: " + Item[1] + ";",// JSON.stringify(Item),
			Desc: "СШ: " + toLonLat(Item)[1] + "; ВД: " + toLonLat(Item)[0] + ";",//JSON.stringify(toLonLat(Item)),
			IconR: "edit_location", IconRColor: "var(--GM8)"
		}
	}


	render() {
		if (this.state.Aktivnii) this.TochkiKolichestvo = this.state.Aktivnii.jDannie.Tochki.length;

		return <div class="RedaktorO">
			<ContentCol className="ColW500 MB0" TitleItems={<>
				<ColTitleItemRender Icon="design_services" Desc="Объекты" Title="Объекты" />

			</>}>
				{this.state.Otslejivanie ? <WideItem ItemClick={null} ItemRClick={this.OtslejivanieIzmenit}
					Item={{
						Title: this.state.Obnovleno, Desc: JSON.stringify(this.state.Tochka.flatCoordinates) + JSON.stringify(toLonLat(this.state.Tochka.flatCoordinates)),
						IconL: "gps_fixed", IconLColor: "var(--RM5)", IconR: "check_box", IconRColor: "var(--GM9)"
					}}
				/> : <WideItem ItemClick={null} ItemRClick={this.OtslejivanieIzmenit}
					Item={{
						Title: "Положение устройства не отслеживается", Desc: "",
						IconL: "gps_fixed", IconLColor: "var(--RM5)", IconR: "check_box_outline_blank", IconRColor: "var(--GM4)"
					}}
				/>}

				{this.state.Aktivnii == null ? <>
					{(this.state.SektoriAktivnii != null) ? <WideItem Item={this.SektoriParser(this.state.SektoriAktivnii)} ItemData={this.state.SektoriAktivnii}
						ItemRClick={this.Zakrit}
					/> : ((this.state.PloshadkiAktivnii != null) && <WideItem Item={this.PloshadkiParser(this.state.PloshadkiAktivnii)} ItemData={this.state.PloshadkiAktivnii}
						ItemRClick={this.Zakrit}
					/>)}

					<WideList Items={this.state[this.state.AktivniiSpisok]} Parser={this[this.state.AktivniiSpisok + "Parser"]}
						ItemClick={this[this.state.AktivniiSpisok + "Click"]} ItemRClick={this.Otkrit}
					/>
				</> : <>
					<WideItem Item={this[this.state.AktivniiSpisok + "Parser"](this.state.Aktivnii)} ItemData={this.state.Aktivnii}
						ItemClick={this[this.state.AktivniiSpisok + "Click"]}
					/>
					<WideItem Item={{Title: "Сохранить", IconL: "save", IconLColor: "var(--GM6)"}} ItemClick={this.TochkiSohranit}/>

					{this.state.Aktivnii.jDannie.Tochki.map((T, I) => {
						if (I + 1 >= this.TochkiKolichestvo) return;
						return <Tochka I={I} Aktivnii={this.state.TochkiAktivnii} Tochka={T}
							Vibrat={this.TochkiVibrat} Izmenit={this.TochkiIzmenit}
						/>
					})}
				</>}
				

			</ContentCol>
			<RMap width={"100%"} height={"100%"} extent={Raion} initial={this.state.Centr} view={[this.state.Centr, this.CentrPostavit]} onClick={this.KartaTochka} >
				<RControl.RLayers>
					<ROSMWebGL properties={{ label: "OpenStreetMap" }} />
					<RLayerTileWebGL
						properties={{ label: "OpenTopo" }}
						url="https://{a-c}.tile.opentopomap.org/{z}/{x}/{y}.png"
						attributions="Kartendaten: © OpenStreetMap-Mitwirkende, SRTM | Kartendarstellung: © OpenTopoMap (CC-BY-SA)"
					/>
					<RLayerTileWebGL
						properties={{ label: "Transport" }}
						url="http://tile.thunderforest.com/transport/{z}/{x}/{y}.png"
					/>
					<RLayerTileWebGL
						properties={{ label: "Watercolor" }}
						url="https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg"
					/>
				</RControl.RLayers>

				<Dorogi Centr={this.state.Centr} />

				{(this.state.Aktivnii != null) && <RLayerVector>
					<RFeature geometry={new Polygon([this.state.Aktivnii.jDannie.Tochki])}>
						<RStyle><RStroke color={this.state.Aktivnii.sCvetK || this.state.Aktivnii.sCvet} width={1} /><RFill color="transparent" /></RStyle>
					</RFeature>
					<RFeature geometry={new Point(this.state.Aktivnii.jDannie.Tochki[0])}>
						<ROverlay className="no-interaction P11PX"  >
							<div class="P11PX Icon R_NoSel R_CC R_MI" style={{ color: "var(--GM7)" }}>gps_fixed</div>
							<span class="P11PX Icon R_NoSel R_CC KR_MTN">№{0}</span>
						</ROverlay>
					</RFeature>
					{this.state.Aktivnii.jDannie.Tochki.map((T, I) => {
						if (I == 0 || I + 1 == this.state.Aktivnii.jDannie.Tochki.length) return;
						return <RFeature geometry={new Point(T)}>
							<ROverlay className="no-interaction P11PX"  >
								<div class="P11PX Icon R_NoSel R_CC R_MI" style={{ color: "var(--GM8)" }}>gps_fixed</div>
								<span class="P11PX Icon R_NoSel R_CC KR_MTN">№{I}</span>
							</ROverlay>
						</RFeature>
					})}
				</RLayerVector>}

				{this.state.Otslejivanie && <>
					<RGeolocation tracking={this.state.Otslejivanie} trackingOptions={{ enableHighAccuracy: true }} onChange={this.TochkaPostavit} />
					<RLayerVector >
						<RFeature geometry={this.state.Tochka}>
							<ROverlay className="no-interaction P11PX">
								<div class="P11PX Icon R_NoSel R_CC R_MI" style={{ color: "var(--GM9)" }}>control_point</div>
							</ROverlay>
						</RFeature>
						{(this.state.Tochnost != null) && <RFeature geometry={this.state.Tochnost}></RFeature>}
					</RLayerVector>
				</>}
			</RMap>
		</div>
	}
}

class OrganizaciiB extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Centr: { center: Satka, zoom: 12.7 },
			Organizacii: [], OrganizaciiAktivnii: null,
			ZdaniiVse: [], OrganizaciiVse: []
		};

		NabiratelSpiska({
			C: this, Z: Zapros, T: "Organizacii",
			Spisok: {
				S: "Obekti/B/Organizacii/Spisok", K: "iId", N: "Загрузка списка организаций", MO: (O) => {
					return O.map(P => { P.jDannie = JSON.parse(P.jDannie); return P; });
				}
			},
			Click: { K: "iId" },
			Dobavit: { S: "Obekti/B/Organizacii/Dobavit", N: "Добавление организации", PV: 1, PO: 1 },
			Deistvia: [
				{ DT: "Izmenit", S: "Obekti/B/Organizacii/Izmenit", N: "Сохранение организации", PO: 1, CSU: 1, M: (D => { return { ...D, jDannie: JSON.stringify(D.jDannie) }; }) }
			]
		});
		
		NabiratelFunkcii({
			C: this, T: "OrganizaciiAktivnii", Poluchateli: [
				{ K: "iNomer", A: ["Tekst"] },
				{ K: "sNazvanie", A: ["Tekst"] }, { K: "sOpisanie", A: ["Tekst"] }
			], Perekluchateli: [
				{ K: "bKarta", Z: ["0", "1"] }, { K: "bUdalen", Z: ["0", "1"] }
			]
		});

		this.OrganizaciiZoni = []; 
	}

	componentDidMount() {
		this.OrganizaciiSpisok();
		Zapros({ S: "Polzovateli/Dostupi/Spisok" }).then(O => {
			let DO; let DKS = ["sText", "sNazvanie", "sOpisanie", "sZnakGruppa", "sZnak"];
			this.setState({
				DostupiSpisok: O.map(D => {
					DO = {}; DO.iDostup = D.iDostup;
					DKS.forEach(DK => { DO[DK + "D"] = D[DK] });
					return DO;
				})
			})
		});
		Zapros({ S: "Karta/Zdanii/Spisok" }).then(O => {
			this.setState({
				ZdaniiVse: O.map(P => {
					P.jDannie = JSON.parse(P.jDannie); return P;
				})
			});
		});
		Zapros({ S: "Karta/Organizacii/Spisok" }).then(O => {
			this.setState({
				OrganizaciiVse: O.map(P => {
					P.jDannie = JSON.parse(P.jDannie); return P;
				})
			});
		});
	}

	CentrPostavit = (Centr) => { this.setState({ Centr }); if (false) console.log(Centr); }
	TochkaPostavit = (ME) => {
		let geoloc = ME.target;
		this.setState({
			Tochka: new Point(geoloc.getPosition()), Tochnost: geoloc.getAccuracyGeometry(), Obnovleno: (new Date()).toLocaleString()
		})
	}

	KartaTochka = (ME) => {
		//console.log(ME);
		//console.log(ME.map.getCoordinateFromPixel(ME.pixel));
	}


	PoluchatelDannie = (ME) => {
		let Source = ME.target || {}; let RTree = Source.featuresRtree_ || {}; 
		let Items = RTree.items_ || {}; let RBush = RTree.rbush_ || {};
		if (Object.keys(Items).length !== 0) {
			let Areas = [];
			if (RBush.data.children.length == 0) return;
			let Roots = [];
			RBush.data.children.forEach(B => { Roots.push(B.value.ol_uid) });
			let Root = "";
			Roots.forEach(R => { if (Items[R].value.values_.geometry.geometries_ != undefined) Root = R; });
			if (Root == "") return;
			Roots.splice(Roots.indexOf(Root), 1);
			//let Root = RBush.data.children[0].value.ol_uid;
			//RBush.data.children.map(C => {
			//	Areas.push({ UID: C.value.ol_uid }); return C;
			//});
			//if (Items[Root].value.values_.geometry.geometries_ == undefined) return;
			//console.log(2);
			let Tochki = [];
			Areas = Items[Root].value.values_.geometry.geometries_.map(G => {
				Tochki = [];
				G.flatCoordinates.forEach((E, I, AE) => {
					if (I % 2 === 0) Tochki.push([AE[I], AE[I + 1]]);
				});
				return {
					UID: G.ol_uid, Tochki, Granici: {MinX: G.extent_[0], MinY: G.extent_[1], MaxX: G.extent_[2], MaxY: G.extent_[3]}
				}
			});
			let G = null;
			Roots.forEach(R => {
				G = Items[R].value.values_.geometry;
				Tochki = [];
				G.flatCoordinates.forEach((E, I, AE) => {
					if (I % 2 === 0) Tochki.push([AE[I], AE[I + 1]]);
				});
				
				Areas.push({
					UID: G.ol_uid, Tochki, Granici: { MinX: G.extent_[0], MinY: G.extent_[1], MaxX: G.extent_[2], MaxY: G.extent_[3] }
				});
			});

			/*Areas = Areas.map(A => {
				A.Granici = { MinX: Items[A.UID].minX, MinY: Items[A.UID].minY, MaxX: Items[A.UID].maxX, MaxY: Items[A.UID].maxY };

				//A.Center = { X: (Items[A.UID].minX + , Y: }
				A.Tochki = [];
				Items[A.UID].value.values_.geometry.flatCoordinates.forEach((E, I, AE) => {
					if (I % 2 === 0) A.Tochki.push([AE[I], AE[I + 1]]);
				});
				//A.Tochki = Items[A.UID].value.values_.geometry.flatCoordinates;
				return A;
			});*/
			//this.ZoniE = Areas[0];
			

			if (this.state.OrganizaciiAktivnii != null) {
				this.OrganizaciiZoni[this.state.OrganizaciiAktivnii.iId] = Areas;
			}
		}
	}
	ZapisatDannie = () => {
		//console.log(this.OrganizaciiZoni[this.state.OrganizaciiAktivnii.iId]); return;
		if (this.OrganizaciiZoni[this.state.OrganizaciiAktivnii.iId] == undefined) return;
		let { OrganizaciiAktivnii } = this.state; OrganizaciiAktivnii.jDannie = [];
		this.OrganizaciiZoni[this.state.OrganizaciiAktivnii.iId].forEach(Z => {
			OrganizaciiAktivnii.jDannie.push(Object.assign({}, Z));
		});
		//OrganizaciiAktivnii.jDannie = Object.assign({}, this.OrganizaciiZoni[this.state.OrganizaciiAktivnii.iId]);
		this.setState({ OrganizaciiAktivnii: null }, () => { this.setState({ OrganizaciiAktivnii }) });
	}
	OchistitDannie = () => {
		let { OrganizaciiAktivnii } = this.state;
		OrganizaciiAktivnii.jDannie = [];
		this.setState({ OrganizaciiAktivnii });
	}
	ObrezatDannie = (Params) => {
		let { OrganizaciiAktivnii } = this.state;
		OrganizaciiAktivnii.jDannie.splice(Number(Params.Tekst), 1);
		this.setState({ OrganizaciiAktivnii });
	}

	NaitiCentr = (Obekti) => {
		let MinX = Obekti[0].Granici.MinX, MinY = Obekti[0].Granici.MinY, MaxX = Obekti[0].Granici.MaxX, MaxY = Obekti[0].Granici.MaxY;
		Obekti.forEach(O => {
			if (O.Granici.MinX < MinX) MinX = O.Granici.MinX;
			if (O.Granici.MinY < MinY) MinY = O.Granici.MinY;
			if (O.Granici.MaxX > MaxX) MaxX = O.Granici.MaxX;
			if (O.Granici.MaxY > MaxY) MaxY = O.Granici.MaxY;
		});
		return [(MinX + MaxX) / 2, (MinY + MaxY) / 2];
	}

	OrganizaciiParser = (Item) => {
		return {
			Id: "KOBP" + Item.iId,
			Title: Item.sNazvanie, Desc: Item.sOpisanie,
			IconLT: "TI", IconL: Item.iNomer, IconLColor: Item.sCvet, IconLText: Item.sCvetTekst,
		}
	}

	PloshadkiDostupiParser = (Item) => {
		return {
			Id: "KPD" + Item.iDostup,
			Title: Item.sNazvanieD, Desc: Item.sOpisanieD,
			IconL: Item.sZnakD, IconLColor: RandomColor()
		}
	}

	render() {
		return <div class="RedaktorO">
			<ContentCol className="ColW500 MB0" TitleItems={<>
				<ColTitleItemRender Icon="design_services" Desc="Объекты" Title="Объекты" />

			</>}>

				{this.state.OrganizaciiAktivnii == null ? <>
					<WideItem ItemClick={this.OrganizaciiSpisok}
						Item={{ Title: "Организации", Desc: "Список организаций", IconL: "business", IconLColor: "var(--RM5)" }}
					/>
					<WideList Items={this.state.Organizacii} ItemClick={this.OrganizaciiClick} Parser={this.OrganizaciiParser} />
					<WideItem Item={{ Title: "Добавить", Desc: "Добавить организацию", IconL: "add_photo_alternate", IconLColor: "var(--GM7)" }} ItemClick={this.OrganizaciiDobavit} />
				</> : <>
						<RedaktorObekta C={this} O="Organizacii" />
				</>}


			</ContentCol>
			<RMap width={"100%"} height={"100%"} extent={Raion} initial={this.state.Centr} view={[this.state.Centr, this.CentrPostavit]} onClick={this.KartaTochka} >
				<RControl.RLayers>
					<ROSMWebGL properties={{ label: "OpenStreetMap" }} />
					<RLayerTileWebGL
						properties={{ label: "OpenTopo" }}
						url="https://{a-c}.tile.opentopomap.org/{z}/{x}/{y}.png"
						attributions="Kartendaten: © OpenStreetMap-Mitwirkende, SRTM | Kartendarstellung: © OpenTopoMap (CC-BY-SA)"
					/>

					<RLayerTileWebGL
						properties={{ label: "CyclOSM" }}
						url="https://{a-c}.tile-cyclosm.openstreetmap.fr/cyclosm/{z}/{x}/{y}.png"
						attributions="CyclOSM"
					/>

					<RLayerTileWebGL
						properties={{ label: "Transport" }}
						url="http://tile.thunderforest.com/transport/{z}/{x}/{y}.png"
					/>
					<RLayerTileWebGL
						properties={{ label: "Watercolor" }}
						url="https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg"
					/>
				</RControl.RLayers>

				<Dorogi Centr={this.state.Centr} />

				<RLayerVector>
				{this.state.ZdaniiVse.map(P => {
					if ((P.jDannie.Tochki !== undefined) && (this.state.Centr.zoom >= 13.5)) {
						return <RFeature geometry={new Polygon([P.jDannie.Tochki])}>
							<RStyle><RStroke color={P.sCvetK} width={1} /><RFill color={P.sCvetK.replace(")", ", 0.25)")} /></RStyle>
						</RFeature>;
					} else return null;
				})}
				{this.state.ZdaniiVse.map(P => {
					if ((P.jDannie.Tochki !== undefined) && (this.state.Centr.zoom >= 19) && (this.state.Centr.zoom <= 30)) {
						return <RFeature geometry={new Point([(P.jDannie.Granici.MinX + P.jDannie.Granici.MaxX) / 2, (P.jDannie.Granici.MinY + P.jDannie.Granici.MaxY) / 2])}>
							<ROverlay className="no-interaction P11PX"><div class="R_NoSel K_ZM">{P.sMetka}</div></ROverlay></RFeature>;
					} else return null;
				})}
				</RLayerVector>
				<RLayerVector>
					{this.state.OrganizaciiVse.map(O => {
						if ((O.jDannie.length > 0) && (O.bKarta == 1) && (this.state.Centr.zoom >= 14) && (this.state.Centr.zoom <= 30)) {
							return O.jDannie.map(OD => <RFeature geometry={new Polygon([OD.Tochki])}>
								<RStyle>
									<RStroke color={O.sCvet} width={2} />
									<RFill color="transparent" />
								</RStyle>
							</RFeature>)
						}
					})}
				</RLayerVector>
				<RLayerVector>
					{this.state.OrganizaciiVse.map(O => { //${O.sCvet}
						if ((O.jDannie.length > 0) && (O.bKarta == 1) && (this.state.Centr.zoom >= 17.5) && (this.state.Centr.zoom <= 30)) {
							return <RFeature geometry={new Point(this.NaitiCentr(O.jDannie))}>
								<ROverlay className="no-interaction P11PX">
									<div class="P11PX Icon R_NoSel R_CC R_TI" style={{ background: `radial-gradient(circle, transparent 44%, rgb(0,0,0) 52%, transparent 55%)`, color: 'rgb(0,0,0)' }}>
										{O.iNomer}
									</div>
								</ROverlay>
							</RFeature>
						}
					})}
				</RLayerVector>

				<RLayerVector>
					{this.state.Organizacii.map(O => {
						if ((this.state.OrganizaciiAktivnii == null) && (O.jDannie.length > 0) && (O.bKarta == 1) && (this.state.Centr.zoom >= 14) && (this.state.Centr.zoom <= 30)) {
							return O.jDannie.map(OD => <RFeature geometry={new Polygon([OD.Tochki])}>
								<RStyle>
									<RStroke color={O.sCvet} width={2} />
									<RFill color="transparent" />
								</RStyle>
							</RFeature>)
						}
					})}
				</RLayerVector>
				<RLayerVector>
					{this.state.Organizacii.map(O => { //${O.sCvet}
						if ((this.state.OrganizaciiAktivnii == null) && (O.jDannie.length > 0) && (O.bKarta == 1) && (this.state.Centr.zoom >= 15) && (this.state.Centr.zoom <= 30)) {
							return <RFeature geometry={new Point(this.NaitiCentr(O.jDannie))}>
								<ROverlay className="no-interaction P11PX">
									<div class="P11PX Icon R_NoSel R_CC R_TI" style={{ background: `radial-gradient(circle, transparent 44%, rgb(0,0,0) 52%, transparent 55%)`, color: 'rgb(0,0,0)' }}>
										{O.iNomer}
									</div>
								</ROverlay>
							</RFeature>
						}
					})}
				</RLayerVector>


				{(this.state.OrganizaciiAktivnii != null) && <RLayerVector onChange={this.PoluchatelDannie}>
					{/*this.state.OrganizaciiAktivnii.jDannie.map((D, I) => {
						if (D.Tochki !== undefined)
							return <RFeature geometry={new Polygon([D.Tochki])}>
								<RStyle>
									<RStroke color={this.state.OrganizaciiAktivnii.sCvet} width={1} />
									<RFill color="rgba(0, 0, 0, 0.2)" />
								</RStyle>
							</RFeature>
					})*/}
					<RFeature geometry={new GeometryCollection(
						//this.state.OrganizaciiAktivnii.jDannie.map(D => D.Tochki)
						this.state.OrganizaciiAktivnii.jDannie.map(D => new Polygon([D.Tochki]))
					)}>
						<RStyle>
							<RStroke color={this.state.OrganizaciiAktivnii.sCvet} width={1} />
							<RFill color="rgba(0, 0, 0, 0.2)" />
						</RStyle>
					</RFeature>
					
					<RStyle>
						<RStroke color={this.state.OrganizaciiAktivnii.sCvet} width={1} />
						<RFill color="rgba(0, 0, 0, 0.2)" />
					</RStyle>

					<RInteraction.RDraw type={"Polygon"} condition={shiftKeyOnly} freehandCondition={altShiftKeysOnly} />
					<RInteraction.RModify condition={platformModifierKeyOnly} deleteCondition={(e) => platformModifierKeyOnly(e) && doubleClick(e)} />
				</RLayerVector>}
				{(this.state.OrganizaciiAktivnii != null) && <RLayerVector>
					{this.state.OrganizaciiAktivnii.jDannie.map((D, I) => {
						if (D.Tochki !== undefined)
							return <RFeature geometry={new Point([(D.Granici.MinX + D.Granici.MaxX) / 2, (D.Granici.MinY + D.Granici.MaxY) / 2])}>
									<ROverlay className="no-interaction P11PX"  >
										<div class="P11PX Icon R_NoSel R_CC R_TI" style={{ background: `radial-gradient(circle, transparent 44%, ${this.state.OrganizaciiAktivnii.sCvet} 52%, transparent 55%)`, color: this.state.OrganizaciiAktivnii.sCvet }}>
											{I}
										</div>
									</ROverlay>
								</RFeature>
					})}
					<RStyle>
						<RStroke color={this.state.OrganizaciiAktivnii.sCvet} width={1} />
						<RFill color="rgba(0, 0, 0, 0.2)" />
					</RStyle>
				</RLayerVector>}
				{(this.state.OrganizaciiAktivnii != null) && <RLayerVector>
					{this.state.OrganizaciiAktivnii.jDannie.map((D, J) => {
						if (D.Tochki !== undefined)
							return D.Tochki.map((T, I) => {
								return <RFeature geometry={new Point(T)}>
									<ROverlay className="no-interaction P11PX"  >
										<div class="P11PX Icon R_NoSel R_CC R_MI" style={{ color: this.state.OrganizaciiAktivnii.sCvet }}>gps_fixed</div>
									</ROverlay>
								</RFeature>
							})
					})}
				</RLayerVector>}
			</RMap>
		</div>
	}
}

class ObektiB extends Component {
	constructor(props) {
		super(props);

		this.state = {
			Centr: { center: Satka, zoom: 12.7 },
			Shablon: null,
			Vvod: false,
			PloshadkiTipi: [], SektoriTipi: [], KopleksiTipi: [], ObektiTipi: [], ObektiFormati: [],
			Ploshadki: [], PloshadkiAktivnii: null, PloshadkiIzmenenie: true,
			Sektori: [], SektoriAktivnii: null, SektoriIzmenenie: true,
			Zdanii: [], ZdaniiAktivnii: null, ZdaniiIzmenenie: true,
			ZdaniiVse: [], OrganizaciiVse: []
		};
		this.PloshadkiZoni = []; this.SektoriZoni = []; this.ZdaniiZoni = [];
		
		NabiratelSpiska({
			C: this, Z: Zapros, T: "Ploshadki",
			Spisok: {
				S: "Obekti/B/Ploshadki/Spisok", K: "iId", N: "Загрузка списка площадок", P: () => {
					let Ploshadki = this.state.Ploshadki.map(P => { P.jDannie = JSON.parse(P.jDannie); return P; });
					this.setState({ Ploshadki });
				}
			},
			Click: {
				K: "iId", PU: () => {
					if (!this.state.PloshadkiIzmenenie) this.SektoriSpisok();
				}, PS: () => {
					this.setState({ Sektori: [], SektoriAktivnii: null, Zdanii: [], ZdaniiAktivnii: null });
				}
			},
			Dobavit: { S: "Obekti/B/Ploshadki/Dobavit", N: "Добавление площадки", PV: 1, PO: 1 },
			Deistvia: [
				{ DT: "Izmenit", S: "Obekti/B/Ploshadki/Izmenit", N: "Сохранение площадки", CSU: 1, PO: 1, M: (D => { return { ...D, jDannie: JSON.stringify(D.jDannie) }; }) }
			]
		});

		NabiratelSpiska({
			C: this, Z: Zapros, T: "Sektori",
			Spisok: {
				S: "Obekti/B/Sektori/Poluchit", K: "iId", N: "Загрузка списка секторов", M: (D) => {
					D = Object.assign({}, this.state.PloshadkiAktivnii, {jDannie: "{}"}); return D;
				}, P: () => {
					let Sektori = this.state.Sektori.map(P => { P.jDannie = JSON.parse(P.jDannie); return P; });
					this.setState({ Sektori });
				}
			},
			Click: {
				K: "iId", PU: () => {
					if (!this.state.SektoriIzmenenie) this.ZdaniiSpisok();
				}, PS: () => {
					this.setState({ Zdanii: [], ZdaniiAktivnii: null });
				}
			},
			Dobavit: {
				S: "Obekti/B/Sektori/Dobavit", N: "Добавление сектора", M: (D) => {
					D = Object.assign({}, this.state.PloshadkiAktivnii, { jDannie: "{}" }); return D;
				}, PV: 1, PO: 1 },
			Deistvia: [
				{ DT: "Izmenit", S: "Obekti/B/Sektori/Izmenit", N: "Сохранение сектора", CSU: 1, PO: 1, M: (D => { return { ...D, jDannie: JSON.stringify(D.jDannie) }; }) }
			]
		});

		NabiratelSpiska({
			C: this, Z: Zapros, T: "Zdanii",
			Spisok: {
				S: "Obekti/B/Zdanii/Poluchit", K: "iId", N: "Загрузка списка зданий", M: (D) => {
					D = Object.assign({}, this.state.SektoriAktivnii, { jDannie: "{}" }); return D;
				}, P: () => {
					let Zdanii = this.state.Zdanii.map(P => { P.jDannie = JSON.parse(P.jDannie); return P; });
					this.setState({ Zdanii });
				}
			},
			Click: {
				K: "iId", PU: () => {}, PS: () => {}
			},
			Dobavit: {
				S: "Obekti/B/Zdanii/Dobavit", N: "Добавление здания", M: (D) => {
					D = Object.assign({}, this.state.SektoriAktivnii, { jDannie: "{}" }); return D;
				}, PV: 1, PO: 1
			},
			Deistvia: [
				{ DT: "Izmenit", S: "Obekti/B/Zdanii/Izmenit", N: "Сохранение здания", CSU: 1, PO: 1, M: (D => { return { ...D, jDannie: JSON.stringify(D.jDannie) }; }) }
			]
		});

		NabiratelFunkcii({
			C: this, T: "Shablon", Poluchateli: [
				{ K: "Prozrachnost", A: ["Tekst"], M: [(Z) => Number(Z)] },
				{ K: "Shirina", A: ["Tekst"], M: [(Z) => Number(Z)] },
				{ K: "Povorot", A: ["Tekst"], M: [(Z) => Number(Z)] }
			], Perekluchateli: [
				{K: "Otkrit", Z: [false, true]}
			]
		});
		NabiratelFunkcii({
			C: this, T: "PloshadkiAktivnii", Poluchateli: [
				{ K: "iNomer", A: ["Tekst"] }, { K: "cBukva", A: ["Tekst"] },
				{ K: "sNazvanie", A: ["Tekst"] }, { K: "sOpisanie", A: ["Tekst"] }
			], Perekluchateli: [
				{ K: "bKarta", Z: ["0", "1"] }, { K: "bUdalen", Z: ["0", "1"] }
			]
		});
		NabiratelFunkcii({
			C: this, T: "SektoriAktivnii", Poluchateli: [
				{ K: "iNomer", A: ["Tekst"] },
				{ K: "sNazvanie", A: ["Tekst"] }, { K: "sOpisanie", A: ["Tekst"] }
			], Perekluchateli: [
				{ K: "bKarta", Z: ["0", "1"] }, { K: "bUdalen", Z: ["0", "1"] }
			]
		});
		NabiratelFunkcii({
			C: this, T: "ZdaniiAktivnii", Poluchateli: [
				{ K: "iNomer", A: ["Tekst"] },
				{ K: "sNazvanie", A: ["Tekst"] }, { K: "sOpisanie", A: ["Tekst"] },
			], Perekluchateli: [
				{ K: "bKarta", Z: ["0", "1"] }, { K: "bUdalen", Z: ["0", "1"] }
			]
		});
	}

	componentDidMount() {
		Zapros({ S: "Obekti/B/Ploshadki/Tipi" }).then(O => { this.setState({ PloshadkiTipi: O }) });
		Zapros({ S: "Obekti/B/Sektori/Tipi" }).then(O => { this.setState({ SektoriTipi: O }) });
		Zapros({ S: "Obekti/B/Kompleksi/Tipi" }).then(O => { this.setState({ KompleksiTipi: O }) });
		Zapros({ S: "Obekti/B/Obekti/Tipi" }).then(O => { this.setState({ ObektiTipi: O }) });
		Zapros({ S: "Obekti/B/Obekti/Formati" }).then(O => { this.setState({ ObektiFormati: O }) });
		Zapros({ S: "Polzovateli/Dostupi/Spisok" }).then(O => {
			let DO; let DKS = ["sText", "sNazvanie", "sOpisanie", "sZnakGruppa", "sZnak"];
			this.setState({
				DostupiSpisok: O.map(D => {
					DO = {}; DO.iDostup = D.iDostup;
					DKS.forEach(DK => { DO[DK + "D"] = D[DK] });
					return DO;
				})
			})
		});
		Zapros({ S: "Karta/Zdanii/Spisok" }).then(O => {
			this.setState({
				ZdaniiVse: O.map(P => {
					P.jDannie = JSON.parse(P.jDannie); return P;
				})
			});
		});
		Zapros({ S: "Karta/Organizacii/Spisok" }).then(O => {
			this.setState({
				OrganizaciiVse: O.map(P => {
					P.jDannie = JSON.parse(P.jDannie); return P;
				})
			});
		});
		this.PloshadkiSpisok();
	};
	componentWillUnmount() {
		if (this.state.Shablon) URL.revokeObjectURL(this.state.Shablon.Url);
	}

	CentrPostavit = (Centr) => { this.setState({ Centr }); if (false) console.log(Centr); }

	KartaTochka = (ME) => {
		//console.log(ME);
		//console.log(ME.map.getCoordinateFromPixel(ME.pixel));
		if (this.state.Shablon.Otkrit) {
			let { Shablon } = this.state; Shablon.Tochka = ME.map.getCoordinateFromPixel(ME.pixel);
			this.setState({ Shablon });
		}
	}

	ShablonZagruzit = (IE) => {
		let SS = (Shablon) => { this.setState({ Shablon }); }; 
		let Shablon = {Otkrit: false, Tochka: null, Shirina: 500, Prozrachnost: 0.5, Povorot: 0};
		

		let FR = new FileReader();
		FR.onload = function () {
			Shablon.AB = FR.result;
			Shablon.Blob = new Blob([new Uint8Array(Shablon.AB, 0, Shablon.AB.byteLength)], { type: IE.target.files[0].type });
			Shablon.Url = URL.createObjectURL(Shablon.Blob);
			//console.log(Shablon);
			SS(Shablon);
		};
		FR.readAsArrayBuffer(IE.target.files[0]);
	}
	ShablonOchistit = () => {
		URL.revokeObjectURL(this.state.Shablon.Url);
		this.setState({ Shablon: null });
	}
	ShablonIzmenit = () => {
		let { Shablon } = this.state;
		if (Shablon == null) return;
		Shablon.Otkrit = !Shablon.Otkrit;
		this.setState({Shablon});
	}

	NaitiCentr = (Obekti) => {
		let MinX = Obekti[0].Granici.MinX, MinY = Obekti[0].Granici.MinY, MaxX = Obekti[0].Granici.MaxX, MaxY = Obekti[0].Granici.MaxY;
		Obekti.forEach(O => {
			if (O.Granici.MinX < MinX) MinX = O.Granici.MinX;
			if (O.Granici.MinY < MinY) MinY = O.Granici.MinY;
			if (O.Granici.MaxX > MaxX) MaxX = O.Granici.MaxX;
			if (O.Granici.MaxY > MaxY) MaxY = O.Granici.MaxY;
		});
		return [(MinX + MaxX) / 2, (MinY + MaxY) / 2];
	}

	PloshadkiIzmenenieIzmenit = () => { this.setState({ PloshadkiIzmenenie: !this.state.PloshadkiIzmenenie }); }
	SektoriIzmenenieIzmenit = () => { this.setState({ SektoriIzmenenie: !this.state.SektoriIzmenenie }); }

	PloshadkiAktivniiProveratel = (Params) => {
		if (Params.length == 0) return "";
		if (Params[0] == ' ') return Params.substring(1);
		return Params;
	}


	PoluchatelDannie = (ME) => {
		let Source = ME.target || {}; let RTree = Source.featuresRtree_ || {};
		let Items = RTree.items_ || {}; let RBush = RTree.rbush_ || {};
		if (Object.keys(Items).length !== 0) {
			let Areas = [];
			RBush.data.children.map(C => {
				Areas.push({ UID: C.value.ol_uid }); return C;
			});
			Areas = Areas.map(A => {
				A.Granici = { MinX: Items[A.UID].minX, MinY: Items[A.UID].minY, MaxX: Items[A.UID].maxX, MaxY: Items[A.UID].maxY };
				//A.Center = { X: (Items[A.UID].minX + , Y: }
				A.Tochki = [];
				Items[A.UID].value.values_.geometry.flatCoordinates.forEach((E, I, AE) => {
					if (I % 2 === 0) A.Tochki.push([AE[I], AE[I + 1]]);
				});
				//A.Tochki = Items[A.UID].value.values_.geometry.flatCoordinates;
				return A;
			});
			//this.ZoniE = Areas[0];
			if (this.state.PloshadkiAktivnii != null) {
				if (this.state.SektoriAktivnii != null) {
					if (this.state.ZdaniiAktivnii != null) {
						this.ZdaniiZoni[this.state.ZdaniiAktivnii.iId] = Areas[0];
					}
					else this.SektoriZoni[this.state.SektoriAktivnii.iId] = Areas[0];
				} else this.PloshadkiZoni[this.state.PloshadkiAktivnii.iId] = Areas[0];
			}
			
		}
	}
	ZapisatDannie = () => {
		if (this.state.PloshadkiAktivnii != null) {
			if (this.state.SektoriAktivnii != null) {
				if (this.state.ZdaniiAktivnii != null) {
					if (this.ZdaniiZoni[this.state.ZdaniiAktivnii.iId] == undefined) return;
					let { ZdaniiAktivnii } = this.state;
					ZdaniiAktivnii.jDannie = Object.assign({}, this.ZdaniiZoni[this.state.ZdaniiAktivnii.iId]);
					this.setState({ ZdaniiAktivnii });
				} else {
					if (this.SektoriZoni[this.state.SektoriAktivnii.iId] == undefined) return;
					let { SektoriAktivnii } = this.state;
					SektoriAktivnii.jDannie = Object.assign({}, this.SektoriZoni[this.state.SektoriAktivnii.iId]);
					this.setState({ SektoriAktivnii });
				}
			} else {
				if (this.PloshadkiZoni[this.state.PloshadkiAktivnii.iId] == undefined) return;
				let { PloshadkiAktivnii } = this.state;
				PloshadkiAktivnii.jDannie = Object.assign({}, this.PloshadkiZoni[this.state.PloshadkiAktivnii.iId]);
				this.setState({ PloshadkiAktivnii });
			}
		}
	}
	OchistitDannie = () => {
		if (this.state.PloshadkiAktivnii != null) {
			if (this.state.SektoriAktivnii != null) {
				if (this.state.ZdaniiAktivnii != null) {
					let { ZdaniiAktivnii } = this.state;
					ZdaniiAktivnii.jDannie = {};
					this.setState({ ZdaniiAktivnii });
				} else {
					let { SektoriAktivnii } = this.state;
					SektoriAktivnii.jDannie = {};
					this.setState({ SektoriAktivnii });
				}
			} else {
				let { PloshadkiAktivnii } = this.state;
				PloshadkiAktivnii.jDannie = {};
				this.setState({ PloshadkiAktivnii });
			}
		}
	}

	PloshadkiTipiParser = (Item) => {
		return {
			Id: "KOBPt" + Item.iTip,
			Title: Item.sNazvanieP, Desc: Item.sOpisanieP,
			IconLT: "TI", IconL: "", IconLColor: Item.sCvet, IconLText: Item.sCvetTekst
		}
	}

	PloshadkiParser = (Item) => {
		return {
			Id: "KOBP" + Item.iId,
			Title: Item.sNazvanie, Desc: Item.sOpisanie,
			IconLT: "TI", IconL: Item.sMetka, IconLColor: Item.sCvet, IconLText: Item.sCvetTekst
		}
	}
	PloshadkiDostupiParser = (Item) => {
		return {
			Id: "KPD" + Item.iDostup,
			Title: Item.sNazvanieD, Desc: Item.sOpisanieD,
			IconL: Item.sZnakD, IconLColor: RandomColor()
		}
	}
	SektoriTipiParser = (Item) => {
		return {
			Id: "KOBSt" + Item.iTip,
			Title: Item.sNazvanieS, Desc: Item.sOpisanieS,
			IconLT: "TI", IconL: "", IconLColor: Item.sCvet, IconLText: Item.sCvetTekst
		}
	}
	SektoriParser = (Item) => {
		return {
			Id: "KOBS" + Item.iId,
			Title: Item.sNazvanie, Desc: Item.sOpisanie,
			IconLT: "TI", IconL: Item.sMetka, IconLColor: Item.sCvet, IconLText: Item.sCvetTekst
		}
	}

	ZdaniiParser = (Item) => {
		return {
			Id: "KOBZ" + Item.iId,
			Title: Item.sMetka + " (" + Item.sTextOT + Item.sTextOF + ")", Desc: Item.sNazvanie + " / " + Item.sOpisanie,
			IconLT: "TI", IconL: Item.iNomer, IconLColor: Item.sCvetK, IconLText: Item.sCvetTekstK
		}
	}

	KompleksiParser = (Item) => {
		return {
			Id: "KOBZK" + Item.iKompleks,
			Title: Item.sNazvanieK, Desc: Item.sOpisanieK,
			IconLT: "TI", IconL: Item.sTextK[0], IconLColor: Item.sCvetK, IconLText: Item.sCvetTekstK
		}
	}
	ObektiTipiParser = (Item) => {
		return {
			Id: "KOBZOT" + Item.iTip,
			Title: Item.sNazvanieOT, Desc: Item.sOpisanieOT,
			IconLT: "TI", IconL: Item.sTextOT, IconLColor: "var(--RM6)", IconLText: "var(--GM3)"
		}
	}
	ObektiFormatiParser = (Item) => {
		return {
			Id: "KOBZOF" + Item.iFormat,
			Title: Item.sNazvanieOF, Desc: Item.sOpisanieOF,
			IconLT: "TI", IconL: Item.sTextOF, IconLColor: "var(--RM6)", IconLText: "var(--GM3)"
		}
	}

	render() {
		return <div class="RedaktorO">
			<ContentCol className="ColW500 MB0" TitleItems={<>
				<ColTitleItemRender Icon="design_services" Desc="Объекты" Title="Объекты" />
				<Podskazki Gruppa="РедакторОбектыБольшие" />
			</>}>
				<div class="WideItem P">
					<div onClick={this.ShablonIzmenit} class="Icon R_NoSel R_CC R_MI R_FS30" style={{
						color: this.state.Shablon ? (this.state.Shablon.Otkrit ? "var(--GM7)" : "var(--GM9)") : "var(--RM5)"
					}}>filter</div>
					<div class="Item">
						<span class="Text">
							<input type="file" id="file-selector" accept=".jpg, .jpeg, .png, .gif" class="Title" onChange={this.ShablonZagruzit} />
							<div class="Desc" title={""}>{"Фоновое изображение для шаблона"}</div>
						</span>
					</div>
					<div onClick={this.ShablonOchistit} class="Icon R_NoSel R_CC R_MI R_FS30" style={{color: "var(--GM2)"}}>backspace</div>
				</div>
				{(this.state.Shablon != null) && (this.state.Shablon.Otkrit) && <>
					<WideItemE Poluchatel={this.ShablonPoluchatelProzrachnost} Item={{ Title: this.state.Shablon.Prozrachnost, Desc: "Прозрачность", IconL: "opacity" }}/>
					<WideItemE Poluchatel={this.ShablonPoluchatelShirina} Item={{ Title: this.state.Shablon.Shirina, Desc: "Ширина", IconL: "aspect_ratio" }}/>
					<WideItemE Poluchatel={this.ShablonPoluchatelPovorot} Item={{ Title: this.state.Shablon.Povorot, Desc: "Поворот", IconL: "flip_camera_android" }}/>
				</>}
				{(this.state.PloshadkiIzmenenie) ? <>
					{(this.state.PloshadkiAktivnii == null) ? <>
						<WideItem ItemClick={this.PloshadkiSpisok} ItemRClick={this.PloshadkiIzmenenieIzmenit}
							Item={{ Title: "Площадки", Desc: "Список площадок", IconL: "crop", IconLColor: "var(--RM5)", IconR: "tune", IconRColor: "var(--GM7)" }}
						/>
						<WideList Items={this.state.Ploshadki} ItemClick={this.PloshadkiClick} Parser={this.PloshadkiParser} />
						<WideItem Item={{ Title: "Добавить", Desc: "Добавить площадку", IconL: "add_photo_alternate", IconLColor: "var(--GM7)" }} ItemClick={this.PloshadkiDobavit} />
					</> : <>

						<RedaktorObekta C={this} O="Ploshadki" />
					</>}
				</> : <>
					{(this.state.PloshadkiAktivnii == null) ? <>
						<WideItem ItemClick={this.PloshadkiSpisok} ItemRClick={this.PloshadkiIzmenenieIzmenit}
							Item={{ Title: "Площадки", Desc: "Список площадок", IconL: "crop", IconLColor: "var(--RM5)", IconR: "view_list", IconRColor: "var(--GM7)" }}
						/>
						<WideList Items={this.state.Ploshadki} ItemClick={this.PloshadkiClick} Parser={this.PloshadkiParser} />
					</> : <>
						<WideList Items={[this.state.PloshadkiAktivnii]} ItemClick={this.PloshadkiClick} Parser={this.PloshadkiParser} />
						{this.state.SektoriIzmenenie ? <>
							{(this.state.SektoriAktivnii == null) ? <>
								<WideItem ItemClick={this.SektoriSpisok} ItemRClick={this.SektoriIzmenenieIzmenit}
									Item={{ Title: "Секторы", Desc: "Список секторов", IconL: "location_searching", IconLColor: "var(--RM5)", IconR: "tune", IconRColor: "var(--GM7)" }}
								/>
								<WideList Items={this.state.Sektori} ItemClick={this.SektoriClick} Parser={this.SektoriParser} />
								<WideItem Item={{ Title: "Добавить", Desc: "Добавить сектор", IconL: "add_photo_alternate", IconLColor: "var(--GM7)" }} ItemClick={this.SektoriDobavit} />
							</> : <>
								<RedaktorObekta C={this} O="Sektori" />
							</>}
						</> : <>
							{this.state.SektoriAktivnii == null ? <>
								<WideItem ItemClick={this.SektoriSpisok} ItemRClick={this.SektoriIzmenenieIzmenit}
									Item={{ Title: "Секторы", Desc: "Список секторов", IconL: "location_searching", IconLColor: "var(--RM5)", IconR: "view_list", IconRColor: "var(--GM7)" }}
								/>
								<WideList Items={this.state.Sektori} ItemClick={this.SektoriClick} Parser={this.SektoriParser} />
							</> : <>
								<WideList Items={[this.state.SektoriAktivnii]} ItemClick={this.SektoriClick} Parser={this.SektoriParser} />
								{this.state.ZdaniiAktivnii == null ? <>
									<WideItem ItemClick={this.ZdaniiSpisok}
										Item={{ Title: "Здания", Desc: "Список зданий", IconL: "business", IconLColor: "var(--RM5)" }}
									/>
									<WideList Items={this.state.Zdanii} ItemClick={this.ZdaniiClick} Parser={this.ZdaniiParser} />
									<WideItem Item={{ Title: "Добавить", Desc: "Добавить здание", IconL: "add_photo_alternate", IconLColor: "var(--GM7)" }} ItemClick={this.ZdaniiDobavit} />
								</> : <>
									<RedaktorObekta C={this} O="Zdanii" />
								</>}
							</>}

						</>}
					</>}
				</>}
				
			</ContentCol>
			<RMap width={"100%"} height={"100%"} extent={Raion} initial={this.state.Centr} view={[this.state.Centr, this.CentrPostavit]} onClick={this.KartaTochka} >
				<RControl.RLayers>
					<ROSMWebGL properties={{ label: "OpenStreetMap" }} />
					<RLayerTileWebGL properties={{ label: "OpenTopo" }} url="https://{a-c}.tile.opentopomap.org/{z}/{x}/{y}.png" attributions="Kartendaten: © OpenStreetMap-Mitwirkende, SRTM | Kartendarstellung: © OpenTopoMap (CC-BY-SA)" />
					<RLayerTileWebGL properties={{ label: "Transport" }} url="http://tile.thunderforest.com/transport/{z}/{x}/{y}.png" />
					<RLayerTileWebGL properties={{ label: "Watercolor" }} url="https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg"/>
				</RControl.RLayers>

				<Dorogi Centr={this.state.Centr} />

				{(this.state.Shablon != null) && <RLayerVector>
					{(this.state.Shablon.Tochka != null) && <RFeature geometry={new Point(this.state.Shablon.Tochka)}>
						<ROverlay className="no-interaction P11PX"  >
							<div class="P11PX" style={{ transform: "rotate(" + this.state.Shablon.Povorot + "deg)" }}>
								<img class="IMG" src={this.state.Shablon.Url} style={{ width: this.state.Shablon.Shirina, opacity: this.state.Shablon.Prozrachnost }} alt="" />
							</div>
						</ROverlay>
					</RFeature>}
				</RLayerVector>}

				<RLayerVector>
					{this.state.ZdaniiVse.map(P => {
					if ((P.jDannie.Tochki !== undefined) && (this.state.Centr.zoom >= 13.5)) {
						return <RFeature geometry={new Polygon([P.jDannie.Tochki])}>
							<RStyle><RStroke color={P.sCvetK} width={1} /><RFill color={P.sCvetK.replace(")", ", 0.25)")} /></RStyle>
						</RFeature>;
					} else return null;
				})}
				{this.state.ZdaniiVse.map(P => {
					if ((P.jDannie.Tochki !== undefined) && (this.state.Centr.zoom >= 19) && (this.state.Centr.zoom <= 30)) {
						return <RFeature geometry={new Point([(P.jDannie.Granici.MinX + P.jDannie.Granici.MaxX) / 2, (P.jDannie.Granici.MinY + P.jDannie.Granici.MaxY) / 2])}>
							<ROverlay className="no-interaction P11PX"><div class="R_NoSel K_ZM">{P.sMetka}</div></ROverlay></RFeature>;
					} else return null;
				})}
				</RLayerVector>

				<RLayerVector>
					{this.state.OrganizaciiVse.map(O => {
						if ((O.jDannie.length > 0) && (O.bKarta == 1) && (this.state.Centr.zoom >= 14) && (this.state.Centr.zoom <= 30)) {
							return O.jDannie.map(OD => <RFeature geometry={new Polygon([OD.Tochki])}>
								<RStyle>
									<RStroke color={O.sCvet} width={2} />
									<RFill color="transparent" />
								</RStyle>
							</RFeature>)
						}
					})}
				</RLayerVector>
				<RLayerVector>
					{this.state.OrganizaciiVse.map(O => { //${O.sCvet}
						if ((O.jDannie.length > 0) && (O.bKarta == 1) && (this.state.Centr.zoom >= 17.5) && (this.state.Centr.zoom <= 30)) {
							return <RFeature geometry={new Point(this.NaitiCentr(O.jDannie))}>
								<ROverlay className="no-interaction P11PX">
									<div class="P11PX Icon R_NoSel R_CC R_TI" style={{ background: `radial-gradient(circle, transparent 44%, rgb(0,0,0) 52%, transparent 55%)`, color: 'rgb(0,0,0)' }}>
										{O.iNomer}
									</div>
								</ROverlay>
							</RFeature>
						}
					})}
				</RLayerVector>

				<RLayerVector>
					<OtobrajatelGruppi C={this} O="Ploshadki" MFOT={11.5} MMOT={10} MMDO={14.5} />
					<OtobrajatelGruppi C={this} O="Sektori" MFOT={11.5} MMOT={14} MMDO={17.5} />
					<OtobrajatelGruppi C={this} O="Zdanii" MFOT={11.5} MMOT={17} MMDO={30} Cvet="sCvetK" />
				</RLayerVector>

				<OtobrajatelFiguri C={this} O="Ploshadki" />
				<OtobrajatelFiguri C={this} O="Sektori" />
				<OtobrajatelFiguri C={this} O="Zdanii" Cvet="sCvetK" />
			</RMap>
		</div>
	}
}

class TochkiD extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Centr: { center: Satka, zoom: 12.7 },
			Tochka: new Point(fromLonLat([59.030, 55.058])),
			Tochnost: null, Otslejivanie: false,
			Obnovleno: (new Date()).toLocaleString(),
			Tochki: [], TochkiAktivnii: null, TochkiPokazat: [],
			ViborVse: false, ViborIshodashie: false, ViborVhodashie: false,
			TochkaPuti: [], TochkaPutiAktivnii: null,
			Puti: [], PutiAktivnii: null, PutiPokazat: [],
			TochkaNovaa: { X: 6565881.670465297, Y: 7374121.623138507, Vibor: false },
			PokazivatNomera: false,
			ZdaniiVse: []
		};
		this.TochkiSkritieRef = createRef(); this.PutiSkritieRef = createRef();
		this.Zona = null;

		NabiratelSpiska({
			C: this, Z: Zapros, T: "Tochki",
			Spisok: { S: "Obekti/D/Tochki/Spisok", K: "iId", N: "Загрузка списка точек" },
			Click: {
				K: "iId",
				PU: () => { this.TochkaPutiSpisok(); this.setState({ TochkiPokazat: [] }); },
				PS: () => { this.setState({ TochkaPuti: [], TochkaPutiAktivnii: null }); this.ViborSbros(); }
			},
			Dobavit: {
				S: "Obekti/D/Tochki/Dobavit", N: "Добавление точки", PO: 1, M: (D) => {
					return { X: this.state.TochkaNovaa.X.toString(), Y: this.state.TochkaNovaa.Y.toString() }
				}
			},
			Deistvia: [
				{ DT: "Izmenit", S: "Obekti/D/Tochki/Izmenit", N: "Сохранение точки", CSU: 1, PO: 1 },
				{ DT: "Pokazat", S: "Obekti/D/Tochki/Pokazat", N: "Сохранение точек", PO: 1, M: (D) => this.state.TochkiPokazat, P: () => { this.setState({ TochkiPokazat: [] }); } },
			]
		});

		NabiratelSpiska({
			C: this, Z: Zapros, T: "TochkaPuti",
			Spisok: {
				S: "Obekti/D/Puti/Poluchit/Vse", K: "iId", N: "Загрузка списка путей", M: (D => this.state.TochkiAktivnii), P: () => { this.PutiSpisok(); }
			},
			//Click: { K: "iId" },
			Dobavit: {S: "Obekti/D/Puti/Dobavit", N: "Добавление пути", PO: 1, FPU: 1},
			Deistvia: [
				{ DT: "Izmenit", S: "Obekti/D/Puti/Izmenit", N: "Сохранение пути", CSU: 1, PO: 1 },
			]
		});
		NabiratelSpiska({
			C: this, Z: Zapros, T: "Puti",
			Spisok: { S: "Obekti/D/Puti/Spisok", K: "iId", N: "Загрузка списка путей" },
			Click: { K: "iId", PU: () => { this.setState({ PutiPokazat: [] }); } },
			Deistvia: [
				{ DT: "Izmenit", S: "Obekti/D/Puti/Izmenit", N: "Сохранение пути", CSU: 1, PO: 1 },
				{ DT: "Pokazat", S: "Obekti/D/Puti/Pokazat", N: "Сохранение путей", PO: 1, M: (D) => this.state.PutiPokazat, P: () => { this.setState({ PutiPokazat: [] }); } },
			]
		});

		NabiratelFunkcii({
			C: this, T: "TochkaNovaa", Poluchateli: [
				{ K: "X", A: ["Tekst"], M: [(Z) => Number(Z)] },
				{ K: "Y", A: ["Tekst"], M: [(Z) => Number(Z)] },
				{ NF: "E", K: "X", A: ["Tekst"], M: [(Z) => fromLonLat([Number(Z), 0])[0]] },
				{ NF: "N", K: "Y", A: ["Tekst"], M: [(Z) => fromLonLat([0, Number(Z)])[1]] }
			], Perekluchateli: [
				{ K: "Vibor", Z: [false, true] }
			]
		});
		NabiratelFunkcii({
			C: this, T: "TochkiAktivnii", Poluchateli: [
				{ K: "X", A: ["Tekst"], M: [(Z) => Number(Z)] },
				{ K: "Y", A: ["Tekst"], M: [(Z) => Number(Z)] },
				{ NF: "E", K: "X", A: ["Tekst"], M: [(Z) => fromLonLat([Number(Z), 0])[0]] },
				{ NF: "N", K: "Y", A: ["Tekst"], M: [(Z) => fromLonLat([0, Number(Z)])[1]] },
				{ K: "iRazmer", A: ["Tekst"]}
			], Perekluchateli: [
				{ K: "bUdalen", Z: ["0", "1"] }, { K: "bKarta", Z: ["0", "1"] }, { K: "bZakrit", Z: ["0", "1"] }
			]
		});
		NabiratelFunkcii({
			C: this, T: "PutiAktivnii", Perekluchateli: [
				{ K: "bUdalen", Z: ["0", "1"] }, { K: "bKarta", Z: ["0", "1"] }, { K: "bZakrit", Z: ["0", "1"] }
			]
		});
	}

	componentDidMount() {
		this.TochkiSpisok();
		this.PutiSpisok();
		Zapros({ S: "Polzovateli/Dostupi/Spisok" }).then(O => {
			let DO; let DKS = ["sText", "sNazvanie", "sOpisanie", "sZnakGruppa", "sZnak"];
			this.setState({
				DostupiSpisok: O.map(D => {
					DO = {}; DO.iDostup = D.iDostup;
					DKS.forEach(DK => { DO[DK + "D"] = D[DK] });
					return DO;
				})
			})
		});
		Zapros({ S: "Karta/Zdanii/Spisok" }).then(O => {
			this.setState({
				ZdaniiVse: O.map(P => {
					P.jDannie = JSON.parse(P.jDannie); return P;
				})
			});
		});
	}

	//Puti

	CentrPostavit = (Centr) => { this.setState({ Centr }); if (false) console.log(Centr); }
	TochkaPostavit = (ME) => {
		let geoloc = ME.target;
		this.setState({
			Tochka: new Point(geoloc.getPosition()), Tochnost: geoloc.getAccuracyGeometry(), Obnovleno: (new Date()).toLocaleString()
		})
	}

	KartaTochka = (ME) => {
		//console.log(ME);
		//console.log(ME.map.getCoordinateFromPixel(ME.pixel));
		if (this.state.TochkaNovaa.Vibor) {
			let P = ME.map.getCoordinateFromPixel(ME.pixel);
			this.setState({ TochkaNovaa: { ...this.state.TochkaNovaa, X: P[0], Y: P[1] } });
		} else if (this.state.TochkiAktivnii) {
			if (this.state.ViborVse || this.state.ViborIshodashie || this.state.ViborVhodashie) {
				//console.log("Puti");
			} else {
				let P = ME.map.getCoordinateFromPixel(ME.pixel);
				let { TochkiAktivnii } = this.state;
				TochkiAktivnii.X = P[0].toString(); TochkiAktivnii.Y = P[1].toString();
				this.setState({ TochkiAktivnii });
			}
		}
	}

	PokazivatNomeraIzmenit = () => { this.setState({ PokazivatNomera: !this.state.PokazivatNomera }); }
	OtslejivanieIzmenit = () => { this.setState({ Otslejivanie: !this.state.Otslejivanie }); }

	OtslejivaniePostavit = () => {
		if (this.state.TochkiAktivnii) {
			let { TochkiAktivnii } = this.state;
			TochkiAktivnii.X = this.state.Tochka.flatCoordinates[0].toString(); TochkiAktivnii.Y = this.state.Tochka.flatCoordinates[1].toString();
			this.setState({ TochkiAktivnii });
		} else
			this.setState({ TochkaNovaa: { ...this.state.TochkaNovaa, X: this.state.Tochka.flatCoordinates[0], Y: this.state.Tochka.flatCoordinates[1] } });
	}


	TochkiNaiti = (Params) => {
		if (this.state.Tochki.filter(T => T.iId == Params.Tekst).length == 0) return;
		this.TochkiClick({ Item: this.state.Tochki.filter(T => T.iId == Params.Tekst)[0] });
	}

	ViborSbros = () => { this.setState({ ViborVse: false, ViborIshodashie: false, ViborVhodashie: false }); }
	ViborVseIzmenit = () => { this.setState({ ViborVse: !this.state.ViborVse, ViborIshodashie: false, ViborVhodashie: false }); }
	ViborIshodashieIzmenit = () => { this.setState({ ViborVse: false, ViborIshodashie: !this.state.ViborIshodashie, ViborVhodashie: false }); }
	ViborVhodashieIzmenit = () => { this.setState({ ViborVse: false, ViborIshodashie: false, ViborVhodashie: !this.state.ViborVhodashie }); }

	TochkiSoedinit = (Params) => {
		if (this.state.ViborVse) {
			//console.log("Soedinit: " + this.state.TochkiAktivnii.iId + " -> " + Params.Item.iId);
			//console.log("Soedinit: " + Params.Item.iId + " -> " + this.state.TochkiAktivnii.iId);
			this.TochkaPutiDobavit({ iNachalo: this.state.TochkiAktivnii.iId, iKonec: Params.Item.iId });
			this.TochkaPutiDobavit({ iNachalo: Params.Item.iId, iKonec: this.state.TochkiAktivnii.iId });
			this.TochkiClick(Params);
		} else if (this.state.ViborIshodashie) {
			//console.log("Soedinit: " + this.state.TochkiAktivnii.iId + " -> " + Params.Item.iId);
			this.TochkaPutiDobavit({ iNachalo: this.state.TochkiAktivnii.iId, iKonec: Params.Item.iId });
			this.TochkiClick(Params);
		} else {
			//console.log("Soedinit: " + Params.Item.iId + " -> " + this.state.TochkiAktivnii.iId);
			this.TochkaPutiDobavit({ iNachalo: Params.Item.iId, iKonec: this.state.TochkiAktivnii.iId });
			this.TochkiClick(Params);
		}
	}

	PutiAktivniiObratnii = () => {
		Zapros({ S: "Obekti/D/Puti/Poluchit/Odin", D: { iId: this.state.PutiAktivnii.iIdO } }).then(O => {
			if (O.length > 0)
				this.setState({ PutiAktivnii: O[0] });
		});
	}



	PloshadkiDostupiParser = (Item) => {
		return {
			Id: "KPD" + Item.iDostup,
			Title: Item.sNazvanieD, Desc: Item.sOpisanieD,
			IconL: Item.sZnakD, IconLColor: RandomColor()
		}
	}

	ZonaNachalo = (E) => { this.Zona = E.coordinate; }
	ZonaKonec = (E) => {
		if ((this.state.TochkiAktivnii != null) || (this.state.PutiAktivnii != null)) return;
		let TochkiPokazat = [], PutiPokazat = [];
		if (this.TochkiSkritieRef.current && this.Zona)
			this.TochkiSkritieRef.current.source.forEachFeatureInExtent(
				boundingExtent([this.Zona, E.coordinate]), (f) => TochkiPokazat.push(f.get("iId")) && false
			);
		if (this.PutiSkritieRef.current && this.Zona)
			this.PutiSkritieRef.current.source.forEachFeatureInExtent(
				boundingExtent([this.Zona, E.coordinate]), (f) => PutiPokazat.push(f.get("iId")) && false
			);
		//console.log(TochkiPokazat);
		//console.log(PutiPokazat);
		this.setState({ TochkiPokazat, PutiPokazat })
		this.Zona = null;
	}

	render() {
		//if (this.state.Aktivnii) this.TochkiKolichestvo = this.state.Aktivnii.jDannie.Tochki.length;
		this.TochkaNovaaLL = toLonLat([this.state.TochkaNovaa.X, this.state.TochkaNovaa.Y]);
		if (this.state.TochkiAktivnii)
			this.TochkiAktivniiLL = toLonLat([this.state.TochkiAktivnii.X.replace(',', '.'), this.state.TochkiAktivnii.Y.replace(',', '.')]);

		return <div class="RedaktorO">
			<ContentCol className="ColW500 MB0" TitleItems={<>
				<ColTitleItemRender Icon="design_services" Desc="Дороги" Title="Дороги" />
				<Podskazki Gruppa="РедакторДороги" />
			</>}>
				{this.state.Otslejivanie ? <WideItem ItemClick={null} ItemRClick={this.OtslejivanieIzmenit}
					Item={{
						Title: this.state.Obnovleno, Desc: JSON.stringify(this.state.Tochka.flatCoordinates) + JSON.stringify(toLonLat(this.state.Tochka.flatCoordinates)),
						IconL: "gps_fixed", IconLColor: "var(--RM5)", IconR: "check_box", IconRColor: "var(--GM9)"
					}}
				/> : <WideItem ItemClick={null} ItemRClick={this.OtslejivanieIzmenit}
					Item={{
						Title: "Положение устройства не отслеживается", Desc: "",
						IconL: "gps_fixed", IconLColor: "var(--RM5)", IconR: "check_box_outline_blank", IconRColor: "var(--GM4)"
					}}
				/>}
				<WideItem Item={{ Title: "Положение устройства", Desc: "Взять координаты точки от устройства", IconL: "share_location", IconLColor: "var(--GM9)" }} ItemClick={this.OtslejivaniePostavit} />
				<WideItem ItemClick={this.PokazivatNomeraIzmenit} Item={{
					Desc: "Показать/скрыть номера", IconLT: "TI", IconL: "№",
					...(this.state.PokazivatNomera
						? { Title: "Номера точек/путей отображены", IconR: "visibility", IconRColor: "var(--GM9)" }
						: { Title: "Номера точек/путей скрыты", IconR: "visibility_off", IconRColor: "var(--GM4)" })
				}} />

				{(this.state.TochkiAktivnii == null) && (this.state.PutiAktivnii == null) && <>
					<WideItemE Poluchatel={this.TochkiNaiti} Item={{ Desc: "Введите номер точки или выберите её на карте для редактирования", IconL: "pin_drop", IconLColor: "var(--GM2)" }} />
					<WideItem Item={this.state.TochkaNovaa.Vibor
						? { Title: "Выберите место на карте", Desc: "Выберите место на карте для получения координат", IconL: "ads_click", IconLColor: "var(--GM7)" }
						: { Title: "Введите точку", Desc: "Введите координаты точки или смените режим", IconL: "edit", IconLColor: "var(--GM8)" }
					} ItemClick={this.TochkaNovaaPerekluchatelVibor} />
					<WideItemE Poluchatel={this.TochkaNovaaPoluchatelX} Item={{ Title: this.state.TochkaNovaa.X, Desc: "X", IconL: "X", IconLT: "TI" }} />
					<WideItemE Poluchatel={this.TochkaNovaaPoluchatelY} Item={{ Title: this.state.TochkaNovaa.Y, Desc: "Y", IconL: "Y", IconLT: "TI" }} />
					<WideItemE Poluchatel={this.TochkaNovaaPoluchatelN} Item={{ Title: this.TochkaNovaaLL[1], Desc: "СШ(N)", IconLT: "TI", IconL: "N" }} />
					<WideItemE Poluchatel={this.TochkaNovaaPoluchatelE} Item={{ Title: this.TochkaNovaaLL[0], Desc: "ВД(E)", IconLT: "TI", IconL: "E" }} />
					<WideItem Item={{ Title: "Добавить", Desc: "Добавить точку с этими координатами", IconL: "add_location_alt", IconLColor: "var(--GM7)" }} ItemClick={this.TochkiDobavit} />
					{(this.state.TochkiPokazat.length > 0) && <WideItem ItemClick={this.TochkiPokazat} Item={{
						Title: "Отметить точки видимыми", Desc: "Отметить выбранные точки видимыми на карте", IconL: "visibility", IconLColor: "var(--GM7)"
					}} />}
					{(this.state.PutiPokazat.length > 0) && <WideItem ItemClick={this.PutiPokazat} Item={{
						Title: "Отметить пути видимыми", Desc: "Отметить выбранные пути видимыми на карте", IconL: "visibility", IconLColor: "var(--GM7)"
					}} />}
				</>}
				{(this.state.TochkiAktivnii != null) && (this.state.PutiAktivnii == null) && <>
					<WideItem ItemData={this.state.TochkiAktivnii} ItemClick={this.TochkiClick} Item={{
						Title: "Точка №" + this.state.TochkiAktivnii.iId, IconL: "circle", IconLColor: "var(--GM1)", IconR: "cancel_presentation", IconRColor: "var(--GM2)"
					}} />
					<WideItemO Item={{
						Title: this.state.TochkiAktivnii.sNazvanieD, Desc: "Доступ",
						IconL: this.state.TochkiAktivnii.sZnakD, IconLColor: "var(--RM6)",
						IconR: "view_list", IconRColor: "var(--GM8)"
					}} Items={this.state.DostupiSpisok} ItemParser={this.PloshadkiDostupiParser} ItemClick={this.TochkiAktivniiIzmenatel} />
					<WideItemE Poluchatel={this.TochkiAktivniiPoluchatelX} Item={{ Title: this.state.TochkiAktivnii.X, Desc: "X", IconL: "X", IconLT: "TI" }} />
					<WideItemE Poluchatel={this.TochkiAktivniiPoluchatelY} Item={{ Title: this.state.TochkiAktivnii.Y, Desc: "Y", IconL: "Y", IconLT: "TI" }} />
					<WideItemE Poluchatel={this.TochkiAktivniiPoluchatelN} Item={{ Title: this.TochkiAktivniiLL[1], Desc: "СШ(N)", IconLT: "TI", IconL: "N" }} />
					<WideItemE Poluchatel={this.TochkiAktivniiPoluchatelE} Item={{ Title: this.TochkiAktivniiLL[0], Desc: "ВД(E)", IconLT: "TI", IconL: "E" }} />
					<WideItemE Poluchatel={this.TochkiAktivniiPoluchateliRazmer} Item={{ Title: this.state.TochkiAktivnii.iRazmer, Desc: "Размер", IconLT: "TI", IconL: this.state.TochkiAktivnii.iRazmer }} />
					<WideItem ItemClick={this.TochkiAktivniiPerekluchatelbZakrit} Item={{
						Desc: "Доступность для движения", IconL: "navigation", IconLColor: "var(--GM1)",
						...(this.state.TochkiAktivnii.bZakrit == 0
							? { Title: "Открыто для движения", IconR: "launch", IconRColor: "var(--GM9)" }
							: { Title: "Закрыто для движения", IconR: "block", IconRColor: "var(--GM4)" })
					}} />
					<WideItem ItemClick={this.TochkiAktivniiPerekluchatelbKarta} Item={{
						Desc: "Состояние отображения", IconL: "map", IconLColor: "var(--GM8)",
						...(this.state.TochkiAktivnii.bKarta == 1
							? { Title: "Отображается на карте", IconR: "visibility", IconRColor: "var(--GM9)" }
							: { Title: "Не отображается на карте", IconR: "visibility_off", IconRColor: "var(--GM4)" })
					}} />
					<WideItem ItemClick={this.TochkiAktivniiPerekluchatelbUdalen} Item={{
						Desc: "Состояние удаления", IconL: "delete_outline", IconLColor: "var(--GM8)",
						...(this.state.TochkiAktivnii.bUdalen == 1
							? { Title: "Удалено", IconR: "check_box", IconRColor: "var(--GM4)" }
							: { Title: "Не удалено", IconR: "check_box_outline_blank", IconRColor: "var(--GM9)" })
					}} />
					<WideItem ItemClick={this.TochkiIzmenit} Item={{ Title: "Сохранить", Desc: "Сохранить изменения в записи", IconL: "save", IconLColor: "var(--GM6)" }} />
					<WideItem ItemClick={this.ViborVseIzmenit} Item={{
						Title: "Соединение точек (в обе стороны)", Desc: "Выберите, чтобы соединить точки",
						IconL: "sync_alt", IconLColor: "var(--GM6)",
						...(this.state.ViborVse
							? { IconR: "check_box", IconRColor: "var(--GM1)" }
							: { IconR: "check_box_outline_blank", IconRColor: "var(--GM2)" }
						)
					}} />
					<WideItem ItemClick={this.ViborIshodashieIzmenit} Item={{
						Title: "Соединение точек (только исходящие)", Desc: "Выберите, чтобы соединить точки",
						IconL: "zoom_out_map", IconLColor: "var(--GM7)",
						...(this.state.ViborIshodashie
							? { IconR: "check_box", IconRColor: "var(--GM1)" }
							: { IconR: "check_box_outline_blank", IconRColor: "var(--GM2)" }
						)
					}} />
					<WideItem ItemClick={this.ViborVhodashieIzmenit} Item={{
						Title: "Соединение точек (только входящие)", Desc: "Выберите, чтобы соединить точки",
						IconL: "zoom_in_map", IconLColor: "var(--GM8)",
						...(this.state.ViborVhodashie
							? { IconR: "check_box", IconRColor: "var(--GM1)" }
							: { IconR: "check_box_outline_blank", IconRColor: "var(--GM2)" }
						)
					}} />
				</>}
				{(this.state.TochkiAktivnii == null) && (this.state.PutiAktivnii != null) && <>
					<WideItem ItemData={this.state.PutiAktivnii} ItemClick={this.PutiClick} Item={{
						Title: "Путь №" + this.state.PutiAktivnii.iId, Desc: "Точки: №" + this.state.PutiAktivnii.iNachalo + " -> №" + this.state.PutiAktivnii.iKonec,
						IconL: "horizontal_rule", IconLColor: "var(--GM1)", IconR: "cancel_presentation", IconRColor: "var(--GM2)"
					}} />
					<WideItem ItemClick={this.PutiAktivniiPerekluchatelbZakrit} Item={{
						Desc: "Доступность для движения", IconL: "navigation", IconLColor: "var(--GM1)",
						...(this.state.PutiAktivnii.bZakrit == 0
							? { Title: "Открыто для движения", IconR: "launch", IconRColor: "var(--GM9)" }
							: { Title: "Закрыто для движения", IconR: "block", IconRColor: "var(--GM4)" })
					}} />
					<WideItem ItemClick={this.PutiAktivniiPerekluchatelbKarta} Item={{
						Desc: "Состояние отображения", IconL: "map", IconLColor: "var(--GM8)",
						...(this.state.PutiAktivnii.bKarta == 1
							? { Title: "Отображается на карте", IconR: "visibility", IconRColor: "var(--GM9)" }
							: { Title: "Не отображается на карте", IconR: "visibility_off", IconRColor: "var(--GM4)" })
					}} />
					<WideItem ItemClick={this.PutiAktivniiPerekluchatelbUdalen} Item={{
						Desc: "Состояние удаления", IconL: "delete_outline", IconLColor: "var(--GM8)",
						...(this.state.PutiAktivnii.bUdalen == 1
							? { Title: "Удалено", IconR: "check_box", IconRColor: "var(--GM4)" }
							: { Title: "Не удалено", IconR: "check_box_outline_blank", IconRColor: "var(--GM9)" })
					}} />
					<WideItem ItemClick={this.PutiIzmenit} Item={{ Title: "Сохранить", Desc: "Сохранить изменения в записи", IconL: "save", IconLColor: "var(--GM6)" }} />
					{this.state.PutiAktivnii.iIdO != null
						? <WideItem ItemData={this.state.PutiAktivnii} ItemClick={this.PutiAktivniiObratnii} Item={{
							Title: "Открыть обратный путь №" + this.state.PutiAktivnii.iIdO, Desc: "Точки: №" + this.state.PutiAktivnii.iKonec + " -> №" + this.state.PutiAktivnii.iNachalo,
							IconL: "horizontal_rule", IconLColor: "var(--GM1)", IconR: "reply", IconRColor: "var(--GM2)"
						}} />
						: <WideItem Item={{ Title: "Обратный путь отсутвует", Desc: "", IconL: "block", IconLColor: "var(--GM2)" }} />
					}
				</>}

				{false && (this.state.Aktivnii == null ? <>
					{(this.state.SektoriAktivnii != null) ? <WideItem Item={this.SektoriParser(this.state.SektoriAktivnii)} ItemData={this.state.SektoriAktivnii}
						ItemRClick={this.Zakrit}
					/> : ((this.state.PloshadkiAktivnii != null) && <WideItem Item={this.PloshadkiParser(this.state.PloshadkiAktivnii)} ItemData={this.state.PloshadkiAktivnii}
						ItemRClick={this.Zakrit}
					/>)}

					<WideList Items={this.state[this.state.AktivniiSpisok]} Parser={this[this.state.AktivniiSpisok + "Parser"]}
						ItemClick={this[this.state.AktivniiSpisok + "Click"]} ItemRClick={this.Otkrit}
					/>
				</> : <>
					<WideItem Item={this[this.state.AktivniiSpisok + "Parser"](this.state.Aktivnii)} ItemData={this.state.Aktivnii}
						ItemClick={this[this.state.AktivniiSpisok + "Click"]}
					/>
					<WideItem Item={{ Title: "Сохранить", IconL: "save", IconLColor: "var(--GM6)" }} ItemClick={this.TochkiSohranit} />

					{this.state.Aktivnii.jDannie.Tochki.map((T, I) => {
						if (I + 1 >= this.TochkiKolichestvo) return;
						return <Tochka I={I} Aktivnii={this.state.TochkiAktivnii} Tochka={T}
							Vibrat={this.TochkiVibrat} Izmenit={this.TochkiIzmenit}
						/>
					})}
				</>)}


			</ContentCol>
			<RMap width={"100%"} height={"100%"} extent={Raion} initial={this.state.Centr} view={[this.state.Centr, this.CentrPostavit]} onClick={this.KartaTochka} >
				<RControl.RLayers>
					<ROSMWebGL properties={{ label: "OpenStreetMap" }} />
					<RLayerTileWebGL
						properties={{ label: "OpenTopo" }}
						url="https://{a-c}.tile.opentopomap.org/{z}/{x}/{y}.png"
						attributions="Kartendaten: © OpenStreetMap-Mitwirkende, SRTM | Kartendarstellung: © OpenTopoMap (CC-BY-SA)"
					/>
					<RLayerTileWebGL
						properties={{ label: "Transport" }}
						url="http://tile.thunderforest.com/transport/{z}/{x}/{y}.png"
					/>
					<RLayerTileWebGL
						properties={{ label: "Watercolor" }}
						url="https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg"
					/>
				</RControl.RLayers>

				

				<RLayerVector>
					{this.state.ZdaniiVse.map(P => {
						if ((P.jDannie.Tochki !== undefined) && (this.state.Centr.zoom >= 13.5)) {
							return <RFeature geometry={new Polygon([P.jDannie.Tochki])}>
								<RStyle><RStroke color={P.sCvetK} width={1} /><RFill color={P.sCvetK.replace(")", ", 0.25)")} /></RStyle>
							</RFeature>;
						} else return null;
					})}
					{this.state.ZdaniiVse.map(P => {
						if ((P.jDannie.Tochki !== undefined) && (this.state.Centr.zoom >= 19) && (this.state.Centr.zoom <= 30)) {
							return <RFeature geometry={new Point([(P.jDannie.Granici.MinX + P.jDannie.Granici.MaxX) / 2, (P.jDannie.Granici.MinY + P.jDannie.Granici.MaxY) / 2])}>
								<ROverlay className="no-interaction P11PX"><div class="R_NoSel K_ZM">{P.sMetka}</div></ROverlay></RFeature>;
						} else return null;
					})}
				</RLayerVector>

				{this.state.Otslejivanie && <>
					<RGeolocation tracking={this.state.Otslejivanie} trackingOptions={{ enableHighAccuracy: true }} onChange={this.TochkaPostavit} />
					<RLayerVector >
						<RFeature geometry={this.state.Tochka}>
							<ROverlay className="no-interaction P11PX">
								<div class="P11PX Icon R_NoSel R_CC R_MI" style={{ color: "var(--GM9)" }}>control_point</div>
							</ROverlay>
						</RFeature>
						{(this.state.Tochnost != null) && <RFeature geometry={this.state.Tochnost}></RFeature>}
					</RLayerVector>
				</>}
				
				<RLayerVector >
					<RFeature geometry={new Point([this.state.TochkaNovaa.X, this.state.TochkaNovaa.Y])}>
						<ROverlay className="no-interaction P11PX">
							<div class="P11PX Icon R_NoSel R_CC R_MI" style={{ color: "var(--GM7)" }}>ads_click</div>
						</ROverlay>
					</RFeature>
				</RLayerVector>

				<RLayerVector>
					{this.state.Tochki.filter(T => T.bKarta == "1").map(T => <TochkaD key={"TD" + T.iId} C={this} Tochka={T} />)}
				</RLayerVector>
				<RLayerVector ref={this.TochkiSkritieRef} >
					{this.state.Tochki.filter(T => T.bKarta == "0").map(T => <TochkaD key={"TD" + T.iId} C={this} Tochka={T} />)}
					{(this.state.TochkiAktivnii == null) && <RInteraction.RDragBox condition={shiftKeyOnly} onBoxStart={this.ZonaNachalo} onBoxEnd={this.ZonaKonec} />}
				</RLayerVector>

				<RLayerVector>
					<RStyle><RStroke color="#6a6a6a" width={8} /></RStyle>
					{this.state.Puti.filter(P => P.bKarta == "1" && P.bZakrit == "0" && P.iId != this.state.PutiAktivnii).map(P => <PutD key={"PD" + P.iId} C={this} Put={P} />)}
				</RLayerVector>
				<RLayerVector>
					<RStyle><RStroke color="#e14b00" width={8} /></RStyle>
					{this.state.Puti.filter(P => P.bKarta == "1" && P.bZakrit == "1" && P.iId != this.state.PutiAktivnii).map(P => <PutD key={"PD" + P.iId} C={this} Put={P} />)}
				</RLayerVector>
				<RLayerVector ref={this.PutiSkritieRef} >
					<RStyle><RStroke color="#dedede" width={8} /></RStyle>
					{this.state.Puti.filter(P => P.bKarta == "0" && P.iId != this.state.PutiAktivnii).map(P => <PutD key={"PD" + P.iId} C={this} Put={P} />)}
					{(this.state.PutiAktivnii == null) && <RInteraction.RDragBox condition={shiftKeyOnly} onBoxStart={this.ZonaNachalo} onBoxEnd={this.ZonaKonec} />}
				</RLayerVector>
				<RLayerVector>
					<RStyle><RStroke color="#ffaf00" width={8} /></RStyle>
					{this.state.Puti.filter(P => this.state.PutiPokazat.includes(P.iId)).map(P => <PutD key={"PDV" + P.iId} C={this} Put={P} />)}
				</RLayerVector>
				<RLayerVector>
					<RStyle><RStroke color="#ff8400" width={8} /></RStyle>
					{(this.state.PutiAktivnii != null) && <PutD key={"PD" + this.state.PutiAktivnii.iId} C={this} Put={this.state.PutiAktivnii} />}
				</RLayerVector>

				<RLayerVector>
					{this.state.TochkaPuti.filter(P => P.iNachalo == (this.state.TochkiAktivnii || {}).iId).map(P => <RFeature geometry={new LineString([
							[P.NachaloX.replace(',', '.'), P.NachaloY.replace(',', '.')],
							[P.KonecX.replace(',', '.'), P.KonecY.replace(',', '.')]
						])}>
							<RStyle>
								<RStroke color="#00b5ff" width={12} />
							</RStyle>
						</RFeature>
					)}
				</RLayerVector>
				<RLayerVector>
					{this.state.TochkaPuti.filter(P => P.iKonec == (this.state.TochkiAktivnii || {}).iId).map(P =>
						<RFeature geometry={new LineString([
							[P.NachaloX.replace(',', '.'), P.NachaloY.replace(',', '.')],
							[P.KonecX.replace(',', '.'), P.KonecY.replace(',', '.')]
						])}>
							<RStyle>
								<RStroke color="#5700ff" width={4} />
							</RStyle>
						</RFeature>
					)}
				</RLayerVector>

				
			</RMap>
		</div>
	}
}



class Polzovateli extends Component {
	constructor(props) {
		super(props);

		this.state = {
			//Polzovatel: PolzovatelNull,
			Login: "", Text: "", Polzovatel: null,
			Polzovateli: [], PolzovateliAktivnii: null,
			DostupiSpisok: [], Dostupi: []
		};

		NabiratelSpiska({
			C: this, Z: Zapros, T: "Polzovateli",
			Spisok: { K: "iId", N: "Загрузка списка пользователей" },
			Click: { K: "iId", PU: () => { this.DostupiSpisok(); this.setState({ Polzovatel: null, Text: "" }) }, PS: () => { this.setState({ Dostupi: [] }) } },
			Deistvia: [
				{ DT: "Ubrat", PO: 1, CSU: 1, N: "Убирание пользователя" }
			]
		});

		NabiratelSpiska({
			C: this, Z: Zapros, T: "Dostupi",
			Spisok: {
				S: "Polzovateli/Dostupi/Poluchit", K: "iId", N: "Загрузка доступов пользователя", M: (D) => { return this.state.PolzovateliAktivnii }
			},
			Deistvia: [
				{
					DT: "Dobavit", S: "Polzovateli/Dostupi/Dobavit", N: "Добавление доступа", PO: 1, LIU: 1,
					M: (D => { return { ...D, ...this.state.PolzovateliAktivnii } })
				},
				{
					DT: "Ubrat", S: "Polzovateli/Dostupi/Ubrat", N: "Убирание доступа", PO: 1, LIU: 1,
					M: (D => { return { ...D, ...this.state.PolzovateliAktivnii } })
				}
			]
		});
	}

	componentDidMount() {
		this.PolzovateliSpisok();
		Zapros({S: "Polzovateli/Dostupi/Spisok", M: "Dostup"}).then(O => {
			this.setState({DostupiSpisok: O})
		});
	}

	LoginZadat = (E) => {
		this.setState({ Login: E.target.value });
	}

	LoginNaiti = () => {
		Zapros({ S: "Polzovateli/Naiti", D: {Login: this.state.Login}, M: "Polzovateli", N: "Поиск пользователя" }).then(O => {
			if (O.Text !== undefined) {
				this.setState({ Text: O.Text, Polzovatel: null });
			} else {
				this.setState({ Polzovatel: O, Text: "" });
			}
		});
	}

	LoginDobavit = () => {
		Zapros({ S: "Polzovateli/Dobavit", D: { sLogin: this.state.Polzovatel.sLogin }, M: "Polzovateli", N: "Добавление пользователя" }).then(O => {
			this.setState({ Polzovatel: null, Login: "", PolzovateliAktivnii: O }, () => {
				this.PolzovateliSpisok();
			})
		});
	}

	PolzovateliParser = (Item) => {
		return {
			Id: "KP" + Item.iId,
			Title: Item.ImaK, Desc: Item.sLogin + " - " + Item.Ima,
			...(((this.state.PolzovateliAktivnii || {}).iId === Item.iId)
				? { IconL: "badge", Indicator: true } : { IconL: "assignment_ind", Indicator: false }
			),
			...((Item.A === "1")
				? { IconR: "account_circle", IconRColor: "var(--GM9)" } : { IconR: "no_accounts", IconRColor: "var(--RM3)" }
			)
		}
	}

	DostupiParser = (Item) => {
		return {
			Id: "KPD" + Item.iDostup,
			Title: Item.sNazvanie, Desc: Item.sOpisanie,
			IconL: Item.sZnak,
			...((this.state.Dostupi.filter(E => E.iDostup === Item.iDostup).length > 0)
				? { IconR: "check_box", IconRColor: "var(--GM7)", Click: this.DostupiUbrat }
				: { IconR: "check_box_outline_blank", IconRColor: "var(--RM6)", Click: this.DostupiDobavit }			
			)
		}
	}

	render() {
		return <ContentWide ColAutoWidth ColAutoHeight AutoResize>
			<ContentCol TitleItems={<>
				<ColTitleItemRender P Icon="layers" Desc="Смена режима отображения" onClick={() => { }}
					Title={/*this.state.ModeText[this.state.Mode]*/"Пользователи - " + this.state.Polzovateli.length}
				/>
			</>}>
				<WideList Items={this.state.Polzovateli} ItemClick={this.PolzovateliClick} Parser={this.PolzovateliParser} RandomLColor />
			</ContentCol>

			{this.state.PolzovateliAktivnii == null ? <ContentCol TitleItems={<>
				<ColTitleItemRender Icon="person_add" Desc="Доваить пользователя в приложение" Title={"Добавить"} />
			</>}>
				<WideInput IconLColor="var(--GM1)" Value={this.state.Login} ValueChange={this.LoginZadat} Item={{IconL: "abc", Id: "Login", Type: "text"}} />
				<WideItem IconLColor="var(--GM8)" Item={{IconL: "person_search", Title: "Найти", Desc: "Проверить наличие введённого логина на сервере"}} ItemClick={this.LoginNaiti} />
				{(this.state.Text !== "") && <WideItem Blocked IconLColor="var(--GM4)" Item={{IconL: "warning_amber", Title: "Ошибка", Desc: this.state.Text}} />}
				{(this.state.Polzovatel != null) && <>
					<WideItem Blocked IconLColor={RandomColor()} Item={{IconL: "contact_mail", Title: this.state.Polzovatel.sLogin, Desc: "Логин"}} />
					<WideItem Blocked IconLColor={RandomColor()} Item={{IconL: "badge", Title: this.state.Polzovatel.Ima, Desc: "Имя"}} />
					<WideItem Blocked IconLColor={RandomColor()} Item={{IconL: "perm_contact_calendar", Title: this.state.Polzovatel.ImaK, Desc: "Сокращение"}} />
					<WideItem IconLColor="var(--GM6)" ItemClick={this.LoginDobavit} Item={{IconL: "person_add", Title: "Добавить", Desc: "добавить найденного пользователя"}} />
				</>}
			</ContentCol> : <ContentCol TitleItems={<>
				<ColTitleItemRender Icon="badge" Desc="Выбранный пользователь" Title={"Пользователь №" + this.state.PolzovateliAktivnii.iId} />
			</>}>
					<WideItem Blocked IconLColor={RandomColor()} Item={{IconL: "contact_mail", Title: this.state.PolzovateliAktivnii.sLogin, Desc: "Логин"}} />
					<WideItem Blocked IconLColor={RandomColor()} Item={{IconL: "badge", Title: this.state.PolzovateliAktivnii.Ima, Desc: "Имя"}} />
					<WideItem Blocked IconLColor={RandomColor()} Item={{IconL: "perm_contact_calendar", Title: this.state.PolzovateliAktivnii.ImaK, Desc: "Сокращение"}} />
					<WideItem IconLColor="var(--GM10)" ItemClick={this.PolzovateliUbrat} Item={{IconL: "person_remove", Title: "Убрать", Desc: "Убрать выбранного пользователя"}} />
			</ContentCol>}
		
			<ContentCol TitleItems={<>
				<ColTitleItemRender Icon="help" Desc="Доступы выбранного пользователя" onClick={() => { }} Title={"Доступы"}/>
			</>}>
				{this.state.PolzovateliAktivnii == null ? <WideItem Blocked IconLColor="var(--GM2)" Item={{
					IconL: "view_list", Title: "Пользователь не выбран", Desc: "Выберите пользователя для управления доступами"
				}} /> : <WideList
						Items={this.state.DostupiSpisok} Parser={this.DostupiParser} RandomLColor
				/>}
			</ContentCol>
		</ContentWide>
	}
}

import { Component, createContext, cloneElement } from "react"
import { Link } from 'react-router-dom'
import ScrollArea from "react-scrollbars-custom"

import { KeyGen } from "./Funkcii"
import { ShortItem } from "./Elementi"

import "./Okna.scss"

export const Okna = createContext();
Okna.displayName = "ServerOkon";

const RazmitiePX = 15, MCSSOkno = "Okno";

export class ServerOkon extends Component {
	constructor(props) {
		super(props);

		this.Razmitie = () => {
			let Elements = document.getElementsByClassName(MCSSOkno);
			for (let I = 0; I < Elements.length; ++I) {
				Elements[I].style["filter"] = "blur(" + ((Elements.length - 1 - I) * RazmitiePX) + "px)";
			}
		}

		this.Otkrit = (P) => {
			let { Okna } = this.state;
			Okna.push(P);
			this.setState({ Okna }, this.Razmitie)
		}

		this.Zakrit = (P) => {
			let { Okna } = this.state;
			Okna = Okna.filter(Okno => Okno.UID != P.UID);
			this.setState({ Okna }, this.Razmitie)
		}

		this.Okna = { Otkrit: this.Otkrit, Zakrit: this.Zakrit };

		this.state = {
			Okna: []
		};
	}

	Zapolnatel = (C) => {
		if (C.Okna != this.Okna) {
			C.Okna = this.Okna;
		}
	}

	render() {
		return <Okna.Provider value={this.Zapolnatel}>
			{this.props.children}
			{this.state.Okna.map(Okno => { return Okno.Element })}
		</Okna.Provider>
	}
}

export class OOkno extends Component {
	constructor(props) {
		super(props);
		this.state = {
			UID: KeyGen({ Type: "KeyTag", Tag: "OO"}),
			Otkrito: false
		};
	}

	Zakrit = () => { this.Okna.Zakrit(this.state); this.Izmenit(); }
	
	AktivatorClick = () => {
		if (this.props.Zablokirovano) return;

		if (this.state.Otkrito) 
			this.Zakrit();
		else 
			this.Okna.Otkrit({...this.state, Element: cloneElement(this.props.children, {Zakrit: this.Zakrit})});

		this.Izmenit();
	}

	Izmenit = () => { this.setState({ Otkrito: !this.state.Otkrito }); }


	render() {

		return <>
			<Okna.Consumer>{Zapolnatel => Zapolnatel(this)}</Okna.Consumer>
			{cloneElement(this.props.Aktivator, { onClick: this.AktivatorClick, ItemClick: this.AktivatorClick })}
		</>
	}
}

export class Okno extends Component {
	state = {
		Mashtab: "100%",
		MarginLeft: "100%",
		MenuSkrito: false
	}

	componentDidMount() {
		//Change screen DPR
		this.setState({
			Mashtab: (100 + Math.round(((window.devicePixelRatio < 1 ? 1 : window.devicePixelRatio) - 1) * 0.2 * 100)) + "%"
		});
		this.setState({ MarginLeft: "0" }, () => {
			//setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 100);
		});

		/*if (this.ApiCheck())
			this.setState({
				MenuHided: ((UIApi.Storage.Get({ Name: this.props.Tag + "_MenuHided" }) != null)
					? UIApi.Storage.Get({ Name: this.props.Tag + "_MenuHided" })
					: false)
			});*/
	}

	/*ApiCheck = () => {
		if (window.UIApi) if (UIApi.Storage) return true;
		return false;
	}*/

	MenuSkritoIzmenit = () => {
		this.setState({ MenuSkrito: !this.state.MenuSkrito }, () => { setTimeout(() => { window.dispatchEvent(new Event('resize')); }, 400); });
		//if (this.ApiCheck())
		//	UIApi.Storage.Set({ Name: this.props.Tag + "_MenuHided", Value: !this.state.MenuHided });
	}

	Zakrit = () => {
		this.setState({ MarginLeft: "100%" }, () => {
			setTimeout(() => { this.props.Zakrit(); }, 333);
		});
	}

	/*style={{
			position: "absolute",
			width: this.state.Mashtab,
			height: this.state.Mashtab
		}} */
	//noDefaultStyles
	render() {
		if (this.props.OknoNazvanie)
			document.title = this.props.OknoNazvanie;

		return <div class={MCSSOkno + (this.state.MenuSkrito ? " MHide" : "")} style={{ marginLeft: this.state.MarginLeft }}>
			<nav class="MenuCol R_NoSel" >
				<table class="Body"><tbody><tr><td>
					{this.props.TitleElement || <Link to="/" className="Row" style={{ background: "var(--GM1)", color: "var(--GM1T)", boxShadow: "0 0 3px 2px var(--GM2)" }}>
						<div class={"Icon R_CC"} title={this.props.TitleText}>
							<i class="R_MI R_FS30">{this.props.TitleIcon}</i>
						</div>
						<div class="Title R_CL">{this.props.TitleText}</div>
					</Link>
					}

					{(this.props.Zakrivaemoe)
						? <OknoMenuStroka Data={{ Icon: "cancel_presentation", Title: "Закрыть" }} ItemClick={this.Zakrit} />
						: null
					}

				</td></tr><tr><td style={{ height: "100%" }} class="MB0">
					<ScrollArea noDefaultStyles>
						{(this.props.Buttons != undefined) && <div class="Buttons">
							{(this.props.Skrivaemoe == true) && <div onClick={this.MenuSkritoIzmenit} class={"ShortItem  P"} title="Свернуть">
								<div class="Icon R_NoSel R_CC R_MI R_FS40" style={{
									color: "var(--RM5)", transform: "rotate(" + (this.state.MenuSkrito ? "180" : "0") + "deg)"
								}}>switch_left</div><div class="Title">Свернуть</div>
							</div>}
							{this.props.Buttons}
						</div>}
						{this.props.Rows}
					</ScrollArea>
				</td></tr><tr>
						{(this.props.Buttons == undefined) && <td>
							{this.props.RowsD || null}
							{(this.props.Skrivaemoe)
								? <div class="Row A" onClick={this.MenuSkritoIzmenit}>
									<div class={"Icon R_CC R0" + (this.state.MenuSkrito ? " R180" : "")} style={{ color: "var(--RM5)" }} title="Свернуть">
										<i className="R_MI R_FS30">switch_left</i>
									</div>
									{/*<div class="Title R_CL">Свернуть</div>*/}
								</div>
								: null
							}
							{/*(this.props.Menuable)
						? <WindowChildren TriggerItem={<div class="Row A">
							<div class={"Icon T_CC"} title="Меню"><i class="MI_SH S30">apps</i></div>
							<div class="Title T_CC">Меню</div>
						</div>}>
							<WWMainMenu params={this.props.MenuParams || { menuType: "home" }} />
						</WindowChildren>
						: null
					*/}
						</td>}
					</tr></tbody></table>
			</nav>
			<main class={"ContentBlock " + (this.props.ContentClass ? this.props.ContentClass : "")}

			>
				{this.props.children}
			</main>
		</div>
	}
}
//
/**

<OknoMenuStroka Data={{Icon: "menu", Title: "Меню"}} ItemClick={()=>{}}/>
*/
export class OknoMenuStroka extends Component {
	ItemClick = () => {
		if (this.props.ItemClick)
			this.props.ItemClick({ Data: this.props.Data });
	}
	render() {
		return <div class={"Row" + (this.props.ItemClick ? " A" : "")} onClick={this.ItemClick}>
			{(this.props.Data.Icon) ? <div class="Icon R_CC" title={this.props.Data.Title}>
				<i class="R_MI R_FS30">{this.props.Data.Icon}</i>
			</div> : null}
			<div class="Title R_CL">{this.props.Data.Title}</div>
		</div>
	}
}







export class Zaprosi extends Component {
	state = {
		Zaprosi: []
	}
	componentDidMount() {
		console.log("Nabludatel");
		window.addEventListener("storage", this.Nabludatel);
	}
	componentWillUnmount() {
		window.removeEventListener("storage", this.Nabludatel);
	}


	Nabludatel = (SE) => {
		console.log("Nabludatel+");
		console.log(SE);
		console.log(window.localStorage.getItem("Zaprosi"));
	}

	render() { return null }
}

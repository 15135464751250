//import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

import { ServerOkon/*, Zaprosi*/ } from "./Moduli/Okna"
import { ServerVhoda } from "./Moduli/Vhod"
//import App from './App';
import Karta from "./Prilojenia/Karta"
import Redaktor from "./Prilojenia/Redaktor"

import "./Root.css";

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(<BrowserRouter basename={baseUrl}>
	<div id="Zaprosi" class="R_NoSel"/>
	<ServerOkon>
		<Routes>
			<Route index={true} element={
				<ServerVhoda key="index" ><Karta /></ServerVhoda>
			} />
			<Route path="redaktor/*" element={
				<ServerVhoda key="redaktor" Zakrito><Redaktor /></ServerVhoda>
			} />
		</Routes>
	</ServerOkon>
</BrowserRouter>);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

const API = "API/"; const headers = { "Content-Type": "application/json;charset=utf-8" };

Date.prototype.getWeekNumber = function () {
	var d = new Date(Date.UTC(this.getFullYear(), this.getMonth(), this.getDate()));
	var dn = d.getUTCDay() || 7;
	d.setUTCDate(d.getUTCDate() + 4 - dn);
	var ys = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
	return Math.ceil((((d - ys) / 86400000) + 1) / 7)
}

Date.prototype.getYearDay = function () {
	var start = new Date(this.getFullYear(), 0, 0);
	var offset = (this - start)/* + ((s.getTimezoneOffset() - this.getTimezoneOffset()) * 60 * 1000)*/;
	var offsetDay = 1000 * 60 * 60 * 24;
	return Math.floor(offset / offsetDay);
}

function AsHex(N) { return Number(N).toString(16) }

/**
*SOZDANIE LOKALNOI DATI
*@param {Object} Params				Params object	> {Value: 1619067572810}
*@param {Number} P.Value			Ticks			> 1619067572810 or 637546823728100000
*@param {Boolean} P.IsShortTicks	unix or Net		> true(unix) or false(Net)
*@param {Boolean} P.Full			full or compact	> true(DMY HM) or false(HM / W HM / DM / DMY)
*@param {String} P.weekday			weekday format	> "short" or other JS values
*@param {String} P.month			month format	> "short" or other JS values
*@example <span>{DTFormatter({Value: Element.Ticks})}</span>
*@example <span>{DTFormatter({Value: Element.Ticks, IsShortTicks: false, Full: true})}</span>
*/
export function DTFormatter({ Value, IsShortTicks = true,
	Full = false, Time = false, Seconds = false, weekday = "short", month = "short"
}) {
	var NetOffset = 621356148 * 1e9; //621356148000000000 - RAZNICA MEJDU .Net I unix;
	var WeekOffset = 5184 * 1e5; //518400000 - 6 TIKI ZA 6 DNEI;
	var Ticks = (IsShortTicks ? Value : ((Value - NetOffset) / 1e4));
	var DTOptions = {};
	var Start = new Date, DayStart = Start.setHours(0, 0, 0, 0), YearStart = Start.setMonth(0, 1);
	if (Full)
		DTOptions = { day: "numeric", month: "long", year: "numeric", hour: "2-digit", minute: "2-digit" };
	else {
		if (Seconds) { DTOptions["second"] = "2-digit"; }
		if (Time || ((DayStart - WeekOffset) - Ticks < 0)) { DTOptions["hour"] = "2-digit"; DTOptions["minute"] = "2-digit"; }
		if ((DayStart - Ticks >= 0) && ((DayStart - WeekOffset) - Ticks < 0)) { DTOptions["weekday"] = weekday; }
		if (Time || ((DayStart - WeekOffset) - Ticks >= 0)) { DTOptions["day"] = "numeric"; DTOptions["month"] = month; }
		if ((YearStart - Ticks >= 0)) { DTOptions["year"] = "numeric"; }
	}
	return new Date(Ticks).toLocaleString([], DTOptions);
}

/**
*GENERACIA TEKSTOVIH UNIKALNIH ID
*@param {Object} Params		Params object	> {}
*@param {String} P.Type		Key type		> "Key"
*@param {String} P.Tag		Key tag			> "H"
*@example Elem.Key = KeyGen({});
*@example Elem.Key = KeyGen({Type: "KeyTag", Tag: "H"});
*/
export function KeyGen({ Type = "Key", Tag = "" }) {
	var Key = "";
	if (Type == "Key") {
		Key = `${(~~(Math.random() * 1e9)).toString(16)}-${(+new Date).toString(16)}`;
	} else if (Type == "KeyTag") {
		Key = `${Tag}_R${(~~(Math.random() * 1e8)).toString(8)}_T${(+new Date).toString(8)}`;
	} else if (Type == "KeyDate") {
		var Now = new Date;
		//Key = AsHex(Now.getFullYear()) + "-" + Now.getWeekNumber() + DaysText[DC.getDay()] + "-" +
		//	AsHex((DC.getHours() * 60 + DC.getMinutes()) * 60 + DC.getSeconds());
		Key = `${AsHex(Now.getFullYear())}-${AsHex(Now.getYearDay())}-${AsHex((Now.getHours() * 60 + Now.getMinutes()) * 60 + Now.getSeconds())}`;
	}
	Key = Key.toUpperCase();
	return Key;
}

/**
*PROSTO RANDOMNII CVET
*/
export function RandomColor() {
	return `hsl(${~~(Math.random() * 1e3)}, 50%, 50%)`;
}

/**
 * Запрос к серверу
 * @param {Object} P		Параметры		> {}
 * @param {String} P.T		Тип метода		> "POST"
 * @param {String} P.S		Ссылка			> "Polzovateli/Vhod"
 * @param {Object} P.D		Данные			> "{Id: 0}"
 * @param {String} P.M		Метка			> "Vhod"
 * @param {String} P.N		Название		> "Синхронизация данных"
 * @example ???
*/
export async function Zapros({ T = "POST", S, D = {}, M = "Zapros", N = "Синхронизация данных" }) {
	let Id = KeyGen({ Type: "KeyTag", Tag: M });

	//let Zaprosi = JSON.parse(window.localStorage.getItem("Zaprosi") || "[]");
	//Zaprosi.push({ Id, M, N });
	//window.localStorage.setItem("Zaprosi", JSON.stringify(Zaprosi));
	//console.log(window.localStorage.getItem("Zaprosi"));
	let ZaprosE = document.createElement("div");
	ZaprosE.id = Id;
	ZaprosE.classList.add("Zapros");
	ZaprosE.innerText = N + "...";
	let ZaprosiE = document.getElementById("Zaprosi");
	ZaprosiE.appendChild(ZaprosE);


	let Otvet = await fetch(API + S, {
		method: T, headers, body: JSON.stringify(D)
		//mode: 'cors', // no-cors, *cors, same-origin
		//cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
		//credentials: 'same-origin', // include, *same-origin, omit
		//redirect: 'follow', // manual, *follow, error
		//referrerPolicy: 'no-referrer', // no-referrer, *client
	});

	if (!Otvet.ok) {

		ZaprosE.remove();
		console.log(Otvet);
		throw new Error("!ok");
	}

	let Dannie = await Otvet.json();

	//Zaprosi = JSON.parse(window.localStorage.getItem("Zaprosi") || "[]");
	//Zaprosi = Zaprosi.filter(E => E.Id != Id);
	//window.localStorage.setItem("Zaprosi", JSON.stringify(Zaprosi));
	ZaprosE.remove();

	return Dannie;
}

export function NabiratelSpiska({ C, Z, T, Spisok = null, Dobavit = null, Deistvia = [], Click = null }) {
	//console.log("NabiratelSpiska: " + T + "{" + (Get ? " Get," : "") + (Add ? " Add," : "") + (Click ? " Click," : "")
	//	+ (Editors.length > 0 ? " Editors:[" + Editors.map(E=>{return E.ST}) + "]," : "") + "}");

	if (Spisok) C[T  + "Spisok"] = (Params) => {
		//console.log("ListInitializer: " + T + "Spisok");

		let D = ZapolnitDannieZaprosa(Spisok, Params, C, T);
		Z({
			S: Spisok.S || T + "/Spisok", ...Spisok, ...(Params || {}), D
		}).then(O => {
			if (Spisok.MO) O = Spisok.MO(O);
			let CS = {}; CS[T] = O; CS[T + "Aktivnii"] = O.filter(OE => OE[Spisok.K] == (C.state[T + "Aktivnii"] || {})[Spisok.K])[0] || null;
			C.setState(CS, () => { if (Spisok.P) Spisok.P() })
		});
	}

	if (Click) C[T + "Click"] = (Params) => {
		if ((Params.Item != null) && ((C.state[T + "Aktivnii"] || {})[Click.K] != Params.Item[Click.K])) {
			//console.log("ListInitializer: " + T + "Click");
			let S = {}; S[T + "Aktivnii"] = Params.Item;
			C.setState(S, () => { if (Click.PU) Click.PU() })
		} else {
			//console.log("ListInitializer: " + T + "UnClick");
			let S = {}; S[T + "Aktivnii"] = null;
			C.setState(S, () => { if (Click.PS) Click.PS() })
		}
	}

	if (Dobavit) C[T + "Dobavit"] = (Params) => {
		if (Dobavit.U) if (!Dobavit.U()) return;
		//console.log("ListInitializer: " + T + "Add");
		let D = ZapolnitDannieZaprosa(Dobavit, Params, C, T);
		Z({ S: Dobavit.S || T + "/Dobavit", ...Dobavit, ...(Params || {}), D }).then(O => {
			if (Dobavit.PV) {
				if (Spisok.MO) O = Spisok.MO([O])[0];
				let CS = {}; CS[T + "Aktivnii"] = O;
				C.setState(CS, () => {
					if (Dobavit.PO) C[T + "Spisok"]();
					if (Click.PU) Click.PU();
					//if (Click.PS) Click.PS();
				});
			} else {
				if (Dobavit.PO) C[T + "Spisok"]();
				//if (Click.PU) Click.PU();
				//if (Click.PS) Click.PS();
			}
		});
	}

	Deistvia.map(Deistvie => {
		C[T + Deistvie.DT] = (Params) => {
			if (Deistvie.U) if (!Deistvie.U()) return;
			//console.log("ListInitializer: " + T + Editor.ST);
			let D = ZapolnitDannieZaprosa(Deistvie, Params, C, T);
			Z({ S: Deistvie.S || T + "/" + Deistvie.DT, ...Deistvie, D }).then(O => {
				if (Deistvie.PO) C[T + "Spisok"]();
				if (Deistvie.P) Deistvie.P()
			});
		}
	});
}

function ZapolnitDannieZaprosa(Handler, Params, This, Tag) {
	let D = {};
	if (Handler.D) D = Handler.D;
	else if (Handler.LI || Handler.LIU) D = { Item: Params.Item };
	else if (Handler.CS || Handler.CSU) D = { Item: This.state[Tag + "Aktivnii"] };
	else if (Handler.FP || Handler.FPU) D = { Item: Params };

	if (Handler.LIU || Handler.CSU || Handler.FPU) D = { ...D.Item };

	D = { ...D, ...(Handler.V || {})};

	if (Handler.M) D = Handler.M(D);

	return D;
}

const FIzmenatel = "Izmenatel", FOchishatel = "Ochishatel", FPoluchatel = "Poluchatel", FPerekluchatel = "Perekluchatel", FUstanovshik = "Ustanovshik";
export function NabiratelFunkcii({ C, T, Izmenatel = {}, Poluchateli = [], Perekluchateli = [], Ustanovshiki = [] }) {

	C[T + FIzmenatel] = (P) => {
		if (C.state[T] == null) return;
		let Izmenenie = {};
		if ((Object.keys(P).length == 1) && (P.Izmenenie)) 
			Izmenenie = { ...P.Izmenenie };
		else if ((Object.keys(P).length == 2) && (P.Item))
			Izmenenie = { ...P.Item };
		else if (Object.keys(P).length > 0)
			Izmenenie = { ...P };

		let CS = {}; CS[T] = { ...(C.state[T] || {}), ...Izmenenie };
		C.setState(CS);
	}

	C[T + FOchishatel] = () => {
		let CS = {}; CS[T] = null;
		C.setState(CS);
	}

	Poluchateli.forEach(PO => {
		C[T + FPoluchatel + (PO.NF || PO.K)] = (P) => {
			if (C.state[T] == null) return;
			let Izmenenie = {};
			Izmenenie[PO.K] = P;
			if (PO.A) {
				PO.A.forEach((A, I) => {
					if (PO.M) { if (PO.M[I]) Izmenenie[PO.K] = PO.M[I](Izmenenie[PO.K][A]); }
					else Izmenenie[PO.K] = Izmenenie[PO.K][A];
				});
			} 
			C[T + FIzmenatel](Izmenenie);
		}
	});

	Perekluchateli.forEach(PO => {
		C[T + FPerekluchatel + (PO.NF || PO.K)] = () => {
			if (C.state[T] == null) return;
			let Izmenenie = {};
			Izmenenie[PO.K] = C.state[T][PO.K];
			let I = PO.Z.indexOf(Izmenenie[PO.K]) + 1;
			if (I == PO.Z.length) I = 0;
			Izmenenie[PO.K] = PO.Z[I];
			C[T + FIzmenatel](Izmenenie);
		}
	});

	Ustanovshiki.forEach(UO => {
		C[T + FUstanovshik + (UO.NF || UO.T)] = () => {
			if (C.state[T] == null) return;
			let Izmenenie = {};
			UO.K.forEach((K, I) => { Izmenenie[K] = UO.Z[I] });
			C[T + FIzmenatel](Izmenenie);
		}
	});
}
import { Component } from 'react';

import {
	altShiftKeysOnly,
	platformModifierKeyOnly,
	shiftKeyOnly,
	//	altKeyOnly,
	//	never,
	doubleClick,
} from "ol/events/condition";
import { fromLonLat, toLonLat } from "ol/proj";
import { /*Geometry,*/ Point, Polygon, LineString/*, GeometryCollection*/ } from "ol/geom";
import { Feature } from "ol";

import { RLayerVector, RFeature, RInteraction, ROverlay, RPopup } from "rlayers";
import { RStyle, /*RIcon,*/ RFill, RStroke } from "rlayers/style";

import {
	WideItem, WideItemE, WideItemO
} from "../Moduli/Elementi"

export class Tochka extends Component {
	constructor(props) {
		super(props);
	}

	Vibrat = () => { this.props.Vibrat({ Item: this.props.I }); }
	Izmenit = (Tochka) => { this.props.Izmenit({ Tochka, I: this.props.I }) }
	IzmenitX = (Params) => { this.Izmenit([Number(Params.Tekst), this.props.Tochka[1]]) }
	IzmenitY = (Params) => { this.Izmenit([this.props.Tochka[0], Number(Params.Tekst)]) }
	IzmenitN = (Params) => { this.Izmenit(fromLonLat([this.TochkaLL[0], Number(Params.Tekst)])) }
	IzmenitE = (Params) => { this.Izmenit(fromLonLat([Number(Params.Tekst), this.TochkaLL[1]])) }
	IzmenitDP = () => { this.props.Izmenit({ I: this.props.I, Deistvie: "P" }); }
	IzmenitDU = () => { this.props.Izmenit({ I: this.props.I, Deistvie: "U" }); }

	render() {
		this.TochkaLL = toLonLat(this.props.Tochka);
		return <>
			<WideItem ItemClick={this.Vibrat}
				ItemData={this.props.I} Item={{
					Title: "X: " + this.props.Tochka[0] + "; Y: " + this.props.Tochka[1] + ";",
					Desc: "СШ(N): " + this.TochkaLL[1] + "; ВД(E): " + this.TochkaLL[0] + ";",
					IconR: "edit_location", IconRColor: "var(--GM2)",
					IconLT: "TI", IconL: this.props.I.toString(), IconLText: "var(--GM3)",
					IconLColor: (this.props.Aktivnii == this.props.I) ? "var(--GM5)" : ((this.props.I == 0) ? "var(--GM7)" : "var(--GM8)"),
				}}
			/>
			{(this.props.Aktivnii == this.props.I) && <>
				<WideItemE Poluchatel={this.IzmenitX} Item={{ Title: this.props.Tochka[0], Desc: "X", IconLT: "TI", IconL: "X" }} />
				<WideItemE Poluchatel={this.IzmenitY} Item={{ Title: this.props.Tochka[1], Desc: "Y", IconLT: "TI", IconL: "Y" }} />
				<WideItemE Poluchatel={this.IzmenitN} Item={{ Title: this.TochkaLL[1], Desc: "СШ(N)", IconLT: "TI", IconL: "N" }} />
				<WideItemE Poluchatel={this.IzmenitE} Item={{ Title: this.TochkaLL[0], Desc: "ВД(E)", IconLT: "TI", IconL: "E" }} />
				<WideItem ItemClick={this.IzmenitDP} ItemRClick={this.IzmenitDU} Item={{
					Title: "Записать положение устройства", Desc: "Или удалить точку",
					IconL: "share_location", IconLColor: "var(--GM9)",
					IconR: "delete_outline", IconRColor: "var(--RM3)"
				}} />
			</>}
		</>;
	}
}



const MOA = "Aktivnii", MOAP = "AktivniiPoluchatel", MOAI = "AktivniiIzmenatel";
export const RedaktorObekta = ({ C, O }) => {

	return <>
		{(O == "Organizacii") && <>
			<WideItem Item={{ Title: "Организация", Desc: "Выбранная организация", IconL: "edit_location_alt", IconLColor: "var(--GM1)", IconR: "cancel_presentation", IconRColor: "var(--RM5)" }}
				ItemClick={() => { C.setState({ OrganizaciiAktivnii: null }) }}
			/>
		</>}

		{(O == "Zdanii") && <>
			<WideItem Item={{ Title: "Здание", Desc: "Выбранное здание", IconL: "edit_location_alt", IconLColor: "var(--GM1)", IconR: "cancel_presentation", IconRColor: "var(--RM5)" }}
				ItemClick={() => { C.setState({ ZdaniiAktivnii: null }) }}
			/>

			<WideItemO Item={{
				Title: C.state[O + MOA].sNazvanieK, Desc: C.state[O + MOA].sOpisanieK,
				IconLT: "TI", IconL: (C.state[O + MOA].sTextK || " ")[0], IconLColor: C.state[O + MOA].sCvetK, IconLText: C.state[O + MOA].sCvetTekstK,
				IconR: "view_list", IconRColor: "var(--GM8)"
			}} Items={C.state.KompleksiTipi} ItemParser={C.KompleksiParser} ItemClick={C[O + MOAI]} />
			<WideItemO Item={{
				Title: C.state[O + MOA].sNazvanieOT, Desc: C.state[O + MOA].sOpisanieOT,
				IconLT: "TI", IconL: C.state[O + MOA].sTextOT, IconLColor: "var(--RM6)", IconLText: "var(--GM3)",
				IconR: "view_list", IconRColor: "var(--GM8)"
			}} Items={C.state.ObektiTipi} ItemParser={C.ObektiTipiParser} ItemClick={C[O + MOAI]} />
			<WideItemO Item={{
				Title: C.state[O + MOA].sNazvanieOF, Desc: C.state[O + MOA].sOpisanieOF,
				IconLT: "TI", IconL: C.state[O + MOA].sTextOF, IconLColor: "var(--RM6)", IconLText: "var(--GM3)",
				IconR: "view_list", IconRColor: "var(--GM8)"
			}} Items={C.state.ObektiFormati} ItemParser={C.ObektiFormatiParser} ItemClick={C[O + MOAI]} />
		</>}
		{(O == "Sektori") && <>
			<WideItem Item={{ Title: "Сектор", Desc: "Выбранный сектор", IconL: "edit_location_alt", IconLColor: "var(--GM1)", IconR: "cancel_presentation", IconRColor: "var(--RM5)" }}
				ItemClick={() => { C.setState({ SektoriAktivnii: null }) }}
			/>

			<WideItemO Item={{
				Title: C.state[O + MOA].sNazvanieS, Desc: "Тип",
				IconLT: "TI", IconL: "", IconLColor: C.state[O + MOA].sCvet,
				IconR: "view_list", IconRColor: "var(--GM8)"
			}} Items={C.state.SektoriTipi} ItemParser={C.SektoriTipiParser} ItemClick={C[O + MOAI]} />
		</>}
		{(O == "Ploshadki") && <>
			<WideItem Item={{ Title: "Площадка", Desc: "Выбранная площадка", IconL: "edit_location_alt", IconLColor: "var(--GM1)", IconR: "cancel_presentation", IconRColor: "var(--RM5)" }}
				ItemClick={() => { C.setState({ PloshadkiAktivnii: null }) }}
			/>

			<WideItemO Item={{
				Title: C.state[O + MOA].sNazvanieP, Desc: "Тип",
				IconLT: "TI", IconL: "", IconLColor: C.state[O + MOA].sCvet,
				IconR: "view_list", IconRColor: "var(--GM8)"
			}} Items={C.state.PloshadkiTipi} ItemParser={C.PloshadkiTipiParser} ItemClick={C[O + MOAI]} />
		</>}

		<WideItemO Item={{
			Title: C.state[O + MOA].sNazvanieD, Desc: "Доступ",
			IconL: C.state[O + MOA].sZnakD, IconLColor: "var(--RM6)",
			IconR: "view_list", IconRColor: "var(--GM8)"
		}} Items={C.state.DostupiSpisok} ItemParser={C.PloshadkiDostupiParser} ItemClick={C[O + MOAI]} />

		{(O == "Ploshadki") && <WideItemE Poluchatel={C[O + MOAP + "cBukva"]} Proveratel={C.PloshadkiAktivniiProveratel} Item={{ Title: C.state[O + MOA].cBukva, Desc: "Буква", IconLT: "TI", IconL: C.state[O + MOA].cBukva }} />}
		<WideItemE Poluchatel={C[O + MOAP + "iNomer"]} Proveratel={C.PloshadkiAktivniiProveratel} Item={{ Title: C.state[O + MOA].iNomer, Desc: "Номер", IconLT: "TI", IconL: C.state[O + MOA].iNomer }} />
		<WideItemE Poluchatel={C[O + MOAP + "sNazvanie"]} Proveratel={C.PloshadkiAktivniiProveratel} Item={{ Title: C.state[O + MOA].sNazvanie, Desc: "Название", IconL: "abc" }} />
		<WideItemE Poluchatel={C[O + MOAP + "sOpisanie"]} Proveratel={C.PloshadkiAktivniiProveratel} Item={{ Title: C.state[O + MOA].sOpisanie, Desc: "Описание", IconL: "subtitles" }} />
		<WideItem ItemClick={C.ZapisatDannie} ItemRClick={C.OchistitDannie} Item={{ Title: "Обновить фигуру", Desc: "Обновить данные точек фигуры на карте", IconL: "format_shapes", IconLColor: "var(--GM8)", IconR: "backspace", IconRColor: "var(--RM2)" }} />
		<WideItem ItemClick={C[O + "AktivniiPerekluchatelbKarta"]} Item={{
			Desc: "Состояние отображения", IconL: "map", IconLColor: "var(--GM8)",
			...(C.state[O + MOA].bKarta == 1
				? { Title: "Отображается на карте", IconR: "visibility", IconRColor: "var(--GM9)" }
				: { Title: "Не отображается на карте", IconR: "visibility_off", IconRColor: "var(--GM4)" })
		}} />
		<WideItem ItemClick={C[O + "AktivniiPerekluchatelbUdalen"]} Item={{
			Desc: "Состояние удаления", IconL: "delete_outline", IconLColor: "var(--GM8)",
			...(C.state[O + MOA].bUdalen == 1
				? { Title: "Удалено", IconR: "check_box", IconRColor: "var(--GM4)" }
				: { Title: "Не удалено", IconR: "check_box_outline_blank", IconRColor: "var(--GM9)" })
		}} />
		{(O == "Organizacii") && <WideItemE Poluchatel={C.ObrezatDannie} Item={{ Value: "", Title: "", Desc: "Введите номер здания для удаления", IconL: "bookmark_remove" }} />}
		<WideItem ItemClick={C[O + "Izmenit"]} Item={{ Title: "Сохранить", Desc: "Сохранить изменения в записи", IconL: "save", IconLColor: "var(--GM6)" }} />
	</>
}

export const OtobrajatelGruppi = ({ C, O, MFOT, MFDO = 30, MMOT, MMDO, Cvet = "sCvet" }) => {
	if (!C.state[O + "Izmenenie"]) {
		if ((C.state[O + MOA] != null) && (C.state[O + MOA].jDannie.Tochki !== undefined) && (C.state.Centr.zoom >= MFOT))
			return <RFeature geometry={new Polygon([C.state[O + MOA].jDannie.Tochki])}>
				<RStyle><RStroke color={C.state[O + MOA][Cvet]} width={1} /><RFill color="transparent" /></RStyle>
			</RFeature>
		else return null;
	} else return <>
		{C.state[O].map(P => {
			if (!((P.bKarta == 1) && (P.jDannie.Tochki !== undefined))) return null;
			return <>
				{((C.state.Centr.zoom >= MFOT) && (C.state.Centr.zoom <= MFDO)) && <RFeature geometry={new Polygon([P.jDannie.Tochki])}>
					<RStyle><RStroke color={P[Cvet]} width={3} /><RFill color="transparent" /></RStyle>
					{false && (C.state[O + MOA] == null) && <RPopup trigger="hover" className="example-overlay">
						<p><em>{P.sOpisanie}</em></p>
					</RPopup>}
				</RFeature>}
				{((C.state.Centr.zoom >= MMOT) && (C.state.Centr.zoom <= MMDO)) && <RFeature geometry={new Point([(P.jDannie.Granici.MinX + P.jDannie.Granici.MaxX) / 2, (P.jDannie.Granici.MinY + P.jDannie.Granici.MaxY) / 2])}>
					<ROverlay className="no-interaction P11PX">
						{(O != "Zdanii") && <div class="P11PX Icon R_NoSel R_CC R_TI"
							style={{ background: `radial-gradient(circle, ${P[Cvet]}, ${P[Cvet]} 52%, transparent 55%)`, color: P[Cvet + "Tekst"] }}
						>
							{P.sMetka}
						</div>}
						{(O == "Zdanii") && <div class="R_NoSel K_ZM" >{P.sMetka}</div>}
					</ROverlay>
				</RFeature>
				}}
			</>
		})}
	</>
}
export const OtobrajatelFiguri = ({ C, O, Cvet = "sCvet" }) => {
	if ((!C.state[O + "Izmenenie"]) || (C.state[O + MOA] == null)) return;
	return <>
		<RLayerVector onChange={C.PoluchatelDannie}>
			{(C.state[O + MOA].jDannie.Tochki !== undefined) && <RFeature geometry={new Polygon([C.state[O + MOA].jDannie.Tochki])}>
				<RStyle><RStroke color={C.state[O + MOA][Cvet]} width={1} /><RFill color="rgba(0, 0, 0, 0.2)" /></RStyle>
			</RFeature>}
			<RStyle><RStroke color={C.state[O + MOA][Cvet]} width={1} /><RFill color="rgba(0, 0, 0, 0.2)" /></RStyle>

			{(C.state[O + MOA].jDannie.Tochki === undefined) && <RInteraction.RDraw type={"Polygon"} condition={shiftKeyOnly} freehandCondition={altShiftKeysOnly} />}
			{(C.state[O + MOA].jDannie.Tochki !== undefined) && <RInteraction.RModify condition={platformModifierKeyOnly} deleteCondition={(e) => platformModifierKeyOnly(e) && doubleClick(e)} />}
		</RLayerVector>
		<RLayerVector>
			{(C.state[O + MOA].jDannie.Tochki !== undefined) &&
				C.state[O + MOA].jDannie.Tochki.map(T => <RFeature geometry={new Point(T)}>
					<ROverlay className="no-interaction P11PX"  >
						<div class="P11PX Icon R_NoSel R_CC R_MI" style={{ color: C.state[O + MOA][Cvet] }}>gps_fixed</div>
					</ROverlay>
				</RFeature>)
			}
		</RLayerVector>
	</>
}

export class TochkaD extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Metka: new Feature({
				geometry: new Point([6565881.670465297, 7374121.623138507]),
				iId: 0
			})
		};
	}
	componentDidMount() {
		this.Postavit();
	}
	componentDidUpdate(PProps) {
		if (
			(Number(this.props.Tochka.X.replace(',', '.')) != this.state.Metka.getGeometry().getCoordinates()[0])
			|| (Number(this.props.Tochka.Y.replace(',', '.')) != this.state.Metka.getGeometry().getCoordinates()[1])
		) {
			this.Postavit();
		}
	}

	Postavit = () => {
		this.setState({
			Metka: new Feature({
				geometry: new Point([Number(this.props.Tochka.X.replace(',', '.')), Number(this.props.Tochka.Y.replace(',', '.'))]),
				iId: this.props.Tochka.iId
			})
		});
	}

	Vibor = () => {
		if (this.props.C.state.TochkaNovaa.Vibor == true) return;
		if (this.props.C.state.PutiAktivnii != null) return;
		setTimeout(() => { this.props.C.PutiClick({ Item: null }); }, 100);
		if (this.props.C.state.ViborVse || this.props.C.state.ViborIshodashie || this.props.C.state.ViborVhodashie) {
			//console.log("Puti - Tochka");
			this.props.C.TochkiSoedinit({ Item: this.props.Tochka });
		} else
			this.props.C.TochkiClick({ Item: this.props.Tochka });
	}

	render() {
		this.Cvet = "var(--RM4)";
		if (this.props.Tochka.bKarta == 1) this.Cvet = "var(--RM5)";
		if (this.props.Tochka.bZakrit == 1) this.Cvet = "var(--GM4)";
		if ((this.props.C.state.TochkiAktivnii || {}).iId == this.props.Tochka.iId) this.Cvet = "var(--GM1)";
		if (this.props.C.state.TochkiPokazat.includes(this.props.Tochka.iId)) this.Cvet = "var(--GM5)";

		return <RFeature key={this.props.Tochka.iId} feature={this.state.Metka} onClick={this.Vibor}  >
			<ROverlay className="P11PX">
				<div style={{ color: this.Cvet }}
					class={"P11PX Icon R_NoSel R_CC R_MI" + (this.props.C.state.TochkaNovaa.Vibor ? "" : " KR_C KR_CP")}
				>
					circle
				</div>
				{this.props.C.state.PokazivatNomera && <span class="P11PX Icon R_NoSel R_CC KR_MTN"
					style={{ color: (this.props.C.state.TochkiAktivnii || {}).iId == this.props.Tochka.iId ? "var(--GM1)" : "var(--GM10)" }}
				>
					№ Т{this.props.Tochka.iId}
				</span>}
			</ROverlay>
		</RFeature>
	}
}

export class PutD extends Component {
	constructor(props) {
		super(props);
		this.state = {
			Metka: new Feature({
				geometry: new LineString([
					[6565881.670465297, 7374121.623138507],
					[6565881.670465297, 7374121.623138507]
				]),
				iId: 0
			})
		};
	}
	componentDidMount() {
		this.Postavit();
	}
	componentDidUpdate(PProps) {
		if (
			(Number(this.props.Put.NachaloX.replace(',', '.')) != this.state.Metka.getGeometry().getCoordinates()[0][0])
			|| (Number(this.props.Put.NachaloY.replace(',', '.')) != this.state.Metka.getGeometry().getCoordinates()[0][1])
			|| (Number(this.props.Put.KonecX.replace(',', '.')) != this.state.Metka.getGeometry().getCoordinates()[1][0])
			|| (Number(this.props.Put.KonecY.replace(',', '.')) != this.state.Metka.getGeometry().getCoordinates()[1][1])
		) {
			this.Postavit();
		}
	}

	Postavit = () => {
		this.setState({
			Metka: new Feature({
				geometry: new LineString([
					[Number(this.props.Put.NachaloX.replace(',', '.')), Number(this.props.Put.NachaloY.replace(',', '.'))],
					[Number(this.props.Put.KonecX.replace(',', '.')), Number(this.props.Put.KonecY.replace(',', '.'))]
				]),
				iId: this.props.Put.iId
			})
		});
	}

	Vibor = () => {
		//console.log(this.props.Put);
		if (this.props.C.state.TochkiAktivnii != null) return;
		this.props.C.PutiClick({ Item: this.props.Put });
		//if (this.props.C.state.TochkaNovaa.Vibor == true) return;
		//if (this.props.C.state.ViborVse || this.props.C.state.ViborIshodashie || this.props.C.state.ViborVhodashie) {
			//console.log("Puti - Tochka");
		//	this.props.C.TochkiSoedinit({ Item: this.props.C.state.Tochki.filter(T => T.iId == this.props.Tochka.iId)[0] });
		//} else
		//	this.props.C.TochkiClick({ Item: this.props.C.state.Tochki.filter(T => T.iId == this.props.Tochka.iId)[0] });
	}
	
	render() {

		return <RFeature key={this.props.Put.iId} feature={this.state.Metka} onClick={this.Vibor}>
			<ROverlay className="P11PX">
				{this.props.C.state.PokazivatNomera && <span class="P11PX Icon R_NoSel R_CC KR_MTN"
					style={{
						color: (
							((this.props.C.state.PutiAktivnii || {}).iId == this.props.Put.iId)
							|| ((this.props.C.state.PutiAktivnii || {}).iIdO == this.props.Put.iId)
						) ? "var(--GM1)" : "var(--GM10)"
					}}
				>
					№ П{this.props.Put.iId}
				</span>}
			</ROverlay>
		</RFeature>
	}
}
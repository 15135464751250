import { Component, createRef } from "react"
import { Link } from 'react-router-dom';
import ScrollArea from "react-scrollbars-custom"


import { Okno, OOkno } from "../Moduli/Okna"
import { RandomColor, KeyGen } from "./Funkcii"

import "./Elementi.scss";


/**
@param {boolean} ColAutoWidth	>	Use one wide col		>	true
@param {boolean} ColAutoHeight	>	Dynamic col height		>	true
@param {boolean} AutoResize		>	Observe content updates	>	true
@param {any} ref				>	Manual update size		>	this.ContentWide
@param {number} Margin			>	Margin of elements(px)	>	10
@example
this.ContentWide = React.createRef();
WidthChange=()=>{if (this.ContentWide) if (this.ContentWide.current) this.ContentWide.current.WidthChange()}

<ContentWide ref={this.ContentWide} Margin={10} ScrollPoint={"H_SP"} ColAutoWidth ColAutoHeight AutoResize>
	{Items.map(ItemData=>{return <div class="H_SP" style={{margin: "10px"}}/>})}
</ContentWide>
*/
export class ContentWide extends Component {
	constructor(props) {
		super(props);
		this.state = { Enabled: false, Offset: { I: 0, P: 0 }, ScrollPoints: [], CSSItemWKey: "" };
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.WidthChange);
		this.CSSItemW.remove();
	}
	componentDidMount() {
		this.FixedZone = createRef();
		this.TouchKD = new Date().valueOf();
		this.setState({ CSSItemWKey: "FullW_" + KeyGen({}) }, () => {
			this.CSSItemW = document.getElementById(this.state.CSSItemWKey);
			if (!this.CSSItemW) {
				this.CSSItemW = document.createElement("style");
				this.CSSItemW.setAttribute("id", this.state.CSSItemWKey);
				document.getElementsByTagName("head")[0].appendChild(this.CSSItemW);
			}
		});

		window.addEventListener("resize", this.WidthChange);
	}
	componentDidUpdate(_propsOld, stateOld) {
		if ((stateOld.Offset.I != this.state.Offset.I) || (stateOld.Offset.P != this.state.Offset.P)) return;
		this.WidthChange();
	}

	WidthChange = () => {
		if (this.WidthResizeTask) {
			clearTimeout(this.WidthResizeTask);
			this.WidthResizeTask = setTimeout(this.WidthResize, 100);
		}
		else {
			this.WidthResizeTask = setTimeout(this.WidthResize, 100);
		}
	}

	WidthResize = () => {
		if (this.FixedZone.current == null) return;
		let Items = this.FixedZone.current.children[0].children;
		if (Items.length == 0) {
			if (this.state.Enabled) this.setState({ Enabled: false, Offset: { I: 0, P: 0 }, ScrollPoints: [] });
			return;
		}

		let OffsetMargin = (this.props.Margin || 0);
		let I = 0, J = 0;

		//fps--
		if (this.props.ColAutoWidth) {
			let Parent = this.FixedZone.current.parentElement;
			while (!Parent.className.includes("Okno"))
				Parent = Parent.parentElement;
			this.CSSItemW.innerHTML = ".ContentWide>.FixedZone>.WideZone>*{" + "}";
			let ItemStyles = window.getComputedStyle(Items[0]);
			let MarginLeft = Number.parseInt(ItemStyles["margin-left"]) || 0, MarginRight = Number.parseInt(ItemStyles["margin-right"]) || 0;
			if ((Items[0].offsetWidth + MarginLeft + MarginRight) * 2 + OffsetMargin * 4 > Parent.offsetWidth - 50 * 2) {
				let AutoWidth = Parent.offsetWidth - 50 * 2 - OffsetMargin * 2 - MarginLeft - MarginRight + "px !important;";
				this.CSSItemW.innerHTML = ".ContentWide>.FixedZone>.WideZone>*{"
					+ "min-width: " + AutoWidth + "max-width: " + AutoWidth + "width: " + AutoWidth
					+ "}";
			}
		}

		//fps--
		let FZHeight = this.FixedZone.current.offsetHeight;
		if (this.props.ColAutoHeight) {
			for (I = 0; I < Items.length; ++I) {
				if (Items[I]) {
					for (J = 0; J < Items[I].children.length; ++J) {
						if (Items[I].children[J]) {
							if (Items[I].children[J].className.includes("ScrollbarsCustom")) {
								/*if (Items[I].children[J].children[0].children[0].children[0].children[0].offsetHeight > FZHeight){
									Items[I].classList.add("FullH");
									continue;
								}*/

								Items[I].classList.remove("FullH");
								Items[I].children[J].children[0].style.position = "relative";
								Items[I].children[J].children[0].children[0].style.position = "relative";
								Items[I].children[J].children[0].children[0].children[0].style.marginBottom = "50px";
								if (Items[I].offsetHeight > FZHeight) {
									Items[I].children[J].children[0].style.position = "absolute";
									Items[I].children[J].children[0].children[0].style.position = "absolute";
									Items[I].children[J].children[0].children[0].children[0].style.marginBottom = "0px";
									Items[I].classList.add("FullH");
								}
							}
						}
					}
				}
			}
		}

		//fps+-
		if ((this.FixedZone.current.offsetWidth + Items[0].offsetLeft - OffsetMargin
			- Items[Items.length - 1].offsetLeft - Items[Items.length - 1].offsetWidth
		) > 0) {
			if (this.state.Enabled) this.setState({ Enabled: false, Offset: { I: 0, P: 0 }, ScrollPoints: [] });
		} else {
			let Points = [];
			let OffsetLeft = (Items[0].offsetLeft - OffsetMargin) * (-1);
			if (this.state.ScrollPoints.includes(Items[Items.length - 1].offsetLeft - OffsetMargin + OffsetLeft)) {
				Points = this.state.ScrollPoints;
			} else {
				for (I = 0; I < Items.length; ++I) {
					if (Items[I]) {
						if (!Points.includes(Items[I].offsetLeft - OffsetMargin + OffsetLeft)) {
							Points.push(Items[I].offsetLeft - OffsetMargin + OffsetLeft);
						}
					}
				}
			}

			if (JSON.stringify(this.state.ScrollPoints) != JSON.stringify(Points)) this.setState({ ScrollPoints: Points });
			if (!this.state.Enabled) this.setState({ Enabled: true });
		}
	}

	ToLeft = () => { this.Scroll({ Index: this.state.Offset.I - 1, Offset: 100 }) }
	ToRight = () => { this.Scroll({ Index: this.state.Offset.I + 1, Offset: -100 }) }
	Scroll = (Params) => {
		if (!this.state.Enabled) return;
		let Offset = { I: 0, P: 0 };

		if ((this.state.Offset.P + Params.Offset > 0) || (Params.Index < 0)) {
			Offset = { I: 0, P: 0 };
		} else if (Params.Index >= this.state.ScrollPoints.length - 1) {
			Offset = { I: this.state.ScrollPoints.length - 1, P: -this.state.ScrollPoints[this.state.ScrollPoints.length - 1] };
		} else {
			Offset = { I: Params.Index, P: -this.state.ScrollPoints[Params.Index] };
		}

		if ((Offset.I != this.state.Offset.I) || (Offset.P != this.state.Offset.P)) this.setState({ Offset });
	}

	ScrollWideSpin = (E) => {
		if (this.ChildrenScrollCheck(E.target)) return;
		//deltaMode, deltaX, deltaY, deltaZ
		if ((E.deltaX > 0) || (E.deltaY > 0)) this.ToRight();
		if ((E.deltaX < 0) || (E.deltaY < 0)) this.ToLeft();
	}

	ChildrenScrollCheck = (El) => {
		if (El.parentElement.className.includes("ContentWide") || El.className.includes("ContentWide"))
			return false;
		if (El.offsetHeight > El.parentElement.offsetHeight)
			return true;
		else
			return this.ChildrenScrollCheck(El.parentElement);
	}

	TouchMove = (E) => {
		let Ticks = new Date().valueOf();
		if (Ticks - this.TouchKD < 50) return;

		if ((Ticks - this.TouchKD > 100) || (!this.TouchLast)) {
			this.TouchLast = {
				XMin: E.touches[0].screenX - this.FixedZone.current.offsetWidth * 0.05,
				XMax: E.touches[0].screenX + this.FixedZone.current.offsetWidth * 0.05,
				YMin: E.touches[0].screenY - this.FixedZone.current.offsetHeight * 0.05,
				YMax: E.touches[0].screenY + this.FixedZone.current.offsetHeight * 0.05
			};
			this.TouchKD = Ticks;
			return;
		}
		else if ((E.touches[0].screenY >= this.TouchLast.YMin) && (E.touches[0].screenY <= this.TouchLast.YMax)) {
			if (E.touches[0].screenX < this.TouchLast.XMin) { this.ToRight() }
			else if (E.touches[0].screenX > this.TouchLast.XMax) { this.ToLeft() }

			this.TouchKD = Ticks + 200;
		}

		this.TouchKD = Ticks;
	}

	render() {
		return <div class="ContentWide">
			<div ref={this.FixedZone} class="FixedZone" onWheel={this.ScrollWideSpin} onTouchMove={this.TouchMove}>
				<div class="WideZone" style={{ marginLeft: (this.state.Offset.P + "px") }}>
					{this.props.children}
				</div>
			</div>

			<div class={"ScrollWide R_NoSel" + (this.state.Enabled ? "" : " Hide")} onWheel={this.ScrollWideSpin}>
				<div class="Up Button R_CC R_MI R_FS30" onClick={this.ToLeft}>keyboard_arrow_up</div>
				<div class="FixedZone">
					{this.state.ScrollPoints.map((Offset, Index) => {
						return <ScrollPointButton
							Index={Index} Offset={Offset} Selected={this.state.Offset.I} Scroll={this.Scroll}
						/>
					})}
				</div>
				<div class="Down Button  R_CC R_MI R_FS30" onClick={this.ToRight}>keyboard_arrow_down</div>
			</div>
		</div>
	}
}

class ScrollPointButton extends Component {
	ItemClick = () => { this.props.Scroll({ Index: this.props.Index, Offset: -this.props.Offset }) }
	render() {
		return <div class={"Button" + (this.props.Selected == this.props.Index ? " HL" : "")} onClick={this.ItemClick}>
			<div class="Line" /><div class="Indicator" />
		</div>
	}
}

export class ContentCol extends Component {
	componentDidMount() {
		this.Area = createRef();
	}
	onScroll = (_, DataOld) => {
		if (!this.Scrolling) {
			this.ScrollY = DataOld.scrollTop;
			this.ScrollX = DataOld.scrollWidth;
			if (this.Area) if (this.Area.current)
				this.Area.current.scrollTo(DataOld.scrollWidth, DataOld.scrollTop)
		} else if ((DataOld.scrollTop == this.ScrollY) && (DataOld.scrollWidth == this.ScrollX)) {
			if (this.Area) if (this.Area.current)
				this.Area.current.scrollTo(DataOld.scrollWidth, DataOld.scrollTop)
		}
	}
	onScrollStart = () => { this.Scrolling = true }
	onScrollStop = () => { this.Scrolling = false; this.ScrollY = this.ScrollX = -1 }
	//{/*...SAProps*/}
	render() {
		return <div class={"ContentCol " + (this.props.className || "ColW500")}>
			<div class="TitleMain R_NoSel">
				{this.props.TitleItems}
			</div>
			<ScrollArea ref={this.Area} className={"HeLo50"} noDefaultStyles={true}
				onScroll={this.onScroll} onScrollStart={this.onScrollStart} onScrollStop={this.onScrollStop}
			><div class="Block">
					{this.props.children}
				</div></ScrollArea>
		</div>
	}
}

export const ColTitleItemRender = ({ Icon = "", Title = "", Desc = "", P = false, onClick }) => {
	return <div class={"TitleItem" + (P ? " P" : "")} onClick={onClick}>
		<div class="Icon R_CC R_MI R_FS30" title={Desc}>{Icon}</div>
		<div class="R_CC">{Title}</div>
	</div>
}



export class WideList extends Component {
	render() {
		// {Id: "", Title: "", Desc: "", Icon: "", Indicator: "", Blocked: "", NoBorder: ""} key={this.props.Parser(Item).Id}
		return <>
			{this.props.Items.map((Item, I) => {
				return <WideItem key={I} Index={I} LP={this.props.LP} LF={this.props.LF} Item={this.props.Parser(Item)} ItemData={Item}
					ItemLClick={this.props.ItemLClick} ItemClick={this.props.ItemClick} ItemRClick={this.props.ItemRClick}
					IconLColor={this.props.RandomLColor ? RandomColor() : undefined}
				/>
			})}
		</>
	}
}
export class WideItem extends Component {
	MI = "Icon R_NoSel R_CC R_MI R_FS30"; TI = "Icon R_NoSel R_CC R_TI ";
	
	ItemClick = () => {
		if (this.props.Item.Click) this.props.Item.Click({ Item: (this.props.ItemData || this.props.Item), Index: this.props.Index })
		else if (this.props.ItemClick) this.props.ItemClick({ Item: (this.props.ItemData || this.props.Item), Index: this.props.Index })
	}
	ItemLClick = () => { (this.props.Item.LClick || this.props.ItemLClick || this.ItemClick)({ Item: (this.props.ItemData || this.props.Item), Index: this.props.Index }) }
	ItemRClick = () => { (this.props.Item.RClick || this.props.ItemRClick || this.ItemClick)({ Item: (this.props.ItemData || this.props.Item), Index: this.props.Index }) }

	render() {
		this.ITL = this.props.Item.IconLT || this.props.IconLT || "MI";
		this.IconLColor = this.props.Item.IconLColor || this.props.IconLColor || "";
		this.IconLText = this.props.Item.IconLText || this.props.IconLText || "";
		if (this.ITL == "MI") {
			this.ICL = this.MI; this.ISL = { color: this.IconLColor };
		} else if (this.ITL == "TI") {
			this.ICL = this.TI; this.ISL = {
				background: `radial-gradient(circle, ${this.IconLColor}, ${this.IconLColor} 52%, transparent 55%)`,
				color: this.IconLText
			};
		} else { this.ICL = ""; this.ISL = {}; }

		this.ITR = this.props.Item.IconRT || this.props.IconRT || "MI";
		this.IconRColor = this.props.Item.IconRColor || this.props.IconRColor || "";
		this.IconRText = this.props.Item.IconRText || this.props.IconRText || "";
		if (this.ITR == "MI") {
			this.ICR = this.MI; this.ISR = { color: this.IconRColor };
		} else if (this.ITR == "TI") {
			this.ICR = this.TI; this.ISR = {
				background: `radial-gradient(circle, ${this.IconRColor}, ${this.IconRColor} 52%, transparent 55%)`,
				color: this.IconRText
			};
		} else { this.ICR = ""; this.ISR = {}; }


		return <div class={"WideItem"
			+ ((this.props.Item.Blocked || this.props.Blocked) ? "" : " P")
			+ ((this.props.Item.LP || this.props.LP) ? " W" : "")
			+ ((this.props.Item.LF || this.props.LF) ? " LF" : "")
		}>
			{(this.props.Item.Indicator || this.props.Indicator) && <>
				<div class="Indicator L" /><div class="Indicator R" />
			</>}
			<div onClick={this.ItemLClick} class={this.ICL} style={this.ISL}>{this.props.Item.IconL || this.props.IconL || ""}</div>
			<div onClick={this.ItemClick} class={"Item" + ((this.props.Item.NoBorder || this.props.NoBorder) ? " NB" : "")}>
				<span class="Text">
					<div class="Title" title={this.props.Item.Title}>{this.props.Item.Title}</div>
					<div class="Desc" title={this.props.Item.Desc}>{this.props.Item.Desc}</div>
				</span>
			</div>
			<div onClick={this.ItemRClick} class={this.ICR} style={this.ISR}>{this.props.Item.IconR || this.props.IconR || ""}</div>
		</div>
	}
}

export class WideInput extends Component {
	constructor(props) {
		super(props);
		this.Ref = createRef(); //.select()
	}
	
	ItemLClick = () => { (this.props.Item.LClick || this.props.ItemLClick)({ Item: (this.props.ItemData || this.props.Item) }) }
	ItemRClick = () => { (this.props.Item.RClick || this.props.ItemRClick)({ Item: (this.props.ItemData || this.props.Item) }) }

	Knopka = (IE) => {
		if (IE.key === "Escape") this.ItemRClick();
		if ((IE.key === "Enter") && !IE.altKey && !IE.ctrlKey && !IE.metaKey && !IE.shiftKey) this.ItemLClick();
	}

	Focus = () => { this.Ref.current.focus(); }

	render() {
		return <div class="WideItem P">
			{(this.props.Item.Indicator || this.props.Indicator) && <>
				<div class="Indicator L" /><div class="Indicator R" />
			</>}
			<div onClick={this.ItemLClick} class="Icon R_CC R_MI R_FS30 R_NoSel" style={{
				color: (this.props.Item.IconLColor || this.props.IconLColor || "")
			}}>{this.props.Item.IconL || this.props.IconL || ""}</div>
			<div class="Item"><input ref={this.Ref} style={{ width: "100%" }} class="R_IMB"
				id={this.props.Id || this.props.Item.Id} type={this.props.Type || this.props.Item.Type} onChange={this.props.ValueChange} onKeyDown={this.Knopka}
				value={this.props.Value || this.props.Item.Value || this.props.Title || this.props.Item.Title}
			/></div>
			<div onClick={this.ItemRClick} class="Icon R_CC R_MI R_FS30 R_NoSel" style={{
				color: (this.props.Item.IconRColor || this.props.IconRColor || "")
			}}>{this.props.Item.IconR || this.props.IconR || ""}</div>
		</div>
	}
}

export class WideItemE extends Component {
	constructor(props) {
		super(props);

		this.state = {
			Izmenenie: false, Tekst: this.props.Item.Title
		};

		this.Ref = createRef();
	}

	Izmenit = () => {
		this.setState({ Izmenenie: true, Tekst: this.props.Item.Title }, () => { this.Ref.current.Focus(); });
	}
	Sohranit = () => {
		if (this.props.Poluchatel) this.props.Poluchatel({ Item: this.props.Item, Tekst: this.state.Tekst });
		this.setState({ Izmenenie: false });
	}
	Otmenit = () => { this.setState({ Izmenenie: false }); }

	Izmenatel = (IE) => {
		if (this.props.Proveratel) this.setState({ Tekst: this.props.Proveratel(IE.target.value) });
		else this.setState({ Tekst: IE.target.value });
	}

	Item = (Params) => {
		let Props = Object.assign({}, Params); Props.Item = Object.assign({}, Params.Item);
		Props.Item.IconL = undefined; Props.Item.Value = this.state.Tekst; return Props;
	}

	render() {
		if (this.state.Izmenenie)
			return <WideInput ref={this.Ref} {...this.Item(this.props)} ValueChange={this.Izmenatel}
				ItemLClick={this.Sohranit} IconL="save" IconLColor="var(--GM9)"
				ItemRClick={this.Otmenit} IconR="cancel_presentation" IconRColor="var(--RM1)"
			/>
		return <WideItem IconR="drive_file_rename_outline" IconRColor="var(--GM8)" IconLColor="var(--RM6)" IconLText="var(--GM3)"
			{...this.props} ItemClick={this.Izmenit}
		/>
	}
}

export class WideLink extends Component {
	ItemRClick = () => { (this.props.ItemRClick || this.ItemClick)({ Item: (this.props.ItemData || this.props.Item) }) }

	render() {
		return <div className={"WideItem P W"}>
			{((window.location.pathname == this.props.Path) && (this.props.Item.Indicator || this.props.Indicator)) && <div class="Indicator L" />}
			<Link to={this.props.To} className="Icon R_CC R_MI R_FS30 R_NoSel" style={{
				color: (this.props.Item.IconLColor || this.props.IconLColor || "")
			}}>{this.props.Item.IconL || ""}</Link>
			<Link to={this.props.To} className={"Item" + ((this.props.Item.NoBorder || this.props.NoBorder) ? " NB" : "")}>
				<span class="Text">
					<div class="Title" title={this.props.Item.Title}>{this.props.Item.Title}</div>
					<div class="Desc" title={this.props.Item.Desc}>{this.props.Item.Desc}</div>
				</span>
			</Link>
			<div onClick={this.ItemRClick} class="Icon R_CC R_MI R_FS30 R_NoSel" style={{
				color: (this.props.Item.IconRColor || this.props.IconRColor || "")
			}}>{this.props.Item.IconR || ""}</div>
		</div>
	}
}

export class WideItemO extends Component {
	constructor(props) {
		super(props);
		this.OknoRef = createRef();
	}

	ItemClick = (Params) => {
		this.props.ItemClick(Params);
		this.OknoRef.current.Zakrit();
	}

	render() {
		return <OOkno Aktivator={<WideItem Item={this.props.Item} />}>
			<Okno ref={this.OknoRef} Skrivaemoe Zakrivaemoe TitleIcon={this.props.TitleIcon} TitleText={this.props.TitleText || "Выберите элемент"} Rows={this.props.TitleRows || <></>}>
				<ContentWide AutoResize ColAutoWidth>
					{this.props.Items.map(Item => <div class="WideItemBF">
						<WideItem Item={this.props.ItemParser(Item)} ItemData={Item} ItemClick={this.ItemClick} />
					</div>)}
				</ContentWide>
			</Okno>
		</OOkno>
	}
}



export class ShortList extends Component {

	render() {
		return null;
	}
}

export class ShortItem extends Component {
	MI = "Icon R_NoSel R_CC R_MI R_FS40"; TI = "Icon R_NoSel R_CC R_TI ";

	ItemClick = () => {
		if (this.props.Item.Click) this.props.Item.Click({ Item: (this.props.ItemData || this.props.Item) })
		else if (this.props.ItemClick) this.props.ItemClick({ Item: (this.props.ItemData || this.props.Item) })
	}

	render() {
		this.IT = this.props.Item.IconL || this.props.IconL || "MI";
		this.IconColor = this.props.Item.IconColor || this.props.IconColor || "";
		this.IconText = this.props.Item.IconText || this.props.IconText || "";
		if (this.IT == "MI") {
			this.IC = this.MI; this.IS = { color: this.IconColor };
		} else if (this.IT == "TI") {
			this.IC = this.TI; this.IS = {
				background: `radial-gradient(circle, ${this.IconColor}, ${this.IconColor} 52%, transparent 55%)`,
				color: this.IconText
			};
		} else { this.IC = ""; this.IS = {}; }
		//((this.props.Item.NoBorder || this.props.NoBorder) ? " NB" : "")

		return <div onClick={this.ItemClick} class={"ShortItem"
			+ ((this.props.Item.NoBorder || this.props.NoBorder) ? "" : " B")
			+ ((this.props.Item.Blocked || this.props.Blocked) ? "" : " P")
		} title={this.props.Item.Desc}>
			<div class={this.IC} style={this.IS}>{this.props.Item.Icon || this.props.Icon || ""}</div>
			<div class="Title">{this.props.Item.Title}</div>
			{(this.props.Item.Indicator || this.props.Indicator) && <div class="Indicator" />}
		</div>
	}
}

export class ShortLink extends Component {

	render() {
		this.IconColor = this.props.Item.IconColor || this.props.IconColor || "";
		this.IS = { color: this.IconColor };

		return <Link to={this.props.To} className={"ShortItem P"
			+ ((this.props.Item.NoBorder || this.props.NoBorder) ? "" : " B")
		} title={this.props.Item.Desc}>
			<div class="Icon R_NoSel R_CC R_MI R_FS40" style={this.IS} title={this.props.Item.Desc}>{this.props.Item.Icon || this.props.Icon || ""}</div>
			<div class="Title" title={this.props.Item.Desc}>{this.props.Item.Title}</div>
			{((window.location.pathname == this.props.Path) && (this.props.Item.Indicator || this.props.Indicator)) && <div class="Indicator" title={this.props.Item.Desc} />}
		</Link>
	}
}
import { Component, createContext } from "react"

import { Zapros, KeyGen } from "./Funkcii"

import { Okno, OknoMenuStroka } from "../Moduli/Okna"
import { WideItem, WideInput } from "../Moduli/Elementi"

export const Vhod = createContext();
Vhod.displayName = "ServerVhoda";

const Deistvitelnost = 30 * 24 * 60 * 60;

export const PolzovatelNull = {
	Token: null, Id: null, Nik: "", Ima: "Гость", ImaK: "Гость"
};
const PolzovatelNullN = {
	Token: null, Id: 0, Nik: "", Ima: "Гость", ImaK: "Гость"
};

export class ServerVhoda extends Component {
	constructor(props) {
		super(props);

		this.state = {
			Polzovatel: PolzovatelNull,
			Login: "", Parol: "",
			Provereno: false, Text: "",
			Zakrito: undefined
		};

		this.Poluchateli = {}
;
	}

	componentDidMount() {
		this.Proverit();
	}

	Proverit = () => {
		this.setState({ Provereno: false });
		let Token = null;
		let Matches = document.cookie.match(new RegExp(
			"(?:^|; )" + ("Token").replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
		));
		if (Matches) Token = Matches[0];
		if (Token != null)
			Zapros({
				S: "Polzovateli/Proverit", M: "Vhod", N: "Проверка"
			}).then(O => {
				if (O.Text == undefined) {
					this.setState({ Polzovatel: O, Text: "" });
					//domain=df.magnezius.ru; 
					document.cookie = "Token=" + O.Token + "; path=/; max-age=" + Deistvitelnost + "; secure; samesite=strict";
				} else {
					this.setState({ Text: O.Text });
				}
				
				this.setState({ Provereno: true });
			}).catch(O => {
				console.log("avto-vihod");
				this.setState({ Polzovatel: PolzovatelNullN });
				document.cookie = "Token=" + "" + "; path=/; max-age=" + -1 + "; secure; samesite=strict";
				this.setState({ Provereno: true, Polzovatel: PolzovatelNullN });
			});
		else
			this.setState({ Provereno: true, Polzovatel: PolzovatelNullN });
	}
	Voiti = () => {
		this.setState({ Provereno: false });
		Zapros({
			S: "Polzovateli/Voiti", M: "Vhod", N: "Проверка", D: {Login: this.state.Login, Parol: this.state.Parol}
		}).then(O => {
			if (O.Text == undefined) {
				this.setState({ Polzovatel: O });
				//domain=df.magnezius.ru; 
				document.cookie = "Token=" + O.Token + "; path=/; max-age=" + Deistvitelnost + "; secure; samesite=strict";
				this.setState({ Provereno: true });
			} else {
				this.setState({ Text: O.Text });
				this.setState({ Provereno: true });
			}
		});
	}
	Viti = () => {
		Zapros({ S: "Polzovateli/Viti", D: { Skolko: "Odin" }, M: "Vhod", N: "Проверка" }).then(O => {
			this.setState({ Polzovatel: PolzovatelNullN }, () => {
				Object.keys(this.Poluchateli).forEach(K => {
					if (this.Poluchateli[K]) {
						this.Poluchateli[K].Vhod = {};
						this.Poluchateli[K].Vhod.Viti = this.Viti; this.Poluchateli[K].Vhod.Proverit = this.Proverit; this.Poluchateli[K].Vhod.Zakrit = this.Zakrivatel;
						this.Poluchateli[K].setState({ Polzovatel: { ...this.state.Polzovatel } });
					}
				});
			});
			document.cookie = "Token=" + "" + "; path=/; max-age=" + -1 + "; secure; samesite=strict";
		}).catch(O => {
			this.setState({ Polzovatel: PolzovatelNullN }, () => {
				Object.keys(this.Poluchateli).forEach(K => {
					if (this.Poluchateli[K]) {
						this.Poluchateli[K].Vhod = {};
						this.Poluchateli[K].Vhod.Viti = this.Viti; this.Poluchateli[K].Vhod.Proverit = this.Proverit; this.Poluchateli[K].Vhod.Zakrit = this.Zakrivatel;
						this.Poluchateli[K].setState({ Polzovatel: { ...this.state.Polzovatel } });
					}
				});
			});
			document.cookie = "Token=" + "" + "; path=/; max-age=" + -1 + "; secure; samesite=strict";
		});
	}


	LoginZadat = (E) => { this.setState({ Login: E.target.value }) }
	ParolZadat = (E) => { this.setState({ Parol: E.target.value }) }

	Zakrivatel = () => {
		this.setState({ Zakrito: true });
	}

	Zapolnatel = (C, N) => {
		if (C.state.Polzovatel.Id != this.state.Polzovatel.Id) {
			this.Poluchateli[N] = C;
			C.Vhod = {};
			C.Vhod.Viti = this.Viti; C.Vhod.Proverit = this.Proverit; C.Vhod.Zakrit = this.Zakrivatel;
			C.setState({ Polzovatel: { ...this.state.Polzovatel } });
		}
	}

	render() {
		return <Vhod.Provider value={this.Zapolnatel}>
			{((this.state.Zakrito || this.props.Zakrito) && (this.state.Polzovatel.Ima == "Гость")) ? <Okno Nazivaemoe TitleIcon="help" TitleText="СПП.Вход"
				Rows={this.state.Provereno ? <>
					<WideItem Blocked IconLColor="var(--GM1)" Item={{
						IconL: "contact_mail", Title: "Логин"//, Desc: ""
					}} />
					<WideInput IconLColor="var(--GM1)" Value={this.state.Login} ValueChange={this.LoginZadat} Item={{
						IconL: "abc", Id: "Login", Type: "text"
					}} />
					<WideItem Blocked IconLColor="var(--GM2)" Item={{
						IconL: "contact_emergency", Title: "Пароль"
					}} />
					<WideInput IconLColor="var(--GM2)" Value={this.state.Parol} ValueChange={this.ParolZadat} Item={{
						IconL: "password", Id: "Password", Type: "password"
					}} />
					<WideItem IconLColor="var(--RM6)" ItemClick={this.Voiti} Item={{
						IconL: "login", Title: "Войти"
					}} />
					{(this.state.Text != "") && <WideItem Blocked IconLColor="var(--GM4)" Item={{
						IconL: "gpp_bad", Title: "Вход не выполнен", Desc: this.state.Text
					}} />}
					<WideItem IconLColor="var(--GM1)" ItemClick={() => { window.location = "/"} } Item={{
						IconL: "reset_tv", Title: "На главную", Desc: "Вернуться на главную страницу"
					}} />
				</> : <>
						<WideItem Blocked IconLColor="var(--GM7)" Item={{
							IconL: "hourglass_empty", Title: "Проверка", Desc: "Проверка данных на сервере"
						}} />
						<WideItem IconLColor="var(--GM1)" ItemClick={() => { window.location = "/" }} Item={{
							IconL: "reset_tv", Title: "На главную", Desc: "Вернуться на главную страницу"
						}} />
				</>}
			>
			</Okno> : this.props.children}
		</Vhod.Provider>
	}
}


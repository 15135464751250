import { Component } from 'react';
import { Link } from 'react-router-dom';

import { fromLonLat } from "ol/proj";
import { boundingExtent/*, getCenter*/ } from "ol/extent";
import "ol/ol.css";
import "rlayers/control/layers.css";
import { /*Geometry,*/ Point, Polygon, LineString } from "ol/geom";
//import { Geolocation as OLGeoLoc } from "ol";

import { RMap, RControl, ROSMWebGL, RLayerTileWebGL/*, RGeolocation*/ } from "rlayers";
import { RLayerVector, RFeature, ROverlay, RPopup } from "rlayers";
import { RStyle, /*RIcon,*/ RFill, RStroke } from "rlayers/style";

import { Vhod, PolzovatelNull } from "../Moduli/Vhod"
import { Okno, OknoMenuStroka } from "../Moduli/Okna"
import {
	WideList, WideItem, WideLink, ShortItem, ShortLink
} from "../Moduli/Elementi"
import { Zapros, RandomColor } from "../Moduli/Funkcii"

import { Dorogi  } from "./Dopolnenia"

import KI_M from "../Faili/M.png"

import "./Karta.scss";

const Raion = boundingExtent([fromLonLat([58.864, 54.975]), fromLonLat([59.188, 55.147])]);
const Satka = fromLonLat([59.030, 55.058]); //12.7
const Ofis = [6564705.0826363, 7368956.586223013];// fromLonLat([58.972, 55.037]);

export default class Karta extends Component {
	constructor(props) {
		super(props);

		this.state = {
			Polzovatel: PolzovatelNull,
			Centr: { center: Satka, zoom: 12.7, resolution: 23.526255086495535 },
			Ploshadki: [], PloshadkiAktivnii: null,
			Sektori: [], SektoriAktivnii: null,
			Zdanii: [], ZdaniiAktivnii: null,
			Kompleksi: [], KompleksiAktivnii: null,
			Organizacii: [], OrganizaciiAktivnii: null,
			Segmenti: [],
			Poisk: "",
			Menu: [], MenuClick: () => { }, MenuParser: () => { }, MenuAktivnii: null,
		};

		this.ObektTimeout = null;
	}

	componentDidMount() {
		Zapros({ S: "Karta/Ploshadki/Spisok" }).then(O => {
			this.setState({
				Ploshadki: O.map(P => {
					P.jDannie = JSON.parse(P.jDannie); return P;
				})
			});
		});
		Zapros({ S: "Karta/Sektori/Spisok" }).then(O => {
			this.setState({
				Sektori: O.map(P => {
					P.jDannie = JSON.parse(P.jDannie); return P;
				})
			});
		});
		Zapros({ S: "Karta/Zdanii/Spisok" }).then(O => {
			this.setState({
				Zdanii: O.map(P => {
					P.jDannie = JSON.parse(P.jDannie); return P;
				})
			});
		});
		Zapros({ S: "Karta/Kompleksi/Spisok" }).then(O => { this.setState({ Kompleksi: O }); });
		Zapros({ S: "Karta/Organizacii/Spisok" }).then(O => {
			this.setState({
				Organizacii: O.map(P => {
					P.jDannie = JSON.parse(P.jDannie); return P;
				})
			});
		});
		Zapros({ S: "Karta/Puti/Segmenti" }).then(O => {
			this.setState({ Segmenti: O.map(S => { S.jDannie = JSON.parse(S.jDannie); return S }) });
		});
	}

	Zakrivatel = () => { (this.Vhod || {}).Zakrit(); }

	CentrPostavit = (Centr) => { this.setState({ Centr }); if (false) console.log(Centr); }
	VCentr = () => {
		this.CentrPostavit({ center: Satka, zoom: 12.7, resolution: 23.526255086495535 });
		this.setState({ Menu: [], MenuClick: () => { }, MenuParser: () => { } });
	}
	KartaTochka = (ME) => {
		//console.log(ME);
		//console.log(ME.map.getCoordinateFromPixel(ME.pixel));
		/*if (this.state.Shablon.Otkrit) {
			let { Shablon } = this.state; Shablon.Tochka = ME.map.getCoordinateFromPixel(ME.pixel);
			this.setState({ Shablon });
		}*/
	}
	PloshadkaTochka = (Params) => {
		if (this.state.PloshadkiAktivnii == null) {
			this.PloshadkiAktivnii = { Deistvie: "Vibor", Element: Params.Item };
		} else if (this.state.PloshadkiAktivnii.iId != Params.Item.iId) {
			this.PloshadkiAktivnii = { Deistvie: "Perenos", Element: Params.Item };
		} else {
			this.PloshadkiAktivnii = { Deistvie: "Snatie", Element: null };
		}
		if (this.ObektTimeout) clearTimeout(this.ObektTimeout);
		this.ObektTimeout = setTimeout(this.ObektTochka, 100);

		/*if ((this.state.PloshadkiAktivnii == null) || (this.state.PloshadkiAktivnii.iId != Params.Item.iId)) {
			//let { Centr } = this.state;
			//Centr.center[0] = (Params.Item.jDannie.Granici.MinX + Params.Item.jDannie.Granici.MaxX) / 2;
			//Centr.center[1] = (Params.Item.jDannie.Granici.MinY + Params.Item.jDannie.Granici.MaxY) / 2;
			this.setState({ PloshadkiAktivnii: Params.Item, SektoriAktivnii: null });
		} else {
			this.setState({ PloshadkiAktivnii: null, SektoriAktivnii: null });
		}*/
		
	}
	SektorTochka = (Params) => {
		if (this.state.SektoriAktivnii == null) {
			this.SektoriAktivnii = { Deistvie: "Vibor", Element: Params.Item };
		} else if (this.state.SektoriAktivnii.iId != Params.Item.iId) {
			this.SektoriAktivnii = { Deistvie: "Perenos", Element: Params.Item };
		} else {
			this.SektoriAktivnii = { Deistvie: "Snatie", Element: null };
		}
		if (this.ObektTimeout) clearTimeout(this.ObektTimeout);
		this.ObektTimeout = setTimeout(this.ObektTochka, 100);
	}
	ZdanieTochka = (Params) => {
		if (this.state.ZdaniiAktivnii == null) {
			this.ZdaniiAktivnii = { Deistvie: "Vibor", Element: Params.Item };
		} else if (this.state.ZdaniiAktivnii.iId != Params.Item.iId) {
			this.ZdaniiAktivnii = { Deistvie: "Perenos", Element: Params.Item };
		} else {
			this.ZdaniiAktivnii = { Deistvie: "Snatie", Element: null };
		}
		if (this.ObektTimeout) clearTimeout(this.ObektTimeout);
		this.ObektTimeout = setTimeout(this.ObektTochka, 100);
	}
	KompleksTochka = (Params) => {
		if ((this.state.KompleksiAktivnii == null) || (this.state.KompleksiAktivnii.iId != Params.Item.iId)) {
			this.setState({ KompleksiAktivnii: Params.Item });
		} else {
			this.setState({ KompleksiAktivnii: null });
		}
	}
	OrganizaciiTochka = (Params) => {
		if ((this.state.OrganizaciiAktivnii == null) || (this.state.OrganizaciiAktivnii.iId != Params.Item.iId)) {
			this.setState({ OrganizaciiAktivnii: Params.Item });
		} else {
			this.setState({ OrganizaciiAktivnii: null });
		}
	}

	ObektTochka = () => {
		if (this.SektoriAktivnii) {
			if (this.SektoriAktivnii.Deistvie == "Vibor" || this.SektoriAktivnii.Deistvie == "Perenos") {
				let { Centr } = this.state;
				Centr.center[0] = (this.SektoriAktivnii.Element.jDannie.Granici.MinX + this.SektoriAktivnii.Element.jDannie.Granici.MaxX) / 2;
				Centr.center[1] = (this.SektoriAktivnii.Element.jDannie.Granici.MinY + this.SektoriAktivnii.Element.jDannie.Granici.MaxY) / 2;
				this.setState({ SektoriAktivnii: this.SektoriAktivnii.Element, Centr });
			} else
				this.setState({ SektoriAktivnii: null });

			if (this.PloshadkiAktivnii) {
				if (this.PloshadkiAktivnii.Deistvie == "Vibor" || this.PloshadkiAktivnii.Deistvie == "Perenos") {
					let { Centr } = this.state;
					Centr.center[0] = (this.PloshadkiAktivnii.Element.jDannie.Granici.MinX + this.PloshadkiAktivnii.Element.jDannie.Granici.MaxX) / 2;
					Centr.center[1] = (this.PloshadkiAktivnii.Element.jDannie.Granici.MinY + this.PloshadkiAktivnii.Element.jDannie.Granici.MaxY) / 2;
					this.setState({ PloshadkiAktivnii: this.PloshadkiAktivnii.Element, Centr });
				}
			}
			
		} else if (this.PloshadkiAktivnii) {
			if (this.PloshadkiAktivnii.Deistvie == "Vibor" || this.PloshadkiAktivnii.Deistvie == "Perenos") {
				let { Centr } = this.state;
				Centr.center[0] = (this.PloshadkiAktivnii.Element.jDannie.Granici.MinX + this.PloshadkiAktivnii.Element.jDannie.Granici.MaxX) / 2;
				Centr.center[1] = (this.PloshadkiAktivnii.Element.jDannie.Granici.MinY + this.PloshadkiAktivnii.Element.jDannie.Granici.MaxY) / 2;
				this.setState({ PloshadkiAktivnii: this.PloshadkiAktivnii.Element, SektoriAktivnii: null, Centr });
			} else
				this.setState({ PloshadkiAktivnii: null, SektoriAktivnii: null });
		}

		if (this.ZdaniiAktivnii) {
			if (this.ZdaniiAktivnii.Deistvie == "Vibor" || this.ZdaniiAktivnii.Deistvie == "Perenos") {
				let { Centr } = this.state;
				Centr.center[0] = (this.ZdaniiAktivnii.Element.jDannie.Granici.MinX + this.ZdaniiAktivnii.Element.jDannie.Granici.MaxX) / 2;
				Centr.center[1] = (this.ZdaniiAktivnii.Element.jDannie.Granici.MinY + this.ZdaniiAktivnii.Element.jDannie.Granici.MaxY) / 2;
				this.setState({ ZdaniiAktivnii: this.ZdaniiAktivnii.Element, Centr });
			} else
				this.setState({ ZdaniiAktivnii: null});
		}

		this.SektoriAktivnii = null; this.PloshadkiAktivnii = null; this.ZdaniiAktivnii = null;
	}

	NaitiCentr = (Obekti) => {
		let MinX = Obekti[0].Granici.MinX, MinY = Obekti[0].Granici.MinY, MaxX = Obekti[0].Granici.MaxX, MaxY = Obekti[0].Granici.MaxY;
		Obekti.forEach(O => {
			if (O.Granici.MinX < MinX) MinX = O.Granici.MinX;
			if (O.Granici.MinY < MinY) MinY = O.Granici.MinY;
			if (O.Granici.MaxX > MaxX) MaxX = O.Granici.MaxX;
			if (O.Granici.MaxY > MaxY) MaxY = O.Granici.MaxY;
		});
		return [(MinX + MaxX) / 2, (MinY + MaxY) / 2];
	}
	
	MenuPloshadki = (Params) => {
		if ((this.state.MenuAktivnii == null) || (this.state.MenuAktivnii != Params.Item.Title))
			this.setState({ Menu: this.state.Ploshadki, MenuClick: this.PloshadkaTochka, MenuParser: this.PloshadkiParser, MenuAktivnii: Params.Item.Title });
		else
			this.setState({ Menu: [], MenuClick: () => { } , MenuParser: (P) => P, MenuAktivnii: null });
	}
	MenuSektori = (Params) => {
		if ((this.state.MenuAktivnii == null) || (this.state.MenuAktivnii != Params.Item.Title))
			this.setState({ Menu: this.state.Sektori, MenuClick: this.SektorTochka, MenuParser: this.SektoriParser, MenuAktivnii: Params.Item.Title });
		else
			this.setState({ Menu: [], MenuClick: () => { }, MenuParser: (P) => P, MenuAktivnii: null });
	}
	MenuZdanii = (Params) => {
		if ((this.state.MenuAktivnii == null) || (this.state.MenuAktivnii != Params.Item.Title))
			this.setState({ Menu: this.state.Zdanii, MenuClick: this.ZdanieTochka, MenuParser: this.ZdaniiParser, MenuAktivnii: Params.Item.Title });
		else
			this.setState({ Menu: [], MenuClick: () => { } , MenuParser: (P) => P, MenuAktivnii: null });
	}
	MenuKompleksi = (Params) => {
		if ((this.state.MenuAktivnii == null) || (this.state.MenuAktivnii != Params.Item.Title))
			this.setState({ Menu: this.state.Kompleksi, MenuClick: this.KompleksTochka , MenuParser: this.KompleksiParser, MenuAktivnii: Params.Item.Title });
		else
			this.setState({ Menu: [], MenuClick: () => { }, MenuParser: (P) => P, MenuAktivnii: null });
	}
	MenuOrganizacii = (Params) => {
		if ((this.state.MenuAktivnii == null) || (this.state.MenuAktivnii != Params.Item.Title))
			this.setState({ Menu: this.state.Organizacii, MenuClick: this.OrganizaciiTochka, MenuParser: this.OrganizaciiParser, MenuAktivnii: Params.Item.Title });
		else
			this.setState({ Menu: [], MenuClick: () => { }, MenuParser: (P) => P, MenuAktivnii: null });
	}


	PoiskIzmenit = (IE) => { this.setState({ Poisk: IE.target.value }); }

	PloshadkiParser = (Item) => {
		return {
			Id: "KOBP" + Item.iId,
			Title: Item.sNazvanie, Desc: ""/*Item.sOpisanie*/,
			IconLT: "TI", IconL: Item.sMetka, IconLColor: Item.sCvet, IconLText: Item.sCvetTekst,
			Indicator: (this.state.PloshadkiAktivnii || {}).iId == Item.iId
		}
	}
	SektoriParser = (Item) => {
		return {
			Id: "KOBS" + Item.iId,
			Title: Item.sNazvanie, Desc: ""/*Item.sOpisanie*/,
			IconLT: "TI", IconL: Item.sMetka, IconLColor: Item.sCvet, IconLText: Item.sCvetTekst,
			Indicator: (this.state.SektoriAktivnii || {}).iId == Item.iId
		}
	}
	ZdaniiParser = (Item) => {
		return {
			Id: "KOBZ" + Item.iId,
			Title: Item.sMetka, Desc: ""/*Item.sOpisanie*/,
			IconLT: "TI", IconL: Item.sTextOT + Item.sTextOF, IconLColor: Item.sCvetK, IconLText: Item.sCvetTekstK,
			Indicator: (this.state.ZdaniiAktivnii || {}).iId == Item.iId
		}
	}
	KompleksiParser = (Item) => {
		return {
			Id: "KOBK" + Item.iId,
			Title: Item.sOpisanie, Desc: Item.sNazvanie,
			IconLT: "TI", IconL: Item.sText[0], IconLColor: Item.sCvet, IconLText: Item.sCvetTekst,
			Indicator: (this.state.KompleksiAktivnii || {}).iId == Item.iId
		}
	}
	OrganizaciiParser = (Item) => {
		return {
			Id: "KOBO" + Item.iId,
			Title: Item.sNazvanie, Desc: "",
			IconLT: "TI", IconL: Item.iNomer, IconLColor: Item.sCvet, IconLText: Item.sCvetTekst,
			Indicator: (this.state.OrganizaciiAktivnii || {}).iId == Item.iId
		}
	}

	//<Link to="/redaktor">Редактор</Link>warehouse
	render() {
		return <>
			<Vhod.Consumer>{Zapolnatel => Zapolnatel(this, "Karta")}</Vhod.Consumer>
			<Okno Nazivaemoe Skrivaemoe TitleIcon="help" TitleText="СПП.Карта"
				TitleElement={<div style={{ width: "100%", height: "50px" }}>
					<input type="text" class="R_IMB" style={{ width: "100%", paddingRight: "50px" }} value={this.state.Poisk} onChange={this.PoiskIzmenit} placeholder="Поиск" />
					<div class="R_MI R_CC R_FS30 K_IBR">search</div>
				</div>}
				Buttons={<>
					<ShortItem NoBorder ItemClick={this.VCentr} Item={{ Icon: "home", IconColor: "var(--GM1)", Title: "Вся карта", Desc: "Сбросить параметры отображения карты" }} />
					<ShortItem NoBorder ItemClick={this.MenuPloshadki} Item={{
						Icon: "map", IconColor: "var(--GM2)", Title: "Площадки", Desc: "", Indicator: this.state.MenuAktivnii == "Площадки"
					}} />
					<ShortItem NoBorder ItemClick={this.MenuSektori} Item={{
						Icon: "crop", IconColor: "var(--GM2)", Title: "Секторы", Desc: "", Indicator: this.state.MenuAktivnii == "Секторы"
					}} />
					<ShortItem NoBorder ItemClick={this.MenuZdanii} Item={{
						Icon: "warehouse", IconColor: "var(--GM2)", Title: "Здания", Desc: "", Indicator: this.state.MenuAktivnii == "Здания"
					}} />
					<ShortItem NoBorder ItemClick={this.MenuKompleksi} Item={{
						Icon: "business", IconColor: "var(--GM2)", Title: "Комплексы", Desc: "", Indicator: this.state.MenuAktivnii == "Комплексы"
					}} />
					<ShortItem NoBorder ItemClick={this.MenuOrganizacii} Item={{
						Icon: "apartment", IconColor: "var(--GM2)", Title: "Организации", Desc: "", Indicator: this.state.MenuAktivnii == "Организации"
					}} />
					{(this.state.Polzovatel.Id == null)
						? <ShortItem NoBorder Item={{ Icon: "hourglass_empty", IconColor: "var(--GM7)", Title: "Проверка", Desc: "Проверка" }} ItemClick={this.Zakrivatel} />
						: (this.state.Polzovatel.Id == 0)
							? <ShortItem NoBorder Item={{ Icon: "login", IconColor: "var(--RM5)", Title: "Войти", Desc: "Войти" }} ItemClick={this.Zakrivatel} />
						: <>
								<ShortLink NoBorder To="/redaktor"  Path="/redaktor" Item={{Icon: "design_services", IconColor: "var(--GM8)", Title: "Редактор", Desc: "Открыть редактор"}} />
								<ShortItem NoBorder ItemClick={(this.Vhod || {}).Viti} Item={{ Icon: "logout", IconColor: "var(--GM5)", Title: "Выйти", Desc: "Выйти" }}/>
						</>}
				</>}
				Rows={<>
					<WideList LP LF Items={this.state.Menu} ItemClick={this.state.MenuClick} Parser={this.state.MenuParser} />


				</>}
			>
				
				<RMap width={"100%"} height={"100%"} onClick={this.KartaTochka}
					extent={Raion} initial={this.state.Centr} view={[this.state.Centr, this.CentrPostavit]}
				>
					<RControl.RLayers>
						<ROSMWebGL properties={{ label: "OpenStreetMap" }} />
						<RLayerTileWebGL
							properties={{ label: "OpenTopo" }}
							url="https://{a-c}.tile.opentopomap.org/{z}/{x}/{y}.png"
							attributions="Kartendaten: © OpenStreetMap-Mitwirkende, SRTM | Kartendarstellung: © OpenTopoMap (CC-BY-SA)"
						/>
						<RLayerTileWebGL
							properties={{ label: "Transport" }}
							url="http://tile.thunderforest.com/transport/{z}/{x}/{y}.png"
						/>
						<RLayerTileWebGL
							properties={{ label: "Watercolor" }}
							url="https://stamen-tiles.a.ssl.fastly.net/watercolor/{z}/{x}/{y}.jpg"
						/>
					</RControl.RLayers>

					<RLayerVector>
						<RFeature geometry={new Point(Ofis)}>
							<ROverlay className="no-interaction P11PX"  >
								<div class="P11PX">
									<img class="IMG R_KI" src={KI_M} alt="" />
									<span class="K_R_TI">Центральный офис</span>
								</div>
							</ROverlay>
						</RFeature>

						{(this.state.MenuAktivnii == "Площадки") && this.state.Ploshadki.map(P => {
							if ((P.jDannie.Tochki !== undefined) && (this.state.Centr.zoom >= 11.5)) {
								return <RFeature geometry={new Polygon([P.jDannie.Tochki])} onClick={(e) => {
									this.PloshadkaTochka({ Item: P });
								}}>
									<RStyle><RStroke color={P.sCvet} width={3} />
										{((this.state.PloshadkiAktivnii || {}).iId == P.iId)
											? <RFill color={P.sCvet.replace("rgb", "rgba").replace(")", ", 0.1)")} />
											: <RFill color="transparent" />
										}

									</RStyle>
									<RPopup trigger={"hover"} className="example-overlay ">
										<p class="PV" style={{ /* <em></em>background: P.sCvet.replace("rgb", "rgba").replace(")", ", 0.5)"), color: P.sCvetTekst*/ }} >
											{P.sOpisanie}
										</p>
									</RPopup>
								</RFeature>;
							} else return null;
						})}
						{(this.state.MenuAktivnii == "Площадки") && this.state.Ploshadki.map(P => {
							if ((P.jDannie.Tochki !== undefined) && (this.state.Centr.zoom >= 10) && (this.state.Centr.zoom <= 14.5)) {
								return <RFeature geometry={new Point([(P.jDannie.Granici.MinX + P.jDannie.Granici.MaxX) / 2, (P.jDannie.Granici.MinY + P.jDannie.Granici.MaxY) / 2])}>
									<ROverlay className="no-interaction P11PX"  >
										<div class="P11PX Icon R_NoSel R_CC R_TI" style={{ background: `radial-gradient(circle, ${P.sCvet}, ${P.sCvet} 52%, transparent 55%)`, color: P.sCvetTekst }}>
											{P.sMetka}
										</div>
									</ROverlay>
								</RFeature>;
							} else return null;
						})}

						{(this.state.MenuAktivnii == "Секторы") && this.state.Sektori.map(P => {
							if ((P.jDannie.Tochki !== undefined) && (this.state.Centr.zoom >= 12.5)) {
								return <RFeature geometry={new Polygon([P.jDannie.Tochki])} onClick={(e) => {
									this.SektorTochka({ Item: P });
								}}>
									<RStyle><RStroke color={P.sCvet} width={3} />
										{((this.state.SektoriAktivnii || {}).iId == P.iId)
											? <RFill color={P.sCvet.replace("rgb", "rgba").replace(")", ", 0.1)")} />
											: <RFill color="transparent" />
										}

									</RStyle>
									<RPopup trigger={"hover"} className="example-overlay ">
										<p class="PV" style={{ /* <em></em>background: P.sCvet.replace("rgb", "rgba").replace(")", ", 0.5)"), color: P.sCvetTekst*/ }} >
											{P.sOpisanie}
										</p>
									</RPopup>
								</RFeature>;
							} else return null;
						})}
						{(this.state.MenuAktivnii == "Секторы") && this.state.Sektori.map(P => {
							if ((P.jDannie.Tochki !== undefined) && (this.state.Centr.zoom >= 14) && (this.state.Centr.zoom <= 17.5)) {
								return <RFeature geometry={new Point([(P.jDannie.Granici.MinX + P.jDannie.Granici.MaxX) / 2, (P.jDannie.Granici.MinY + P.jDannie.Granici.MaxY) / 2])}>
									<ROverlay className="no-interaction P11PX"  >
										<div class="P11PX Icon R_NoSel R_CC R_TI" style={{ background: `radial-gradient(circle, ${P.sCvet}, ${P.sCvet} 52%, transparent 55%)`, color: P.sCvetTekst }}>
											{P.sMetka}
										</div>
									</ROverlay>
								</RFeature>;
							} else return null;
						})}
						{(this.state.MenuAktivnii == "Здания") && this.state.Sektori.map(P => {
							if ((P.jDannie.Tochki !== undefined) && (this.state.Centr.zoom >= 12.5)) {
								return <RFeature geometry={new Polygon([P.jDannie.Tochki])} onClick={(e) => {
									this.SektorTochka({ Item: P });
								}}>
									<RStyle><RStroke color={P.sCvet} width={3} /><RFill color="transparent" /></RStyle>
								</RFeature>;
							} else return null;
						})}

						{(this.state.MenuAktivnii != "Комплексы") && this.state.Zdanii.map(P => {
							if ((P.jDannie.Tochki !== undefined) && (this.state.Centr.zoom >= 13.5)) {
								return <RFeature geometry={new Polygon([P.jDannie.Tochki])} onClick={(e) => {
									this.ZdanieTochka({ Item: P });
								}}>
									<RStyle>
										{((this.state.MenuAktivnii == "Здания") && ((this.state.ZdaniiAktivnii || {}).iId == P.iId))
											? <RStroke color={"rgb(0,0,0)"} width={1} />
											: <RStroke color={"rgb(255,255,255)"} width={1} />
										}
										
										{((this.state.ZdaniiAktivnii || {}).iId == P.iId)
											? <RFill color={P.sCvetK} />
											: <RFill color={P.sCvetK} />
										}

									</RStyle>
									{(this.state.MenuAktivnii == "Здания") && false && <RPopup trigger={"hover"} className="example-overlay ">
										<p class="PV" style={{ /* <em></em>background: P.sCvet.replace("rgb", "rgba").replace(")", ", 0.5)"), color: P.sCvetTekst*/ }} >
											{P.sOpisanie}
										</p>
									</RPopup>}
								</RFeature>;
							} else return null;
						})}
						{(this.state.MenuAktivnii != "Комплексы") && this.state.Zdanii.map(P => {
							if ((P.jDannie.Tochki !== undefined) && (this.state.Centr.zoom >= 19) && (this.state.Centr.zoom <= 30)) {
								return <RFeature geometry={new Point([(P.jDannie.Granici.MinX + P.jDannie.Granici.MaxX) / 2, (P.jDannie.Granici.MinY + P.jDannie.Granici.MaxY) / 2])}>
									<ROverlay className="no-interaction P11PX"  >
										<div class="R_NoSel K_ZM">
											{P.sMetka}
										</div>
									</ROverlay>
								</RFeature>;
							} else return null;
						})}
						{(this.state.MenuAktivnii == "Комплексы") && (this.state.KompleksiAktivnii != null || true) && this.state.Sektori.filter(SE => {
							return true; return (this.state.Zdanii.filter(ZE => ZE.iIdK == this.state.KompleksiAktivnii.iId).filter(ZE => ZE.iSektor == SE.iId).length >= 1);
						}).map(P => {
							if ((P.jDannie.Tochki !== undefined) && (this.state.Centr.zoom >= 12.5)) {
								return <RFeature geometry={new Polygon([P.jDannie.Tochki])} onClick={(e) => {
									this.SektorTochka({ Item: P });
								}}>
									<RStyle>
										<RStroke color={P.sCvet} width={2} />
										<RFill color="transparent" />
										{/*<RFill color={P.sCvet.replace("rgb", "rgba").replace(")", ", 0.1)")} />*/}
									</RStyle>
								</RFeature>;
							} else return null;
						})}
						{(this.state.MenuAktivnii == "Комплексы") && (this.state.KompleksiAktivnii != null || true) && this.state.Sektori.filter(SE => {
							return true; return (this.state.Zdanii.filter(ZE => ZE.iIdK == this.state.KompleksiAktivnii.iId).filter(ZE => ZE.iSektor == SE.iId).length >= 1);
						}).map(P => {
							if ((P.jDannie.Tochki !== undefined) && (this.state.Centr.zoom >= 14) && (this.state.Centr.zoom <= 17.5)) {
								return <RFeature geometry={new Point([(P.jDannie.Granici.MinX + P.jDannie.Granici.MaxX) / 2, (P.jDannie.Granici.MinY + P.jDannie.Granici.MaxY) / 2])}>
									<ROverlay className="no-interaction P11PX"  >
										<div class="P11PX Icon R_NoSel R_CC R_TI" style={{ background: `radial-gradient(circle, ${P.sCvet}, ${P.sCvet} 52%, transparent 55%)`, color: P.sCvetTekst }}>
											{P.sMetka}
										</div>
									</ROverlay>
								</RFeature>;
							} else return null;
						})}
						{(this.state.MenuAktivnii == "Комплексы") && this.state.Zdanii.filter(E => true/*E.iIdK != "15"*/).map(P => {
							if ((P.jDannie.Tochki !== undefined) && (this.state.Centr.zoom >= 13.5)) {
								return <RFeature geometry={new Polygon([P.jDannie.Tochki])} onClick={(e) => {
									this.KompleksTochka({ Item: this.state.Kompleksi.filter(E=> E.iId == P.iIdK)[0] });
								}}>
									<RStyle>
										{((this.state.KompleksiAktivnii || {}).iId == P.iIdK)
											? <RStroke color={"rgb(0,0,0)"} width={1} />
											: <RStroke color={P.sCvetK} width={1} />
										}
										{((this.state.KompleksiAktivnii || {}).iId == P.iIdK)
											? <RFill color={P.sCvetK} />
											: <RFill color={P.sCvetK} />
										}

									</RStyle>
									{(this.state.MenuAktivnii == "Здания") && false && <RPopup trigger={"hover"} className="example-overlay ">
										<p class="PV" style={{ /* <em></em>background: P.sCvet.replace("rgb", "rgba").replace(")", ", 0.5)"), color: P.sCvetTekst*/ }} >
											{P.sOpisanie}
										</p>
									</RPopup>}
								</RFeature>;
							} else return null;
						})}
						{(this.state.MenuAktivnii == "Комплексы") && this.state.Zdanii.filter(E => true/*E.iIdK != "15"*/).map(P => {
							if ((P.jDannie.Tochki !== undefined) && (this.state.Centr.zoom >= 17.5) && (this.state.Centr.zoom <= 30)) {
								return <RFeature geometry={new Point([(P.jDannie.Granici.MinX + P.jDannie.Granici.MaxX) / 2, (P.jDannie.Granici.MinY + P.jDannie.Granici.MaxY) / 2])}>
									<ROverlay className="no-interaction P11PX"  >
										<div class="R_NoSel K_ZM">
											{P.sMetka}
										</div>
									</ROverlay>
								</RFeature>;
							} else return null;
						})}

						<RLayerVector>
							{this.state.Organizacii.map(O => {
								if ((O.jDannie.length > 0) && (O.bKarta == 1) && (this.state.Centr.zoom >= 14) && (this.state.Centr.zoom <= 30)) {
									return O.jDannie.map(OD => <RFeature geometry={new Polygon([OD.Tochki])}>
										<RStyle>
											<RStroke color={(this.state.OrganizaciiAktivnii || {}).iId == O.iId ? "#ffffff" : O.sCvet} width={1} />
											<RFill color={O.sCvet} />
										</RStyle>
									</RFeature>)
								}
							})}
						</RLayerVector>
						<RLayerVector>
							{this.state.Organizacii.map(O => { //${O.sCvet}
								if ((O.jDannie.length > 0) && (O.bKarta == 1) && (this.state.Centr.zoom >= 17.5) && (this.state.Centr.zoom <= 30)) {
									return <RFeature geometry={new Point(this.NaitiCentr(O.jDannie))}>
										<ROverlay className="no-interaction P11PX">
											<div class="P11PX Icon R_NoSel R_CC R_TI" style={{ background: `radial-gradient(circle, transparent 44%, rgb(0,0,0) 52%, transparent 55%)`, color: 'rgb(0,0,0)' }}>
												{O.iNomer}
											</div>
										</ROverlay>
									</RFeature>
								}
							})}
						</RLayerVector>
					</RLayerVector>

					<RLayerVector>
						{this.state.Segmenti.map(S => {
							if (S.sTip == "Segment" || S.sTip == "SegmentZakritii") return <RFeature geometry={new LineString(S.jDannie)}>
								<RStyle><RStroke color={"#6a6a6a"} width={2.5 * S.iRazmer * (2.0 / this.state.Centr.resolution) + 2} /></RStyle>
							</RFeature>

							return null;
						})}
						{this.state.Segmenti.map(S => {
							if (S.sTip == "Segment") return <RFeature geometry={new LineString(S.jDannie)}>
								<RStyle><RStroke color={"#ffffff"} width={2.5 * S.iRazmer * (2.0 / this.state.Centr.resolution)} /></RStyle>
							</RFeature>
							if (S.sTip == "SegmentZakritii") return <RFeature geometry={new LineString(S.jDannie)}>
								<RStyle><RStroke color={"#e14b00"} width={2.5 * S.iRazmer * (2.0 / this.state.Centr.resolution)} /></RStyle>
							</RFeature>
							if (S.sTip == "TochkaZakritii") return <RFeature geometry={new Point(S.jDannie)}>
								<ROverlay className="P11PX">
									<div style={{ color: "var(--GM4)" }} class={"P11PX Icon R_NoSel R_CC R_MI KR_C KR_CP"}>
										circle
									</div>
								</ROverlay>
							</RFeature>

							return null;
						})}
					</RLayerVector>
				</RMap>
			</Okno>
		</>
	}
}